
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import entityService, { useBasicFilterEntity, useBasicFilterFeatureDescription } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom } from 'library/store';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IFeatureDescription, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, ClickAwayListener, FormControlLabel, FormGroup, IconButton, InputAdornment, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import CodeIcon from '@mui/icons-material/Code';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { ListItemIcon } from '@mui/material';

import TextFieldRight from 'components/ui/TextFieldRight';

import { stripHtml } from "string-strip-html";

import { AiOutlineMessage, AiOutlineWhatsApp, AiOutlineFacebook, AiOutlineFileExcel, AiOutlineFileWord, AiOutlineLayout } from 'react-icons/ai';

import { HiOutlineDocumentReport } from 'react-icons/hi';

import { isFalsy } from 'utility-types';
import DocViewer, { PDFRenderer, PNGRenderer, MSDocRenderer ,DocViewerRenderers} from '@cyntler/react-doc-viewer';

import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useJobService, { useBasicFilterJob } from './services/Job';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { IJob, defaultJob, IJobSchedule, defaultJobSchedule, JobType, DateType, JobScheduleType, IJobScheduleParameter } from './models/Job';

import { globalConfig } from 'config';
import { RichWysiwygEditor } from 'components/ui/RichWysiwygEditor';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';

import useEnumerationService from 'features/configuration/services/Enumeration';
import useBusinessApplicationService, { useBasicFilterBusinessApplicationQuery } from 'features/setup/services/BusinessApplication';

import { IEnumerationItem, Enum_JOB_RETENTION_MODE,Enum_DURATION_UNIT } from 'features/configuration/models/Enumeration';
import { IBusinessApplication, IBusinessApplicationQuery, IBusinessApplicationQueryParameter } from 'features/setup/models/BusinessApplication';
import { IReport } from 'features/configuration/models/Report';
import { useBasicFilterReport } from 'features/configuration/services/Report';

//import * from '../../../public'



export const JobForm: FC<IJob> = (props: IJob = defaultJob) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntityExpressionSyntax } = entityService();

  const { createJob, updateJob } = useJobService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();
  const {getBusinessApplications, getBusinessApplicationQueryParameters } = useBusinessApplicationService();


  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterJob = useBasicFilterJob( 
    (event: React.MouseEvent<unknown>, row: IJob) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openBusinessApplicationQueryFilter, setOpenBusinessApplicationQueryFilter] = useState(false);
    const basicFilterBusinessApplicationQuery = useBasicFilterBusinessApplicationQuery( 
      () => {
        if(!inputBusinessApplication.current || isFalsy((inputBusinessApplication.current as HTMLInputElement).value)){
          enqueueSnackbar( t('You have to select business appication before fetch business data'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
            return -1;
        }           
        else {          
          return (inputBusinessApplication.current as HTMLInputElement).value as unknown as number;
        }        
      },
      async (event: React.MouseEvent<unknown>, row: IBusinessApplicationQuery) => {

        const {id, name } = row;

        setValue('businessApplicationQueryId', id);
        setValue('businessApplicationQueryName', name);
                        
        setOpenBusinessApplicationQueryFilter(false);
      }
    );

    const [openEntityFilter, setOpenEntityFilter] = useState(false);
    const basicFilterEntity = useBasicFilterEntity( 
        (event: React.MouseEvent<unknown>, row: IEntity) => {
            const {name, description} = row;

            setValue('entityName', name || '');
            setValue('entityDescription', description || '');        
                   
            setOpenEntityFilter(false);
        }
    );

    const [openFeatureFilter, setOpenFeatureFilter] = useState(false);
    const basicFilterFeature = useBasicFilterFeatureDescription(
        () => {
            //if(roleEntityIndex < 0 || getValues().roleEntities.length <= roleEntityIndex ) return 'K--@@--K';  
            return getValues().entityName;
        }, 
        (event: React.MouseEvent<unknown>, row: IFeatureDescription) => {
            const {name, label, entityName} = row;
  
            setValue('featureName', name);
            setValue('featureDescription', label);          
                
            //setFilteredFeatures([]);
            setOpenFeatureFilter(false);
        }
    );

    const [openReportFilter, setOpenReportFilter] = useState(false);
    const basicFilterReport = useBasicFilterReport( 
        (event: React.MouseEvent<unknown>, row: IReport) => {
            const {id, name, entityName ,description} = row;

            setValue('reportId', id || 0);
            setValue('reportName', name || '');       
            setValue('entityName', entityName); 
                   
            setOpenReportFilter(false);
        }
    );

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState(''); 
  const [expressionField, setExpressionField] = useState<keyof IJob>('reportRecipientExpression');      

  const emptyFunc = (obj: any) => {}
      
  const methods = useForm<IJob>({defaultValues:defaultJob});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchRetentionMode = watch('retentionMode');
  const watchBusinessApplicationQueryId = watch('businessApplicationQueryId');

  const [jobScheduleIndex, setJobScheduleIndex] = useState<number>(-1);
  const [queryParameters, setQueryParameters] = useState<IBusinessApplicationQueryParameter[]>([]);
 

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IJob>,Error,IJob>(
      _id>0?updateJob:createJob, {   
        onSuccess: (data: IResult<IJob>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Job',data.data.id]);
        },
        onError: (err: Error) => {          
          // enqueueSnackbar( error?.message, { variant: 'error',
          //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

      const [enumItems, setEnumItems] = useState<IEnumerationItem[]>([]);

      const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([]);
      const inputBusinessApplication = useRef();

      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'Job'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT] ));

    const {data: _data, refetch} = useQuery<IJob>(['Job', _id], () => retrieveEntity('Job',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      // const handleClickSearchEnumeration = (event: any) => {
      //   setOpenEnumerationFilter(true);
      // } 

    const refAppendJobSchedules = useRef<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateJobSchedule = useRef<(index: number,value: Partial<FieldArray<IJob>> ) => void>(null);
    const refRemoveJobSchedule = useRef<(index: number ) => void>(null);

    const handleAddJobSchedule = (event: any) => {

      if(watchBusinessApplicationQueryId <= 0) {
        enqueueSnackbar( t('The business application query for filter is not defined'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      const ix = getValues().jobSchedules.length + 1;
      (refAppendJobSchedules.current??emptyFunc)({ ...defaultJobSchedule, description: `## Plan : ${ix}` });
      jobScheduleSelected(ix-1);
    }

    const handleJobScheduleSelected = (event: React.MouseEvent<unknown>,index: number,row: IJobSchedule) => {      
      
      jobScheduleSelected(index);            
  }

  const jobScheduleSelected = (index: number) => {
    setJobScheduleIndex(index);

    const jobSchedule = getValues().jobSchedules[index];
      (refUpdateJobSchedule.current??emptyFunc)(index,
        {...jobSchedule,
          jobScheduleParameters: queryParameters.map( (param, idx) => {
            const {parameterName, parameterDataType, id} = param;

            const tmpParameter = jobSchedule.jobScheduleParameters.find( p => p.businessApplicationQueryParameterId === id);
            
            return {  id: tmpParameter?.id ?? 0, 
                      jobScheduleId: tmpParameter?.jobScheduleId ?? 0, 
                      businessApplicationQueryParameterId: id,
                      parameterName,
                      parameterValueExpression: tmpParameter?.parameterValueExpression ??   '',
            }
          } )
        });
  }
  


  const jobScheduleRowActionIcon = ( jobSchedule: IJobSchedule) : ActionIconTableRow<IJob,IJobSchedule> => {  
      const res: ActionIconTableRow<IJob,IJobSchedule> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: IJobSchedule) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IJobSchedule) => {           
          
          (refRemoveJobSchedule.current??emptyFunc)(index);            
        }
      }
      return res;
    }
      
    const getJobScheduleTypeList = (row: IJobSchedule, cellId: keyof IJobSchedule, 
      opts: {value: string, name: string}[]) => {   
      //const {requestClassEnumerationCode} = row;
      
      return [{value: 'day', name: t('Day')}, {value: 'month', name: t('Month')}, 
              {value: 'unic', name: t('Unic')}, {value: 'recurrent', name: t('Recurrent')}];
    }

      const cellEditableJobSchedule = (row: IJobSchedule, cellId: keyof IJobSchedule) => {
        return true;
      }

      const [dateTypes, setDateTypes]  = useState<{name: string, value: string}[]>([]);
      const [dayOfWeeks, setDayOfWeeks]  = useState<{name: string, value: string}[]>([]);
      const [weekOrders, setWeekOrders]  = useState<{name: string, value: number}[]>([]);
      

      const [headJobScheduleCells, setHeadJobScheduleCells]  = useState<HeadCell<IJobSchedule>[]>([]);
      useEffect(() => {
        setHeadJobScheduleCells([            
          {id:'description', label : t('Description'),  display: true, type: 'string', width: 65, isEditable: cellEditableJobSchedule},
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'type', label : t('Type'),  display: true, type: 'string', width: 35, isEditable: cellEditableJobSchedule,
          getOptions: getJobScheduleTypeList}
        ])
      }, [t,i18n]);

      const refAppendJobScheduleParameters = useRef<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateJobScheduleParameter = useRef<(index: number,value: Partial<FieldArray<IJob>> ) => void>(null);
      const refRemoveJobScheduleParameter = useRef<(index: number ) => void>(null);

      const [parameterIndex, setParameterIndex] = useState<number>(-1);
      const jobScheduleParameterRowActionIcon = ( jobScheduleParameter: IJobScheduleParameter) : ActionIconTableRow<IJob,IJobScheduleParameter> => {  
        const res: ActionIconTableRow<IJob,IJobScheduleParameter> = {
          toolTip: 'remove',
          icon: DeveloperModeIcon,
          hasAction: (index: number,row: IJobScheduleParameter) => true, 
          isActionExecuting: true,
          onRowClickIcon: (event : any, index: number, row: IJobScheduleParameter) => {    
                   
            
            const {entityName, reportLanguageExpression} = getValues(); 
            if(isFalsy(entityName)) {
              enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
                  return;
            }
            
            setParameterIndex(index); 
            setCurrentEntityName(entityName);
            setExpressionField('jobSchedules');  
            setCurrentExpression(row.parameterValueExpression);  
            setOpenEntityExpression(true); 
            //(refRemoveJobScheduleParameter.current??emptyFunc)(index);            
          }
        }
        return res;
      }

      const [headJobScheduleParameterCells, setHeadJobScheduleParameterCells]  = useState<HeadCell<IJobScheduleParameter>[]>([]);
      useEffect(() => {
        setHeadJobScheduleParameterCells([            
          {id:'parameterName', label : t('Name'),  display: true, type: 'string', width: 30, },
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'parameterValueExpression', label : t('Expression'),  display: true, type: 'string', width: 70, }
        ])
      }, [t,i18n]);

      useEffect(() => {
        setDateTypes([{name: t('Day'), value: 'day'}, {name: t('Order'), value: 'order'}]);

        setDayOfWeeks([ {name: t('Monday'), value: 'MON'}, {name: t('Tuesday'), value: 'TUE'}, 
        {name: t('Wednesday'), value: 'WED'}, {name: t('Thursday'), value: 'THU'}, {name: t('Friday'), value: 'FRI'}, 
        {name: t('Saturday'), value: 'SAT'},{name: t('Sunday'), value: 'SUN'}, {name: t('Day'), value: 'DAY'}
        ]);

        setWeekOrders([ {name: t('First'), value: 1}, {name: t('Second'), value: 2}, {name: t('Third'), value: 3},
                        {name: t('Fourth'), value: 4}, {name: t('Last'), value: 5}
        ]);

      }, [t,i18n]);


      const [jobType, setJobType] = useState<JobType>('report');
      const handleChangeJobType = ( event: React.MouseEvent<HTMLElement>, newType: JobType ) => {  

        if (newType !== null) {
          setJobType(newType);
          setValue('type', newType);
        }      
      }

      const handleClickSearchBusinessApplicationQuery = (event: any) => {        
        setOpenBusinessApplicationQueryFilter(true);
       }

      const handleClickSearchEntity = (event: any) => {        
        setOpenEntityFilter(true);
      }

      const handleClickSearchFeature = (event: any) => {        
        setOpenFeatureFilter(true);
      }

      

      const handleClickSearchReport = (event: any) => {        
        setOpenReportFilter(true);
      }

      const handleClickOpenRecipientExpression = (event: any) => {
        const {entityName, reportRecipientExpression} = getValues();
        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
        }

        setCurrentEntityName(entityName);
        setExpressionField('reportRecipientExpression');    
        setCurrentExpression(reportRecipientExpression);    
        setOpenEntityExpression(true);
      }

      const handleClickOpenLanguageExpression = (event: any) => {  
        const {entityName, reportLanguageExpression} = getValues();
        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
        }

        setCurrentEntityName(entityName);
        setExpressionField('reportLanguageExpression');  
        setCurrentExpression(reportLanguageExpression);           
        setOpenEntityExpression(true);
      }      

      const handleClickOkExpression = async () => {
        const {entityName, jobSchedules} = getValues();

        const checkExpression = await checkEntityExpressionSyntax(entityName, currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        if( ['reportRecipientExpression','reportLanguageExpression'].includes(expressionField)) {
          if(returnType !== 'string')
            enqueueSnackbar( t('The result of expression must be string') , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          else
            setValue(expressionField, currentExpression);         
        }

        if( expressionField === 'jobSchedules' ) {
          const jobSchedule = jobSchedules[jobScheduleIndex];
          if(jobSchedule) {
            const jobScheduleParameter = jobSchedule.jobScheduleParameters[parameterIndex];
            const queryParameter = queryParameters.find(p => p.parameterName === jobScheduleParameter.parameterName);
            
            if(queryParameter) {
              if(returnType !== queryParameter.parameterDataType)
                enqueueSnackbar( `${t('The result of expression must be')} : ${queryParameter.parameterDataType}` , { variant: 'error',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              else
                (refUpdateJobScheduleParameter.current??emptyFunc)(parameterIndex, 
                    {...jobScheduleParameter, parameterValueExpression: currentExpression});
            }            
          }
        }        
        
        setOpenEntityExpression(false);
      }

      const [currentJobScheduleType,setCurrentJobScheduleType] = useState<JobScheduleType>('day');
      const [currentDateType,setCurrentDateType] = useState<DateType>('day');
      useEffect(() => {
        const subscription = watch( (value, { name, type }) => {
         
          if(isFalsy(value))
            return;

          if(value && value.jobSchedules 
              && value.jobSchedules.length > jobScheduleIndex && jobScheduleIndex >= 0 
              && value.jobSchedules[jobScheduleIndex] ) {
                const jobSchedule = value.jobSchedules[jobScheduleIndex]!;
                
                setCurrentJobScheduleType(jobSchedule.type || getValues().jobSchedules[jobScheduleIndex].type); 
                setCurrentDateType(jobSchedule.dateType || getValues().jobSchedules[jobScheduleIndex].dateType); 
              }
        });  
      
        return () => subscription.unsubscribe();
      }, [watch, jobScheduleIndex]);

      useEffect( () => {        
        async function loadBusinessApplicationQueryParameters() {
          if(watchBusinessApplicationQueryId > 0) {
            const arr = await getBusinessApplicationQueryParameters(watchBusinessApplicationQueryId);         
            setQueryParameters([...arr]);
          }          
        }
  
        loadBusinessApplicationQueryParameters();
      }, [watchBusinessApplicationQueryId]); 

      useEffect( () => {        
        async function loadBusinessApplications() {
          const arr = await getBusinessApplications( {name: '', dbmsType: ''} );         
          setBusinessApplications([...arr]);
        }
  
        loadBusinessApplications();
      }, []); 

      useEffect( () => {
        async function loadEnumerationItems() {
          const arr = await getEnumerationItemsByEnumerationCodes( [Enum_JOB_RETENTION_MODE,Enum_DURATION_UNIT] );
          setEnumItems([...arr]);
  
        }
  
        loadEnumerationItems();
      }, [])
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Job schedule'));
        setCurrentBasicTextFilterProps(basicFilterJob);
      }, []); 

    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Job',_id, refetch);  
          }, [_id] );
    
    
      useEffect( () => {
        
        console.log(_data);
        if(_data && _data.id > 0) {
            reset({..._data });
            setJobType(_data.type);
            if(_data.jobSchedules.length > 0) setJobScheduleIndex(0);
          }
          
        }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {   
        _setId(0);       
        reset(defaultJob);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              return;
            }
          console.log(data);      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Job', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

    

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.25} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup size="small"
                                  color="primary"
                                  value={jobType}
                                  exclusive
                                  onChange={handleChangeJobType}
                                  aria-label="Small sizes" fullWidth
                                >
                                  <ToggleButton value="report">{t('Report')}</ToggleButton>
                                  <ToggleButton value="feature">{t('System function')} </ToggleButton>
                                </ToggleButtonGroup>            
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(15% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(65% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                      label={t('Active ?')}
                                      control={
                                      <Controller
                                          name='isActive'
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />            
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='businessApplicationId' control={control} 
                                    defaultValue={ (businessApplications || []).length === 1 ? (businessApplications || [])[0].id : 0 }
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="requestTypeBusinessAppId"
                                        label={t('Business application')} inputProps={ {readOnly: false}} inputRef={inputBusinessApplication}>
                                        {businessApplications.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />           
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="businessApplicationQueryName" label={t('Query filter')} {...register('businessApplicationQueryName')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchBusinessApplicationQuery}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                                { openBusinessApplicationQueryFilter && <FormDialog open={openBusinessApplicationQueryFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Query filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenBusinessApplicationQueryFilter(false);}} onOk={()=> {setOpenBusinessApplicationQueryFilter(false);}}  >
                                    <BasicTextFilterForm<IBusinessApplicationQuery> {...basicFilterBusinessApplicationQuery} />
                                  </FormDialog>  } 
                                  { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }           
                            </Box>
                            { jobType === 'feature' && <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="entityDescription" label={t('Entity')} {...register('entityDescription')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchEntity}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                                { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityFilter(false);}} onOk={()=> {setOpenEntityFilter(false);}}  >
                                        <BasicTextFilterForm<IEntity> {...basicFilterEntity } />
                                </FormDialog> }                                          
                            </Box> }
                            { jobType === 'feature' && <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="featureDescription" label={t('Feature')} {...register('featureDescription')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchFeature}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                                { openFeatureFilter && <FormDialog open={openFeatureFilter} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Feature filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenFeatureFilter(false);}} onOk={()=> {setOpenFeatureFilter(false);}}  >
                                        <BasicTextFilterForm<IFeatureDescription> {...basicFilterFeature } />
                                </FormDialog> }                                          
                            </Box> }
                            { jobType === 'report' && <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(70% - 8px)'}} id="reportName" label={t('Report')} {...register('reportName')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchReport}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                                { openReportFilter && <FormDialog open={openReportFilter} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Report filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenReportFilter(false);}} onOk={()=> {setOpenReportFilter(false);}}  >
                                        <BasicTextFilterForm<IReport> {...basicFilterReport } />
                                </FormDialog> }   
                                <Controller name='reportTransmissionMode' control={control} 
                                    defaultValue='default'
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="reportTransmissionMode"
                                        label={t('Transmission mode')} inputProps={ {readOnly: false}} >
                                        <MenuItem key="default" value="default">{t('Default')}</MenuItem>
                                      </TextField>
                                    )}
                                />                                        
                            </Box> }
                            { jobType === 'report' && <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="reportRecipientExpression" label={t('Recipient')} {...register('reportRecipientExpression')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenRecipientExpression}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                                 
                            </Box> }
                            
                            { jobType === 'report' && <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="reportLanguageExpression" label={t('Language')} {...register('reportLanguageExpression')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenLanguageExpression}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />                                 
                            </Box> }
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='retentionMode' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="requestTypeBusinessAppId"
                                        label={t('Retention')} inputProps={ {readOnly: false}} inputRef={inputBusinessApplication}>
                                        {enumItems.filter(x => x.enumerationCode === Enum_JOB_RETENTION_MODE).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />   
                                { watchRetentionMode === 'PERSO' &&
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={'#'} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='retentionValue'
                                control={control}
                              /> }
                              { watchRetentionMode === 'PERSO' && <Controller name='businessApplicationId' control={control} 
                                  defaultValue={ (businessApplications || []).length === 1 ? (businessApplications || [])[0].id : 0 }
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="requestTypeBusinessAppId"
                                      label={t('Dur. unit')} inputProps={ {readOnly: false}} inputRef={inputBusinessApplication}>
                                      {enumItems.filter(x => x.enumerationCode === Enum_DURATION_UNIT).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              /> }       
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column' >
                            <Box sx={{ mt: 1, width: '100%' }}  >                                
                              <ArrayFieldTableEx<IJob,IJobSchedule,'id'> 
                                
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headJobScheduleCells} rowsPathName='jobSchedules' 
                                title={t('Schedules')} rowActionIcon={jobScheduleRowActionIcon}  
                                onRowSelected={handleJobScheduleSelected}
                                                    
                                refAppend={refAppendJobSchedules as MutableRefObject<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateJobSchedule as MutableRefObject<(index: number,value: Partial<FieldArray<IJob>>) => void>}
                                refRemove={refRemoveJobSchedule as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddJobSchedule ,icon: AddCircleIcon,  },
                                
                                ]}
                              /> 
                           </Box>
                        </Stack>                        
                    </Grid>     
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'  >
                        {
                          getValues().jobSchedules.map( (jobSchedule, index) => {
                            return (index === jobScheduleIndex) && (
                              <Fragment key={`key-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                <Box sx={{ mt: 1, width: '100%' }} > 
                                  <Typography  variant="h6" id={`key-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`} component="div" color="primary" noWrap >
                                    {`${t(('Schedule'))} - ${(jobScheduleIndex+1)} - ${jobSchedule.description}`}
                                  </Typography>                                                       
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller
                                        key={`query-${jobSchedule.description}-query-x`}
                                        render={({ field }) => <TextField label={t('Description')} {...field}  
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`, textTransform: 'none'}} 
                                           />} 
                                        name={`jobSchedules.${index}.description`}                      
                                        control={control}
                                      />                                              
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >                                   
                                  <Controller
                                    key={`query-${jobSchedule.startDate}-startDate-x`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Start date')}        
                                        onChange={onChange}                        
                                        value={value}
                                        renderInput={(params) => <TextField {...params} sx={{width:`calc(40% - 8px)`}}  />}
                                      /> )}
                                    name={`jobSchedules.${index}.startDate`}          
                                    control={control}
                                  />
                                  <Controller
                                    key={`query-${jobSchedule.executionTime}-executionTime-x`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <TimePicker label={t('Time')}
                                        inputFormat="HH:mm:ss"    
                                        views={['hours', 'minutes', 'seconds']}     
                                        onChange={onChange}                        
                                        value={value}
                                        renderInput={(params) => <TextField {...params} sx={{width:`calc(30% - 8px)`, textAlign: 'center'}} />}
                                      /> )}
                                    name={`jobSchedules.${index}.executionTime`}          
                                    control={control}
                                  />
                                  <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                      label={t('Active ?')}
                                      control={
                                      <Controller
                                          name={`jobSchedules.${index}.isActive`} 
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />
                                </Box>   
                                {currentJobScheduleType === 'recurrent' && <Box sx={{ mt: 1, width: '100%' }} >                                   
                                  <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                      label={t('Has end time ?')}
                                      control={
                                      <Controller
                                          name={`jobSchedules.${index}.hasEndExecutionTime`} 
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />
                                  <Controller
                                    key={`query-${jobSchedule.endExecutionTime}-endExecutionTime-x`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <TimePicker label={t('Time')}  
                                        inputFormat="HH:mm:ss"    
                                        views={['hours', 'minutes', 'seconds']}   
                                        onChange={onChange}                        
                                        value={value}
                                        renderInput={(params) => <TextField {...params} sx={{width:`calc(30% - 8px)`, textAlign: 'center'}} />}
                                      /> )}
                                    name={`jobSchedules.${index}.endExecutionTime`}          
                                    control={control}
                                  />                                  
                                </Box> }
                                {currentJobScheduleType === 'day' && <Box>
                                  <FormGroup>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Monday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isMonday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Tuesday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isTuesday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('Wednesday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isWednesday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Thursday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isThursday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Friday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isFriday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('Saturday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isSaturday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Sunday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isSunday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                  </FormGroup>
                                </Box>}   
                                {currentJobScheduleType === 'month' && <Box id={`key-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                  <FormGroup id={`key-month-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('January')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isJanuary`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                        label={`${t('Febuary')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isFebuary`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                    />
                                    <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('March')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isMarch`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>                                      
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                        label={`${t('April')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isApril`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                            label={`${t('May')} ?`}
                                            control={
                                            <Controller
                                                name={`jobSchedules.${index}.isMay`} 
                                                control={control}
                                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                            />} 
                                        />
                                        <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('June')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isJune`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>                                    
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('July')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isJuly`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                        label={`${t('August')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isAugust`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('September')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isSeptember`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>                                      
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                        label={`${t('October')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isOctober`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('November')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isNovember`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                        label={`${t('December')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isDecember`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                      />
                                    </Box>                                    
                                    <Box sx={{ mt: 1, width: '100%' }} id={`key-xxx-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                      <Controller name={`jobSchedules.${index}.dateType`}
                                        control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id={`jobSchedules.${jobSchedule.dateType}.dateType`}
                                            label={t('Date')} >
                                              { dateTypes.map(({name, value}, idx) => (<MenuItem key={value} value={value}>{name}</MenuItem>))}                                              
                                          </TextField>
                                        )}
                                      />  
                                      { currentDateType === 'day' &&<Controller
                                          render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                          return (
                                            <NumberFormat              
                                              disabled={false}
                                              label={t('Number lentgh')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                              allowEmptyFormatting={false}
                                              control={control}    
                                              thousandSeparator={true}
                                              decimalScale={0}
                                              onValueChange={ (v) => onChange(v.floatValue) }
                                              defaultValue={value}
                                              value={value}

                                              customInput={TextFieldRight}                            
                                            />
                                          );
                                        }}
                                        name={`jobSchedules.${index}.dayOfMonth`}
                                        control={control}
                                      /> }
                                      { currentDateType === 'order' && <Controller name={`jobSchedules.${index}.weekOrder`}                                        
                                        control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id={`jobSchedules.${jobSchedule.dateType}.weekOrder`}
                                            label={t('Order')} >
                                              { weekOrders.map(({name, value}, idx) => (<MenuItem key={value}  value={value}>{name}</MenuItem>))}                                              
                                          </TextField>
                                        )}
                                      /> }
                                      { currentDateType === 'order' && <Controller name={`jobSchedules.${index}.dayOfWeek`}
                                        control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id={`jobSchedules.${jobSchedule.dateType}.dayOfWeek`}
                                            label={t('Day of week')} >
                                              { dayOfWeeks.map(({name, value}, idx) => (<MenuItem key={value}  value={value}>{name}</MenuItem>))}                                              
                                          </TextField>
                                        )}
                                      /> }
                                    </Box>
                                  </FormGroup>
                                </Box>}                          
                                <Box sx={{ mt: 1, width: '100%' }} > 
                                  <ArrayFieldTableEx<IJob,IJobScheduleParameter,'id'> 
                                      
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headJobScheduleParameterCells} 
                                      rowsPathName={`jobSchedules.${index}.jobScheduleParameters`} 
                                      title={`${t('Parameters')} - ${jobSchedule.description}`} 
                                      rowActionIcon={jobScheduleParameterRowActionIcon}  
                                      //onRowSelected={handleBusinessAppCodificationSelected}
                                                          
                                      refAppend={refAppendJobScheduleParameters as MutableRefObject<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateJobScheduleParameter as MutableRefObject<(index: number,value: Partial<FieldArray<IJob>>) => void>}
                                      refRemove={refRemoveJobScheduleParameter as MutableRefObject<(index: number) => void>}

                                      //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                      //displayMore={undefined}
                                      toolbarActions={[ ]}
                                  />
                                </Box>
                              </Fragment>
                            )
                          })
                        }                        
                      </Stack>   
                    </Grid>          
                </Grid>
            </Box>
        </FormProvider> 
  )
}

