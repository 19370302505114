export type ReportType = 'message' | 'email' | 'file' ;

export type MessageType = 'sms' | 'whatsApp' | 'facebook' | '' ;

export type ReportFileType = 'layout' | 'doc' | 'excel' | '' ;

export interface IReport {
    id: number,
    name: string,
    description: string,
    entityName: string,

    type: ReportType,

    entityDescription: string,
    currentDocumentLanguage: string,

    currentMessageType: MessageType,
    currentReportFileType: ReportFileType,

    reportDocuments: IReportDocument[]
    reportFields: IReportField[]
  }


  export interface IReportDocument {
    id: number,

    documentLanguage: string,

    type: ReportType,
    
    messageType: MessageType,
    messageBody: string,
    emailObject: string,
    emailBody: string,
    reportFileType: ReportFileType,
    base64DocumentFile?: string | ArrayBuffer | null,
    documentFileName: string    
  }



  export interface IReportField {
    id: number,
    
    name: string,
    alias: string,
    format: string,
    valueExpression: string,
  }

  
  export const defaultReport : IReport = {
    id: 0,
    name: '',
    description: '',
    entityName: '',
   
    type: 'message',

    entityDescription: '',
    currentDocumentLanguage: 'fr-FR',
    currentMessageType: 'sms',
    currentReportFileType: 'layout',

    reportDocuments: [],
    reportFields: []

    // messageType: 'sms',
    // bodyMessage: '',
    // emailObject: '',
    // emailBody: '',
    // //base64ReportFile?: string | ArrayBuffer | null,
    // reportFileName: '',
    // //base64WordFile?: string | ArrayBuffer | null,
    // wordFileName: '',
    // //base64ExcelFile?: string | ArrayBuffer | null,
    // excelFileName: '',    
  }

  export const defaultReportDocument : IReportDocument = {
    id: 0,

    documentLanguage: 'fr-FR',
    type: 'message',

    messageType: 'sms',
    messageBody: '',
    emailObject: '',
    emailBody: '',
    reportFileType: 'layout',
    base64DocumentFile: '',
    documentFileName: '',   
  }

  

  export interface IReportSearch {
    name: string,
    description: string
  }