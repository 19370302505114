
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {useWindowSize} from 'react-use';

import { useTheme } from '@mui/material/styles';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { VictoryChart, VictoryContainer, VictoryLabel, VictoryPie, VictoryTheme } from 'victory';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter } from 'themes/commonStyles';

import useMessageService from 'features/production/services/Message';
import {IDashboardMessage, defaultDashboardMessage} from 'features/production/models/Message';

import { defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import WidgetSummary from './homeWidgets/WidgetSummary';
import WidgetBarChart from './homeWidgets/WidgetBarChart';
import WidgetPieChart from './homeWidgets/WidgetPieChart';




export const Home = () => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();

  const {getContractMessagesDashboard} = useMessageService();

  const [dashboard, setDashboard] = useState<IDashboardMessage>(defaultDashboardMessage)

  // const [reportDatasMonth, setReportDatasMonth] = useState<IDashboardCountItem[]>([])
  // const [reportDataItemsMonth, setReportDataItemsMonth] = useState<IDashboardCountItem[]>([])


  const _mt = new Map<string, string>([
    ['text', t('Text')],
    ['image', t('Image')],
    ['document', t('Document')],
    ['video', t('Video')],
    ['audio', t('Audio')]
  ]);

  useEffect( () => {        
    setCurrentFormName(t('Home'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);    

  useEffect(() => {
    async function fetchData() {
      
      const dash = await getContractMessagesDashboard();

      //const xx = dash.monthMessagesByType.map( x => ({...x, label: _mt.has(x.label)? _mt.get(x.label) :x.label  }) );
      //console.log(dash.monthMessagesByType.map( x => ({...x, label: _mt.has(x.label)? _mt.get(x.label) :x.label  }) ));

      setDashboard(dash);      
      //setReportDatasMonth([...dashboard.requestDatasMonth]);
      //setReportDataItemsMonth([...dashboard.requestDataItemsMonth]);
      
      
    }
    fetchData();
  }, []);

  
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={`${t('Year')} - ${t('Message(s) sended/delivered')}`} paletteColor={theme.palette['primary']} 
                iconName='IoSendSharp' total={dashboard.countMessageYear} total2={dashboard.countMessageDeliveryYear}  />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={`${t('Month')} - ${t('Message(s) sended/delivered')}`} paletteColor={theme.palette['info']} 
                iconName='IoSendOutline' total={dashboard.countMessageMonth} total2={dashboard.countMessageDeliveryMonth}  />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={`${t('Month')} - ${t('Received messages')}`} paletteColor={theme.palette['warning']}  
                iconName='MdCallReceived' total={dashboard.countReceivededMessageMonth}   />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={`${t('Year')} - ${t('Recipient')}(s)`} paletteColor={theme.palette['success']} 
                iconName='MdContacts' total={dashboard.countRecipientYear}   />
        </Grid>
        <Grid item xs={12} md={6} lg={8} key={`${dashboard.barChatLabels.length}`}>
          <WidgetBarChart title={`${t('Message')}(s)`}
              subheader={`${t('Created')}/${t('Delivered')}`}
              chartLabels={dashboard.barChatLabels}
              chartData={[
                {
                  name: t('Sended'),
                  type: 'column',
                  fill: 'solid',
                  data: dashboard.countMessages,
                },
                {
                  name: t('Delivered'),
                  type: 'area',
                  fill: 'gradient',
                  data: dashboard.countDeliveryMessages,
                },
                // {
                //   name: 'Team C',
                //   type: 'line',
                //   fill: 'solid',
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}   />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <WidgetPieChart title={`${t('Month')} -/- ${t('Type')}`} subheader=''
              // chartData={[
              //   { label: 'America', value: 4344 },
              //   { label: 'Asia', value: 5435 },
              //   { label: 'Europe', value: 1443 },
              //   { label: 'Africa', value: 4443 },
              // ]}
              chartData={ dashboard.monthMessagesByType.map( x => ({...x, label: _mt.has(x.label)? _mt.get(x.label)??x.label :x.label }) )}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}/>
        </Grid>
      </Grid>
    </Box>
  )
}
