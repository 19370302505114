import { DataType } from "features/configuration/models/ExtensionType";
import { IUserBusinessApplication } from "features/security/models/User";
import { IRequestTypeBusinessApp } from "./RequestType";


export interface IBusinessApplication {
    id: number,
    name: string,
    description: string,

    isDirectConnection: boolean,
    dbmsType: string,
    dbConnectionString: string,

    isAuthenticatedApi: boolean,
    apiUserName: string,
    apiPassword: string,
    authenticationUrl: string,

    localServerApiUrl: string,

    requestTypeBusinessApps: IRequestTypeBusinessApp[],
    businessApplicationQueries: IBusinessApplicationQuery[],

    userBusinessApplications: IUserBusinessApplication[],
  }

export interface IBusinessApplicationQuery {
  id: number,
  businessApplicationId: number,
  name: string,
  description: string,
  query: string,

  jobs: any[],

  businessApplicationQueryParameters: IBusinessApplicationQueryParameter[],

  requestTypeBusinessAppCodifications: IRequestTypeBusinessAppCodification[],

  masterRequestTypeBusinessApps: IRequestTypeBusinessApp[],
  itemRequestTypeBusinessApps: IRequestTypeBusinessApp[],
  intermediaryRequestTypeBusinessApps: IRequestTypeBusinessApp[],
  userRequestTypeBusinessApps: IRequestTypeBusinessApp[],
  
}

export interface IBusinessApplicationQueryParameter {
  id: number,
  businessApplicationQueryId: number,
  parameterName: string,
  parameterDataType: DataType,

  jobScheduleParameters: any[]
}

export interface IRequestTypeBusinessAppCodification {
  id: number,
  requestTypeBusinessAppId: number,
  businessApplicationQueryId: number,
  requestClassEnumerationCode: string,
  defaultMappingCode: string,

  requestTypeBusinessAppCodificationMappings: IRequestTypeBusinessAppCodificationMapping[]
}

export interface IRequestTypeBusinessAppCodificationMapping {
  id: number,
  requestTypeBusinessAppId: number,
  businessApplicationQueryId: number,
  requestClassEnumerationCode: string,
  defaultMappingCode: string,
}

export const defaultBusinessApplication : IBusinessApplication = {
    id: 0,
    name: '',
    description: '',

    isDirectConnection: true,
    dbmsType: 'SQLSERVER',
    dbConnectionString: '',

    isAuthenticatedApi: false,
    apiUserName: '',
    apiPassword: '',
    authenticationUrl: '',

    localServerApiUrl: '',

    requestTypeBusinessApps: [],
    businessApplicationQueries: [],
    userBusinessApplications: []
  }

  export interface IBusinessApplicationSearch {    
    name: string,
    dbmsType: string
  }

  export interface IBusinessApplicationQuerySearch {    
    name: string,
    description: string
  }