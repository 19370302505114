import React, { CSSProperties, SVGAttributes } from 'react';
//import { IconContext } from 'react-icons';
//import loadable from "@loadable/component";

import { FiSettings } from 'react-icons/fi';
import { MdSecurity } from 'react-icons/md';
import { MdPermDataSetting } from 'react-icons/md'; // Configure
import { MdProductionQuantityLimits, MdContacts} from 'react-icons/md';
import { SiWebmoney } from 'react-icons/si';
import { CgMoreO } from 'react-icons/cg';

import { RiQrCodeFill } from 'react-icons/ri';
import { TbFileReport, TbApiApp } from 'react-icons/tb';
import { HiDocumentReport } from 'react-icons/hi';
import { RiMoreFill } from 'react-icons/ri';



import { IoGitPullRequestOutline, IoSendSharp, IoSendOutline  } from 'react-icons/io5';
import { MdSettingsApplications, MdOutlineTextsms , MdCallReceived, MdApi} from 'react-icons/md';
import { GrDocumentConfig, GrShieldSecurity, GrUserAdmin, GrSchedulePlay  } from 'react-icons/gr';
import { VscExtensions, VscFileBinary } from 'react-icons/vsc';
import { WiCloud } from 'react-icons/wi';
import { BiChat, BiMessageSquareEdit } from 'react-icons/bi';
import { BsPersonBadge } from 'react-icons/bs';
import { FaFileContract } from 'react-icons/fa';
import { AiOutlineSchedule, AiOutlineFieldNumber } from 'react-icons/ai';



import { pink } from '@mui/material/colors';
import { Colors } from 'themes/theme';

interface IProps {
  icon: string;
  color?: string;
  size?: string;
  className?: string;
  style?: CSSProperties;
  attr?: SVGAttributes<SVGElement>;
}

// const DynamicIcon: React.FC<IProps> = ({ ...props }) => {
//   const [library, iconComponent] = props.icon.split("/");

//   if (!library || !iconComponent) return <div>Could Not Find Icon</div>;

//   const lib = library.toLowerCase();
//   const Icon = loadable(() => import(`react-icons/${lib}/index.js`), {
//     resolveComponent: (el: JSX.Element) =>
//       el[iconComponent as keyof JSX.Element]
//   });

//   const value: IconContext = {
//     color: props.color,
//     size: props.size,
//     className: props.className,
//     style: props.style,
//     attr: props.attr
//   };

//   return (
//     <IconContext.Provider value={value}>
//       <Icon />
//     </IconContext.Provider>
//   );
// };

// RiQrCodeFill

export const iconFromName = (iconName: string) : React.ReactNode => {

  switch(iconName) {

    case 'FaFileContract': return <FaFileContract />
    case 'BiMessageSquareEdit': return <BiMessageSquareEdit />
    case 'HiDocumentReport': return <HiDocumentReport />
    case 'AiOutlineSchedule': return <AiOutlineSchedule color={Colors.primary} size={16} />
    case 'RiMoreFill': return <RiMoreFill />

    case 'FiSettings': return <FiSettings />


    case 'MdSecurity': return <MdSecurity />    
    case 'MdPermDataSetting': return <MdPermDataSetting />    // configure
    case 'MdProductionQuantityLimits': return <MdProductionQuantityLimits />    
    case 'SiWebmoney': return <SiWebmoney />    
    case 'CgMoreO': return <CgMoreO />    
    case 'TbFileReport': return <TbFileReport />    


    case 'IoGitPullRequestOutline': return <IoGitPullRequestOutline />

    case 'IoSendSharp': return <IoSendSharp />
    case 'IoSendOutline': return <IoSendOutline />
    case 'MdContacts': return <MdContacts />
    case 'MdCallReceived': return <MdCallReceived />
    

    case 'MdSettingsApplications': return <MdSettingsApplications />
    case 'GrDocumentConfig': return <GrDocumentConfig />
    case 'GrShieldSecurity': return <GrShieldSecurity />
    case 'GrUserAdmin': return <GrUserAdmin />
    case 'VscExtensions': return <VscExtensions />
    case 'VscFileBinary': return <VscFileBinary />
    case 'RiQrCodeFill': return <RiQrCodeFill />

    case 'MdOutlineTextsms': return <MdOutlineTextsms />
    case 'TbApiApp': return <TbApiApp />  
    
    case 'MdApi': return <MdApi />  
    
    case 'BiChat': return <BiChat />       
    case 'BsPersonBadge': return <BsPersonBadge />       

    case 'AiOutlineFieldNumber': return <AiOutlineFieldNumber />       


    default: return <WiCloud />;
  }


}

//export default DynamicIcon;