
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IMenu } from "components/models/Menu";

import { IoSearchCircleSharp } from 'react-icons/io5';


const _ = () => {

    const { t, i18n } = useTranslation();

    const [applicationMenus,setApplicationMenus] = useState<IMenu[]>([]);

    const menu1 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu2 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu3 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }

    const menu4 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu5 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu6 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }

    //const [applicationMenus,setApplicationMenus] = useState<IMenu[]>();

        useEffect( () => {
          setApplicationMenus(
            [
              {
                ...menu1, name: 'param', text: t('Setting'), iconName:'FiSettings', isOpen: false,
                menuItems: [
                  {name: 'companySetup', text: t('Company Setup'), iconName: 'IoSearchCircleSharp', entityName: 'CompanySetup', routeToGo: '/companySetup',   order: 1, group: 1},
                  {name: 'product', text: t('Product'), entityName: 'Product', routeToGo: '/product',  order: 1, group: 2},
                  {name: 'businessApplication', text: t('Business app.'), iconName: 'MdSettingsApplications', entityName: 'BusinessApplication', routeToGo: '/businessApplication',  order: 1, group: 3},
                  {name: 'requestType', text: t('Request type'), iconName: 'GrDocumentConfig', entityName: 'RequestType', routeToGo: '/requestType',  order: 2, group: 3},           
                ]
              }, 
              {
                ...menu2, name: 'security', text: t('Security'), iconName:'MdSecurity', isOpen: false,
                menuItems: [
                  {name: 'role', text: t('Role'), iconName: 'GrShieldSecurity', entityName: 'Role', routeToGo: '/role',  order: 1, group: 1},
                  //{name: 'group', text: 'Group', entityName: 'Role', routeToGo: '/',  order: 1, group: 1},
                  //{name: 'profile', text: 'Profile', entityName: 'Profile', routeToGo: '/',  order: 1, group: 1},
                  {name: 'user', text: t('User'), iconName: 'GrUserAdmin', entityName: 'User', routeToGo: '/user',order: 2, group: 1},
                ]
              },
              {
                ...menu3, name: 'config', text: t('Configuration'), iconName:'MdPermDataSetting', isOpen: false,
                menuItems: [
                  {name: 'extensionType', text: t('Extension type'), iconName: 'VscExtensions' , entityName: 'ExtensionType',routeToGo: '/extensionType', order: 0, group: 1},
                  {name: 'enumeration', text: t('Codification'), iconName: 'RiQrCodeFill' , entityName: 'Enumeration',routeToGo: '/enumeration', order: 1, group: 1},
                  // {name: 'matrix', text: 'Matrix', entityName: 'Matrix',routeToGo: '/', order: 2, group: 1},
                  // {name: 'vector', text: 'Vector', entityName: 'Vector',routeToGo: '/', order: 2, group: 1},
                   {name: 'report', text: t('Report'), iconName: 'TbFileReport', entityName: 'Report',routeToGo: '/report', order: 1, group: 2},
                   {name: 'externalLibrary', text: t('Api file'), iconName: 'VscFileBinary', entityName: 'ExternalLibrary',routeToGo: '/externalLibrary', order: 2, group: 2},
                  //{name: 'tarification', text: t('Tarification'), entityName: 'Tarification',routeToGo: '/tarification', order: 1, group: 1},
                 
                 
                  //{name: 'workflow', text: 'Workflow', entityName: 'Workflow',routeToGo: '/', order: 1, group: 2},
                  //{name: 'entityData', text: 'EntityData', entityName: 'EntityData',routeToGo: '/', order: 1, group: 3},
                  //{name: 'queryData', text: 'QueryData', entityName: 'QueryData',routeToGo: '/', order: 2, group: 3},
                  //{name: 'report', text: 'Report', entityName: 'Report',routeToGo: '/', order: 1, group: 4},            
      
                ]
              },
              
              {
                ...menu4, name: 'production', text: t('Production'), iconName:'MdProductionQuantityLimits', isOpen: false,
                menuItems: [
                  // {name: 'person', text: t('Person'), entityName: 'Person',routeToGo: '/person', order: 1, group: 1}, 
                  // {name: 'distributionChanel', text: t('Distr. Chanel'), entityName: 'DistributionChanel',routeToGo: '/distributionChanel', order: 1, group: 2},       
                  // {name: 'intermediary', text: t('Intermediary'), entityName: 'Intermediary',routeToGo: '/intermediary', order: 2, group: 2}, 
                  // {name: 'agent', text: 'Agent', entityName: 'Agent',routeToGo: '/agent', order: 3, group: 2}, 
                  //{name: 'requestData', text: t('Request'), iconName: 'IoGitPullRequestOutline', entityName: 'RequestData',routeToGo: '/requestData', order: 1, group: 3},                   
                  {name: 'customer', text: t('Customer'), iconName: 'BsPersonBadge', entityName: 'Message',routeToGo: '/customer', order: 1, group: 1}, 
                  {name: 'message', text: t('Message'), iconName: 'BiChat', entityName: 'Message',routeToGo: '/message', order: 1, group: 2}, 
                ]
              },
              {
                ...menu5, name: 'finance', text: t('Finance'), iconName:'SiWebmoney', isOpen: false,
                menuItems: [
                  {name: 'billing', text: t('Billing'), entityName: 'Billing',routeToGo: '/billing', order: 1, group: 1},       
                  
                  //{name: 'paymentOperation', text: 'Payment Operation', entityName: 'Payment Operation',routeToGo: '/', order: 1, group: 2},
                ]
              },
              {
                ...menu6, name: 'more', text: t('More'), iconName:'CgMoreO', isOpen: false,
                menuItems: [
                  // {name: 'editionConfig', text: 'EditionConfig', entityName: 'EditionConfig',routeToGo: '/', order: 1, group: 1},       
                  // {name: 'objectEdition', text: 'ObjectEdition', entityName: 'ObjectEdition',routeToGo: '/', order: 1, group: 1}, 
                  // {name: 'routineObjectData', text: 'RoutineObjectData', entityName: 'RoutineObjectData',routeToGo: '/', order: 1, group: 1},
                  // {name: 'routineFilter', text: 'RoutineFilter', entityName: 'RoutineFilter',routeToGo: '/', order: 1, group: 1},
                  {name: 'smsApi', text: t('SmsApi'), iconName: 'TbApiApp', entityName: 'SmsApi',routeToGo: '/smsApi', order: 1, group: 2},
                  {name: 'job', text: t('Job schedule'), iconName: 'GrSchedulePlay', entityName: 'Job',routeToGo: '/job', order: 1, group: 2},
                  // {name: 'postingRule', text: 'PostingRule', entityName: 'PostingRule',routeToGo: '/', order: 1, group: 3},
                ]
              },
              ]
          );
        },[t, i18n]);

        return {
            applicationMenus, setApplicationMenus
        }
  }

  export default _;