import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IContract,  ICustomerSearch, IPhoneInformation } from "../models/Contract";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    // const createCustomer = async (customer: ICustomer)  =>       
    //     await (await axios.post('/api/production/customer/create', customer)).data;       
        
    const updateContract = async (contract: IContract)  =>       
        await (await axios.post('/api/production/contract/update', contract)).data; 
    
    const getContract = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/contract/get-contract/${id}`));
      return await data;
    }

    const getContractActivation = async () : Promise<string> => {
      const {data} = (await axios.get(`/api/production/contract/get-contract-activation`));
      return await data;
    }

    // : Promise<{status: {accountStatus: {status: string, substatus: string}}}>
    const getContractStatus = async ()  => {
      const {data} = (await axios.get(`/api/production/contract/get-conctract-status`));
      return await data;
    }

    const getContractPhoneInformation = async () : Promise<IPhoneInformation>  => {
      const {data} = (await axios.get(`/api/production/contract/get-conctract-phone-information`));
      return await data;
    }

    // const getContracts = async (criteria: ICustomerSearch) : Promise<IContract[]> => {
    // const {fullName} = criteria;

    // const {data} = (await axios.get(`/api/production/customer/get-customers?fullName=${fullName}`));
    // return await data;
    // }
      
    return {    
      //createCustomer,
      updateContract,

      getContract,
      getContractStatus,
      getContractActivation,

      getContractPhoneInformation
      // getContracts
      
    } 
}

export default _;


export const useBasicFilterContract = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IContract) => void  ) => {

  // const { getCustomers } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headContractCells, setHeadContractCells]  = useState<HeadCell<IContract>[]>([
    {id:'description', label : t('Name'),  display: true, type: 'string', },
    
  ]); 

  const [filterElements, ] = useState([       
        {name: 'fullName', text: t('Name'), value: ''}, 
         
      ]);

  const [filteredCustomers, ] = useState<IContract[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IContract[]> => {
            
    const fullName = filterElements.find( elt => elt.name === 'fullName')?.value || '';
    
    // const arr = await getCustomers( {fullName} );
    
    // return arr;
    return [];
  }

  const objKey: keyof IContract = 'id';

  return {
    title: t('Contract'), headCells: headContractCells, objKey,
    filterElements, rows: filteredCustomers, 
    onFilterButtonClick, onRowDoubleClick
  }
}

