
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {useWindowSize} from 'react-use';

import { useTheme } from '@mui/material/styles';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { VictoryChart, VictoryContainer, VictoryLabel, VictoryPie, VictoryTheme } from 'victory';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter } from 'themes/commonStyles';

import useMessageService from 'features/production/services/Message';
import entityService, { ICustomerApi, useBasicFilterEntity } from 'features/services/Entity';

import {IDashboardMessage, defaultDashboardMessage} from 'features/production/models/Message';

import { defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import WidgetSummary from './homeWidgets/WidgetSummary';
import WidgetBarChart from './homeWidgets/WidgetBarChart';
import WidgetPieChart from './homeWidgets/WidgetPieChart';
import BasicButtonList from 'components/ui/BasicButtonList';

import BoltIcon from '@mui/icons-material/Bolt';
import { FormProvider, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

import { globalConfig } from 'config';



const defaultApi : ICustomerApi = {
  baseUrl: '',

  name: '',
  description: '',

  method: '',
  routeName: '',

  body: ''
}



export const ApiForm = () => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();

  const {getCustomerApis, getCustomerApi } = entityService();

  const [apis, setApis] = useState<ICustomerApi[]>([]);

  // const [reportDatasMonth, setReportDatasMonth] = useState<IDashboardCountItem[]>([])
  // const [reportDataItemsMonth, setReportDataItemsMonth] = useState<IDashboardCountItem[]>([])

  const methods = useForm<ICustomerApi>({defaultValues:{...defaultApi}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;


  const handleApiClick = async (api: ICustomerApi) => {
    const _api = await getCustomerApi(api.name);

    reset({..._api, baseUrl: globalConfig.get().apiUrl });
  }
  

  useEffect( () => {        
        
    async function _() {          
      const arr = await getCustomerApis();
      
      setApis(arr);
    }

    _();
  }, []); 

  useEffect( () => {        
    setCurrentFormName(t('Api'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);    
 
  
  return (
    <FormProvider {...methods} >
      <Box sx={{ mx: 0.1 }}>
        <Grid container rowSpacing={3} columnSpacing={3}>
          
          <Grid item xs={12} sm={5} md={4} lg={3} >
            <div>
              <BasicButtonList<ICustomerApi> items={apis} icon={BoltIcon}
                      onItemClick={handleApiClick} 
                      valueKey={'name'} displayKey={'description'} title={t('Api')} /> 
            </div>
          </Grid>
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <Stack flexDirection='column'  >
              <Box sx={{ mt: 1, width: '100%' }} >
                <TextField sx={{width:'calc(100% - 8px)'}} id="id" label="POST/GET" {...register('method')} inputProps={ {readOnly: true}} /> 
              </Box>
              <Box sx={{ mt: 1, width: '100%' }} >
                <TextField sx={{width:'calc(100% - 8px)'}} id="id" label="Route" {...register('routeName')} inputProps={ {readOnly: true}} /> 
              </Box>
              <Box sx={{ mt: 1, width: '100%' }} >
                <TextField sx={{width:'calc(100% - 8px)'}} id="id" label="Body" multiline rows={5}
                  {...register('body')} inputProps={ {readOnly: true}} /> 
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  )
}
