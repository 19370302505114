
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useRecoilState } from 'recoil';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';


import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom } from 'library/store';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';

import useUserService, { useBasicFilterUser } from './services/User';
import { IUser, defaultUser, IUserRole, IUserBusinessApplication, IUserRequestType } from './models/User';
import { useBasicFilterRole } from './services/Role';
import {IRole} from './models/Role';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IResult } from 'library/interface';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import Button from '@mui/material/Button';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterBusinessApplication } from 'features/setup/services/BusinessApplication';
import { IBusinessApplication } from 'features/setup/models/BusinessApplication';
import { useBasicFilterRequestType } from 'features/setup/services/RequestType';
import { IRequestType } from 'features/setup/models/RequestType';
import md5 from 'md5';
import EntityExpression from 'components/ui/EntityExpression';


export const UserForm: FC<IUser> = (props: IUser = defaultUser) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntityExpressionSyntax} = useEntityService();

  const { createUser, updateUser, getUser, getUsers } = useUserService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

    const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
    const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
    const basicFilterUser = useBasicFilterUser( 
        (event: React.MouseEvent<unknown>, row: IUser) => {
            setIsSearchBoxShow(false);
            _setId(row.id);
        }
    );

    const emptyFunc = (obj: any) => {}
    const [openRoleFilter, setOpenRoleFilter] = useState(false);
    const basicFilterRole = useBasicFilterRole( 
        (event: React.MouseEvent<unknown>, row: IRole) => {
            const {id, name, description} = row;

            if(getValues().userRoles.some( b => b.roleId === id)) return;
                       
            (refAppendUserRoles.current??emptyFunc)({ roleId: id,  
                    roleName: name, roleDescription: description }); 
            
            setOpenRoleFilter(false);
        }
    );

    const [openBusinessApplicationFilter, setOpenBusinessApplicationFilter] = useState(false);
    const basicFilterBusinessApplication = useBasicFilterBusinessApplication( 
        (event: React.MouseEvent<unknown>, row: IBusinessApplication) => {
            const {id, name, description} = row;

            if(getValues().userBusinessApplications.some( b => b.businessApplicationId === id)) return;
            
            (refAppendUserBusinessApplications.current??emptyFunc)({ businessApplicationId: id,  
                businessApplicationName: name, businessApplicationDescription: description }); 
            
            setOpenBusinessApplicationFilter(false);
        }
    );

    const [openRequestTypeFilter, setOpenRequestTypeFilter] = useState(false);
    const basicFilterRequestType = useBasicFilterRequestType( 
        (event: React.MouseEvent<unknown>, row: IRequestType) => {
            const {id, name, description} = row;

            //if(getValues().userRequestTypes.some( b => b.requestTypeId === id)) return;
            
            (refAppendUserRequestTypes.current??emptyFunc)({ requestTypeId: id,  
                requestUserName:'', requestUserPassword:'', accessCode: '', applicantCode: '', intermediaryAccess: '',
                requestTypeName: name, requestTypeDescription: description }); 
            
            setOpenRequestTypeFilter(false);
        }
    );

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState('RequestData'); 

  const [userRequestTypeIndex, setUserRequestTypeIndex] = useState<number>(-1);

  const methods = useForm<IUser>({defaultValues:defaultUser});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IUser>,Error,IUser>(
    _id>0?updateUser:createUser, {   
        onSuccess: (data: IResult<IUser>) => {
        enqueueSnackbar( 'Operation done !!!', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                
        //reset(data.data);
        _setId(data.data.id);
        //setCurrentEntityIdForAction(data.data.id);
        
        queryClient.invalidateQueries(['User',data.data.id]);
        },
        onError: (err: Error) => {          
        enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
    });

    const {data: _data, refetch} = useQuery<IUser>(['User', _id], () => retrieveEntity('User',_id), 
        {refetchOnWindowFocus: false ,enabled: false } );

    const [headUserRoleCells, setHeadUserRoleCells]  = useState<HeadCell<IUserRole>[]>([
        {id:'id', label : t('Id'),  display: false, type: 'numeric', },
        {id:'roleName', label : t('Name'),  display: true, type: 'string', },
        {id:'roleDescription', label : t('Description'),  display: false, type: 'string', },    
      ]); 
    const refAppendUserRoles = useRef<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateUserRole = useRef<(index: number,value: Partial<FieldArray<IUser>> ) => void>(null);
    const refRemoveUserRole = useRef<(index: number ) => void>(null);

    const handleAddRoles = (event: any) => {
        setOpenRoleFilter(true);
      }

    const handleOkFilterRole = () => {
        setOpenRoleFilter(false);
    }
  
    const userRoleRowActionIcon = ( userRole: IUserRole) : ActionIconTableRow<IUser,IUserRole> => {
  
        const res: ActionIconTableRow<IUser,IUserRole> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IUserRole) => {
            
            (refRemoveUserRole.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    const getInputAdornmentBusinessApplicationUser = (row: IUserBusinessApplication, cellId: keyof IUserBusinessApplication)  => ({
 
        toolTip: 'Select Query',
        icon: ArrowDropDownCircleIcon,
        onClickIcon: (event: any, index: number, row: IUserBusinessApplication ) => {
          
          //handleClickSearchBusinessApplicationQuery('codification')(undefined);
        }  
     })
    
      const cellBusinessAppUserEditable = (row: IUserBusinessApplication, cellId: keyof IUserBusinessApplication) => {
        return true;
     }

    const [headUserBusinessApplicationCells, setHeadUserBusinessApplicationCells]  = useState<HeadCell<IUserBusinessApplication>[]>([
        {id:'id', label : t('Id'),  display: false, type: 'numeric', },
        {id:'businessApplicationName', label : t('Name'),  display: true, type: 'string', },
        {id:'businessApplicationUserName', label : t('User Name'),  display: true, type: 'string', 
            isEditable: cellBusinessAppUserEditable, getInputAdornment: getInputAdornmentBusinessApplicationUser },
        {id:'businessApplicationDescription', label : t('Description'),  display: false, type: 'string', },    
      ]); 
    const refAppendUserBusinessApplications = useRef<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateUserBusinessApplication = useRef<(index: number,value: Partial<FieldArray<IUser>> ) => void>(null);
    const refRemoveUserBusinessApplication = useRef<(index: number ) => void>(null);

    const handleAddBusinessApplications = (event: any) => {
        setOpenBusinessApplicationFilter(true);
      }

    const handleOkFilterBusinessApplication = () => {
        setOpenBusinessApplicationFilter(false);
    }

    
  
    const userBusinessApplicationRowActionIcon = ( userRole: IUserBusinessApplication) : ActionIconTableRow<IUser,IUserBusinessApplication> => {
  
        const res: ActionIconTableRow<IUser,IUserBusinessApplication> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IUserBusinessApplication) => {
            
            (refRemoveUserBusinessApplication.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    const getInputAdornmentRequestTypeAccessCondition = (row: IUserRequestType, cellId: keyof IUserRequestType)  => ({
 
        toolTip: 'Select Query',
        icon: DeveloperModeIcon,
        onClickIcon: (event: any, index: number, row: IUserRequestType ) => {
          
          setCurrentExpression(row.accesCondition);
          setOpenEntityExpression(true);
        }  
     })

    const cellEditable = (row: IUserRequestType, cellId: keyof IUserRequestType) => {
        return true;
     }

    const [headUserUserRequestTypeCells, setHeadUserRequestTypeCells]  = useState<HeadCell<IUserRequestType>[]>([
        {id:'id', label : t('Id'),  display: false, type: 'numeric', },
        {id:'requestTypeName', label : t('Name'),  display: true, type: 'string', width: 35 },
        {id:'requestUserName', label : t('User name'),  display: true, type: 'string', isEditable: cellEditable , width: 10},  
        //{id:'requestUserPassword', label : t('User password'),  display: true, type: 'string', isEditable: cellEditable },
        {id:'accessCode', label : t('Access code'),  display: true, type: 'string', isEditable: cellEditable, width: 10 },  
        {id:'applicantCode', label : t('Applicant code'),  display: true, type: 'string', isEditable: cellEditable, width: 20 },  
        {id:'intermediaryAccess', label : t('Intermediary code'),  display: true, type: 'string', isEditable: cellEditable, width: 20,
            getInputAdornment: getInputAdornmentRequestTypeAccessCondition },  
        {id:'priority', label : t('Priority'),  display: true, type: 'numeric', isEditable: cellEditable, width: 5 },  
        
      ]); 
    const refAppendUserRequestTypes = useRef<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateUserRequestType = useRef<(index: number,value: Partial<FieldArray<IUser>> ) => void>(null);
    const refRemoveUserRequestType = useRef<(index: number ) => void>(null);

    const handleAddRequestTypes = (event: any) => {
        setOpenRequestTypeFilter(true);
      }

    const handleOkFilterRequestType = () => {
        setOpenRequestTypeFilter(false);
    }

    const handleUserRequestTypeSelected = (event: React.MouseEvent<unknown>,index: number,row: IUserRequestType) => {      
      
        setUserRequestTypeIndex(index);            
    }
  
    const userRequestTypeRowActionIcon = ( userRole: IUserRequestType) : ActionIconTableRow<IUser,IUserRequestType> => {
  
        const res: ActionIconTableRow<IUser,IUserRequestType> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IUserRequestType) => {
            
            (refRemoveUserRequestType.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    const handleClickOkExpression = async () => {

        if(getValues().userRequestTypes.length == 0 ||  getValues().userRequestTypes.length <= userRequestTypeIndex)
            return;

        const userRequestType = getValues().userRequestTypes[userRequestTypeIndex];

        const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
        
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }        

        if(returnType !== 'boolean')
            enqueueSnackbar( t('The result of expression must be boolean') , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          else
            (refUpdateUserRequestType.current??emptyFunc)(userRequestTypeIndex, 
                {...userRequestType, accesCondition: currentExpression});

        setOpenEntityExpression(false);
    }


    useEffect( () => {                
        setCurrentFormNameAtom(t('User'));
        setCurrentBasicTextFilterProps(basicFilterUser);
      }, []);


      /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
    useEffect( () => {
        // setCurrentFormName(t('Billing'));        
        
        if(_id > 0)
          retrieveData('User',_id, refetch);  
      }, [_id] );


    useEffect( () => {
      
    if(_data && _data.id > 0) {
        reset(_data);
    }
    }, [_data]);

  const newData = async (event: MouseEvent<HTMLButtonElement>) => {        
    reset(defaultUser);    
  }
  
  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
      const data = getValues(); 

      const {password} = data;        

      if(data.userName.trim() === '' || data.userDescription.trim() === '') {
          enqueueSnackbar( t('User name/description is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }

    if(data.password.trim() === '') {
        enqueueSnackbar( t('Password is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
        }

    mutate({...data, isPasswordChanged: password !== '@@@@@@@@' ,
        passwordMD5: md5(password),password: '',   });
  }

  const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
    openEntityActionDrawer('User', _id);
  }
  
  
  
  const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
  //    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
  //    reset(_data);        
   }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1} columnSpacing={0.2}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >

                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(20% - 8px)'}} id="userName" label={t('Login')} {...register('userName')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(25% - 8px)'}} label={t('Password')} {...register('password')} 
                                    type="password" id="password" autoComplete="current-password"
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <FormControlLabel sx={{width:'calc(15% - 8px)'}}
                                    label={t('Active ?')}
                                    control={
                                    <Controller
                                        name='isActive'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />}                                
                                />
                                <TextField sx={{width:'calc(30% - 8px)'}} id="code" label={t('User description')} {...register('userDescription')} />
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <ArrayFieldTableEx<IUser,IUserRole,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headUserRoleCells} rowsPathName='userRoles' 
                                    title={t('Roles')} rowActionIcon={userRoleRowActionIcon}  
                                    //onRowSelected={handleRoleEntitySelected}
                                                        
                                    refAppend={refAppendUserRoles as MutableRefObject<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateUserRole as MutableRefObject<(index: number,value: Partial<FieldArray<IUser>>) => void>}
                                    refRemove={refRemoveUserRole as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddRoles ,icon: AddCircleIcon,  },
                                    
                                    ]}
                                    canCheckRow={false} //canFilterColumn={false} canDisplayColumnHeader={false}
                                />
                                { openRoleFilter && <FormDialog open={openRoleFilter} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Role filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenRoleFilter(false);}} onOk={handleOkFilterRole}  >
                                        <BasicTextFilterForm<IRole> {...basicFilterRole } />
                                </FormDialog> }
                            </Box>
                        </Stack> 
                    </Grid>
                    <Grid item xs={12} md={3} component={Paper} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <ArrayFieldTableEx<IUser,IUserBusinessApplication,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headUserBusinessApplicationCells} rowsPathName='userBusinessApplications' 
                                    title={t('Applications')} rowActionIcon={userBusinessApplicationRowActionIcon}  
                                    //onRowSelected={handleRoleEntitySelected}
                                                        
                                    refAppend={refAppendUserBusinessApplications as MutableRefObject<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateUserBusinessApplication as MutableRefObject<(index: number,value: Partial<FieldArray<IUser>>) => void>}
                                    refRemove={refRemoveUserBusinessApplication as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddBusinessApplications ,icon: AddCircleIcon,  },
                                    
                                    ]}
                                    canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                />
                                { openBusinessApplicationFilter && <FormDialog open={openBusinessApplicationFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Business application filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenBusinessApplicationFilter(false);}} onOk={handleOkFilterBusinessApplication}  >
                                        <BasicTextFilterForm<IBusinessApplication> {...basicFilterBusinessApplication } />
                                </FormDialog> }
                            </Box>
                        </Stack> 
                    </Grid>
                    <Grid item xs={12} md={7} component={Paper} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <ArrayFieldTableEx<IUser,IUserRequestType,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headUserUserRequestTypeCells} rowsPathName='userRequestTypes' 
                                    title={t('Request type')} rowActionIcon={userRequestTypeRowActionIcon}  
                                    onRowSelected={handleUserRequestTypeSelected}
                                                        
                                    refAppend={refAppendUserRequestTypes as MutableRefObject<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateUserRequestType as MutableRefObject<(index: number,value: Partial<FieldArray<IUser>>) => void>}
                                    refRemove={refRemoveUserRequestType as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddRequestTypes ,icon: AddCircleIcon,  },
                                    
                                    ]}
                                    canCheckRow={false} //canFilterColumn={false}
                                />
                                { openRequestTypeFilter && <FormDialog open={openRequestTypeFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenRequestTypeFilter(false);}} onOk={handleOkFilterRequestType}  >
                                        <BasicTextFilterForm<IRequestType> {...basicFilterRequestType } />
                                </FormDialog> }
                                { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  } 
                            </Box>
                        </Stack> 
                    </Grid>
                    
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

