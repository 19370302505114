import useAxios from 'library/axios'; 

import { IPasswordChange } from '../models/User';

const _ = () => {

    const axios = useAxios();

    const changePassword = async (passwordChange: IPasswordChange) => 
        await (await axios.post('/api/identity/account/change-password', passwordChange)).data;

    const getDashboard = async ()  => {
        const {data} = (await axios.get('/crm/bankUser/getDashboard'));
        return await data;
    }

    return {
       
        changePassword,
       
        getDashboard   
    } 
}

export default _;