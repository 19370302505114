// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, CardHeader, Typography } from '@mui/material';
// utils
//import { fShortenNumber } from '../../../utils/formatNumber';
// components
//import Iconify from '../../../components/iconify';

import ReactApexChart from 'react-apexcharts';

import { GrFormPreviousLink, GrFormNextLink } from 'react-icons/gr';
import useChart from 'library/useChart';


// ----------------------------------------------------------------------


export interface WidgetBarChartProps {    
    title: string,
    subheader: string,
   
    chartLabels: string[], 
    chartData: {
      name: string,
      type: string,
      fill: string,
      data: number[],
    }[],//ApexAxisChartSeries, // | ApexNonAxisChartSeries,

    
}

export default function WidgetBarChart(prop: WidgetBarChartProps) {

  const {title,  subheader, chartLabels, chartData} = prop;


  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'category',
      categories: chartLabels },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number): string => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} msg(s)`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card
      // sx={{
      //   py: 5,
      //   boxShadow: 0,
      //   textAlign: 'center',
      //   color: (theme) => theme.palette['primary'].dark, //.palette[color].darker,
      //   bgcolor: (theme) => theme.palette['primary'].light,
      //   //...sx,
      // }}
      //{...other}
    >
      <CardHeader title={title} subheader={subheader} /> 

      <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
    </Card>
  );
}