
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {useQuery,useQueryClient, QueryClient, QueryClientProvider, useMutation, useQueries, UseQueryResult } from "react-query";
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { currentBasicTextFilterPropsAtom, isSearchBoxShowAtom, currentFormNameAtom } from 'library/store';

import { IResult } from 'library/interface';
import entityService from 'features/services/Entity';
import useBusinessApplicationService, { useBasicFilterBusinessApplication } from './services/BusinessApplication';

import { IBusinessApplication, defaultBusinessApplication, IBusinessApplicationQuery, IBusinessApplicationQueryParameter } from './models/BusinessApplication';

import { IRequestType, defaultRequestType } from './models/RequestType';
import useRequestTypeService from './services/RequestType';
import { useRecoilState } from 'recoil';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';




export const BusinessApplicationForm: FC<IBusinessApplication> = (props: IBusinessApplication = defaultBusinessApplication) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData} = entityService();
  
  const {createBusinessApplication, updateBusinessApplication } = useBusinessApplicationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterBusinessApplication = useBasicFilterBusinessApplication( 
        (event: React.MouseEvent<unknown>, row: IBusinessApplication) => {
            setIsSearchBoxShow(false);
            _setId(row.id);
        }
    );

  const emptyFunc = (obj: any) => {}

  const methods = useForm<IBusinessApplication>({defaultValues:defaultBusinessApplication});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const [businessApplicationQueryIndex, setBusinessApplicationQueryIndex] = useState<number>(-1);

  const watchIsAuthenticatedApi = watch('isAuthenticatedApi');
  const watchIsDirectConnection = watch('isDirectConnection');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IBusinessApplication>,Error,IBusinessApplication>(
      _id>0?updateBusinessApplication:createBusinessApplication, {   
        onSuccess: (data: IResult<IBusinessApplication>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['BusinessApplication',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

    const {data: _data, refetch} = useQuery<IBusinessApplication>(['BusinessApplication', _id], () => retrieveEntity('BusinessApplication',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const cellEditableQuery = (row: IBusinessApplicationQuery, cellId: keyof IBusinessApplicationQuery) => {
        return true;
     }

    const [headBusinessAppicationQueryCells, setHeadBusinessAppicationQueryCells]  = useState<HeadCell<IBusinessApplicationQuery>[]>([]);
    useEffect(() => {
      setHeadBusinessAppicationQueryCells([            
        {id:'id', label : t('Id'),  display: false, type: 'numeric', },
        {id:'name', label : t('Name'),  display: true, type: 'string', width:50, isEditable: cellEditableQuery },
        {id:'description', label : t('Description'),  display: true, type: 'string', width:50, isEditable: cellEditableQuery },
      ]  )
    }, [t,i18n])
            
    const refAppendBusinessApplicationQueries = useRef<(value: Partial<FieldArray<IBusinessApplication>> | Partial<FieldArray<IBusinessApplication>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateBusinessApplicationQuery = useRef<(index: number,value: Partial<FieldArray<IBusinessApplication>> ) => void>(null);
    const refRemoveBusinessApplicationQuery = useRef<(index: number ) => void>(null);
  
    const handleAddQuery = (event: any) => {

      (refAppendBusinessApplicationQueries.current??emptyFunc)({ name: '',  
         description: '', query: '', requestTypeBusinessAppCodifications: [] });
    }

    const handleQuerySelected = (event: React.MouseEvent<unknown>,index: number,row: IBusinessApplicationQuery) => {      
      setBusinessApplicationQueryIndex(index);
  }

    const requestBusinessApplicationQueryRowActionIcon = ( requestTypeBusinessApp: IBusinessApplicationQuery) : ActionIconTableRow<IBusinessApplication,IBusinessApplicationQuery> => {
  
      const res: ActionIconTableRow<IBusinessApplication,IBusinessApplicationQuery> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IBusinessApplicationQuery) => {
          
          (refRemoveBusinessApplicationQuery.current??emptyFunc)(index);            
        }
      }
      return res;
  }


  const cellEditableParameter = (row: IBusinessApplicationQueryParameter, cellId: keyof IBusinessApplicationQueryParameter) => {
    return true;
 }

 const getDataTypesList = (row: IBusinessApplicationQueryParameter, cellId: keyof IBusinessApplicationQueryParameter, 
  opts: {value: string, name: string}[]) => {        

  //const {requestClassEnumerationCode} = row;
  
  return [ 
    {value: 'string', name: t('Text')}, {value: 'integer', name: t('Integer')}, {value: 'decimal', name: t('Decimal')},
    {value: 'float', name: t('Float')}, {value: 'date', name: t('Date')}, {value: 'boolean', name: t('Boolean')},
    {value: 'time', name: t('Time')}, 
  ]; // getAsOptions(refEnumItems.current ?? [],Enum_REPORTFIELD_DATA_FORMAT);
}

  const [headBusinessAppicationQueryParameterCells, setHeadBusinessAppicationQueryParameterCells]  = useState<HeadCell<IBusinessApplicationQueryParameter>[]>([]);
  useEffect(() => {
    setHeadBusinessAppicationQueryParameterCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'parameterName', label : t('Name'),  display: true, type: 'string', width: 50, isEditable: cellEditableParameter },
      {id:'parameterDataType', label : t('Type'),  display: true, type: 'string', width: 50, isEditable: cellEditableParameter, 
        getOptions: getDataTypesList },
    ]  )
  }, [t,i18n])

  const refAppendBusinessApplicationQueryParameters = useRef<(value: Partial<FieldArray<IBusinessApplication>> | Partial<FieldArray<IBusinessApplication>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateBusinessApplicationQueryParameter = useRef<(index: number,value: Partial<FieldArray<IBusinessApplication>> ) => void>(null);
  const refRemoveBusinessApplicationQueryParameter = useRef<(index: number ) => void>(null);

  const handleAddQueryParameter = (event: any) => {
    (refAppendBusinessApplicationQueryParameters.current??emptyFunc)(
      {id:0, businessApplicationQueryId:0, jobScheduleParameters: [],
        parameterName: '',  parameterDataType: 'text' });
  }

  const businessApplicationQueryParameterRowActionIcon = ( businessApplicationQueryParameter: IBusinessApplicationQueryParameter) : ActionIconTableRow<IBusinessApplication,IBusinessApplicationQueryParameter> => {
  
    const res: ActionIconTableRow<IBusinessApplication,IBusinessApplicationQueryParameter> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBusinessApplicationQueryParameter) => {
        
        (refRemoveBusinessApplicationQueryParameter.current??emptyFunc)(index);            
      }
    }
    return res;
}
      
  const [dbmsTypes,] = useState( [       
        {name: 'SQLSERVER', text: 'Sql Server', value: ''}, 
        {name: 'ORACLE', text: 'Oracle', value: ''},     
      ]);    

      useEffect( () => {                
        setCurrentBasicTextFilterProps(basicFilterBusinessApplication);
        setCurrentFormNameAtom(t('Business application'));
      }, []);

      
    useEffect( () => {                
        if(!watchIsAuthenticatedApi) {
          setValue('apiUserName', '');
          setValue('apiPassword', '');
        }
      }, [watchIsAuthenticatedApi]);

      useEffect( () => {                
        if(watchIsDirectConnection) {
          setValue('localServerApiUrl', '');          
        }
      }, [watchIsDirectConnection]);

      /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
    useEffect( () => {
        // setCurrentFormName(t('Billing'));        
        
        if(_id > 0)
          retrieveData('BusinessApplication',_id, refetch);  
      }, [_id] );


    useEffect( () => {

      reset(defaultBusinessApplication);
      
      if(_data && _data.id > 0) {
          reset(_data);

      setBusinessApplicationQueryIndex( ( (_data.businessApplicationQueries || []).length > 0)?0 : -1 );
      
      }
    }, [_data, reset]);

  
  const newData = async (event: MouseEvent<HTMLButtonElement>) => {        
    reset(defaultBusinessApplication);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
    const data = getValues(); 
    if(data.name.trim() === '' || data.description.trim() === '') {
        enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

    console.log(data);

    mutate(data);
}

const filterData = async (event: MouseEvent<HTMLButtonElement>) => {
    //setOpenBasicTextFilterForm(true);
}

const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {
        
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
 }

  return (
    <FormProvider {...methods} >
      <Box sx={{ mx: 0.1 }}>
          <Grid container rowSpacing={1} columnSpacing={0.5}>
              <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                  <Stack flexDirection='column'  >
                      <Box sx={{ mt: 1, width: '100%' }} >
                          <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                          <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                          <Button id='btnFilter' onClick={filterData} sx={ {display:'none'}}  />                                
                          <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                          <TextField sx={{width:'calc(40% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                          <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} {...register('name')} />
                          
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                      </Box>
                      
                      <Box sx={{ mt: 1, width: '100%' }} >
                        <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                            label={t('Direct con. ?')}
                            control={
                            <Controller
                                name='isDirectConnection'
                                control={control}
                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                            />} />

                            { watchIsDirectConnection && <Controller name='dbmsType' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="dbmsType"
                                      label={t('Request type')} inputProps={ {readOnly: false}}>
                                      {dbmsTypes.map( 
                                        (x,idx) => <MenuItem key={x.name} value={x.name}>{x.text}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              /> }
                    </Box>
                    { watchIsDirectConnection && <Box sx={{ mt: 1, width: '100%' }} >
                      <TextField sx={{width:'calc(100% - 8px)'}} id="description" multiline={true} rows={3}
                        inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                        label={t('Connection string')} {...register('dbConnectionString')} />
                    </Box> }
                    { !watchIsDirectConnection && <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(100% - 8px)'}} id="localServerApiUrl" label={t('Local server url')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  {...register('localServerApiUrl')} />
                      </Box> }

                      <Box sx={{ mt: 1, width: '100%' }} >
                        <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                            label={t('Api authentication ?')}
                            control={
                            <Controller
                                name='isAuthenticatedApi'
                                control={control}
                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                            />} />
                            
                      </Box>
                      { watchIsAuthenticatedApi && <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(100% - 8px)'}} id="authenticationUrl" label={t('Authentication url')}
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  {...register('authenticationUrl')} />
                      </Box> }
                      { watchIsAuthenticatedApi && <Box sx={{ mt: 1, width: '100%' }} >
                      <TextField sx={{width:'calc(50% - 8px)'}} id="apiUserName" label={t('Api login')} {...register('apiUserName')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                            <TextField sx={{width:'calc(50% - 8px)'}} label={t('Api password')} {...register('apiPassword')} 
                                type="password" id="password" autoComplete="current-password"
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                      </Box> }
                      
                  </Stack>                        
              </Grid>
              <Grid item xs={12} md={6} lg={4}  component={Paper} >
                <Stack flexDirection='column'>
                    <Box sx={{ mt: 1, width: '100%' }} >
                        <ArrayFieldTableEx<IBusinessApplication,IBusinessApplicationQuery,'id'> 
                            mainObject={getValues()} fieldKey='id' 
                            headCells={headBusinessAppicationQueryCells} rowsPathName='businessApplicationQueries' 
                            title={t('Queries')} rowActionIcon={requestBusinessApplicationQueryRowActionIcon}  
                            onRowSelected={handleQuerySelected}
                                                                            
                            refAppend={refAppendBusinessApplicationQueries as MutableRefObject<(value: Partial<FieldArray<IBusinessApplication>> | Partial<FieldArray<IBusinessApplication>>[], options?: FieldArrayMethodProps) => void>}
                            refUpdate={refUpdateBusinessApplicationQuery as MutableRefObject<(index: number,value: Partial<FieldArray<IBusinessApplication>>) => void>}
                            refRemove={refRemoveBusinessApplicationQuery as MutableRefObject<(index: number) => void>}

                            //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                            //displayMore={undefined}
                            toolbarActions={[
                            { toolTip: `${t('Add')}...`, onClickIcon: handleAddQuery ,icon: AddCircleIcon,  },
                            
                            ]}
                        />                        
                    </Box>
                </Stack> 
              </Grid>
              <Grid item xs={12} md={6} lg={4} component={Paper} >
                <Stack flexDirection='column'>
                  {
                  getValues().businessApplicationQueries.map( (query, index) => {
                    return (index === businessApplicationQueryIndex) && (
                      <Fragment key={`key-${query.name} - ${businessApplicationQueryIndex}`}>
                        <Box sx={{ mt: 1, width: '100%' }} > 
                          <Typography  variant="h6" id="tableTitle" component="div" color="primary" noWrap >
                            {`${t(('Query'))} - ${(businessApplicationQueryIndex+1)} - ${query.name}`}
                          </Typography>                                                       
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                                key={`query-${query.name}-query-x`}
                                render={({ field }) => <TextField label={t('Query')} {...field} multiline={true} rows={8}  
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                                  name={`businessApplicationQueries.${businessApplicationQueryIndex}.query`}                      
                                control={control}
                              />                           
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IBusinessApplication,IBusinessApplicationQueryParameter,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headBusinessAppicationQueryParameterCells} rowsPathName={`businessApplicationQueries.${index}.businessApplicationQueryParameters` }
                              title={t('Parameters')} rowActionIcon={businessApplicationQueryParameterRowActionIcon}  
                              //onRowSelected={handleQuerySelected}
                                                  
                              refAppend={refAppendBusinessApplicationQueryParameters as MutableRefObject<(value: Partial<FieldArray<IBusinessApplication>> | Partial<FieldArray<IBusinessApplication>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateBusinessApplicationQueryParameter as MutableRefObject<(index: number,value: Partial<FieldArray<IBusinessApplication>>) => void>}
                              refRemove={refRemoveBusinessApplicationQueryParameter as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddQueryParameter ,icon: AddCircleIcon,  },
                              
                              ]}
                          />                        
                      </Box>
                      </Fragment> )})
                  }
                </Stack>
              </Grid>

          </Grid>
      </Box>
    </FormProvider> 
  )
}

