import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {IRequestType, IRequestTypeBusinessApp, IRequestTypeSearch } from '../models/RequestType';

const _ = () => {

    const axios = useAxios();

    const createRequestType = async (requestType: IRequestType)  =>       
        await (await axios.post('/api/setup/requestType/create', requestType)).data;       
        
    const updateRequestType = async (requestType: IRequestType)  =>       
        await (await axios.post('/api/setup/requestType/update', requestType)).data;     
        
    const getRequestType = async (id  : number )  => {
        const {data} = (await axios.get(`/api/setup/requestType/get-requestType/${id}`));
        return await data;
    }

    const getRequestTypes = async (criteria: IRequestTypeSearch) : Promise<IRequestType[]> => {
        const {name, description} = criteria;

        const {data} = (await axios.get(`/api/setup/requestType/get-requestTypes?name=${name}&description=${description}`));
        return await data;
    }

        
    return {
        createRequestType,
        updateRequestType,
        getRequestType,        
        getRequestTypes,
        
    } 
}

export default _;


export const useBasicFilterRequestType = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IRequestType) => void  ) => {

    const { getRequestTypes } = _();
  
    const { t, i18n } = useTranslation();   
      
    const [headRequestTypeCells, setHeadRequestTypeCells]  = useState<HeadCell<IRequestType>[]>([
      {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 110 },
      {id:'name', label : t('Name'),  display: true, type: 'string', },
      {id:'description', label : t('Description'),  display: true, type: 'string', },
  
    ]); 
    const [filterElements,] = useState( [       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},     
      ]);    
  
    const [filteredRequestTypes, ] = useState<IRequestType[]>([]); 
  
    const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IRequestType[]> => {
              
      const name = filterElements.find( elt => elt.name === 'name')?.value || '';
      const description = filterElements.find( elt => elt.name === 'description')?.value || '';
      
      const arr = await getRequestTypes( {name, description} );
      
      return arr;
    }
  
    const objKey: keyof IRequestType = 'id';
  
    return {
      title: t('Request types'), headCells: headRequestTypeCells, objKey,
      filterElements, rows: filteredRequestTypes, 
      onFilterButtonClick, onRowDoubleClick
    }
  }


  