
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';



import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TokenIcon from '@mui/icons-material/Token';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ICustomer, defaultCustomer, IContract, defaultContract } from './models/Contract';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import useMessageService from './services/Message';


import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, currentUserSessionAtom } from 'library/store';
import useContractService, { useBasicFilterContract } from './services/Contract';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';

import md5 from 'md5';
import { globalConfig } from 'config';
import { isFalsy } from 'utility-types';
import { typographyGroupBoxStyling } from 'themes/commonStyles';
import { DateTimePicker } from '@mui/x-date-pickers';
import { ThreeCircles } from 'react-loader-spinner';
import { green } from '@mui/material/colors';
import { SiMicrosoftexcel } from 'react-icons/si';

export const ContractForm: FC<IContract> = (props: IContract = {...defaultContract,
      
    },) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer } = entityService();

  const { updateContract, getContractStatus, getContractActivation, getContractPhoneInformation } = useContractService();

  const {generateContractMessageXlsx} = useMessageService();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterCustomer = useBasicFilterContract( 
    (event: React.MouseEvent<unknown>, row: IContract) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  const [isExportingMessages, setIsExportingMessages] = useState<boolean>(false);

  const emptyFunc = (obj: any) => {}

  const methods = useForm<IContract>({defaultValues:defaultContract});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchContractInnerStatus = watch('contractInnerStatus');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IContract>,Error,IContract>(
      _id>0?updateContract:updateContract, {   
        onSuccess: (data: IResult<IContract>) => {
          enqueueSnackbar( '_Operation_done', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Contract',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

    const {data: _data, refetch} = useQuery<IContract>(['Contract', _id], () => retrieveEntity('Contract',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const contractRowActionIcon = ( contract: IContract) : ActionIconTableRow<ICustomer,IContract> => {  
        const res: ActionIconTableRow<ICustomer,IContract> = {
          toolTip: 'remove',
          icon: RemoveCircleIcon,
          hasAction: (index: number,row: IContract) => true, 
          isActionExecuting: true,
          onRowClickIcon: (event : any, index: number, row: IContract) => {           
            
            (refRemoveContract.current??emptyFunc)(index);            
          }
        }
        return res;
      }

      

     const getInputAdornmentToken = (row: IContract, cellId: keyof IContract)  => ({
 
      toolTip: 'Token gen....',
      icon: TokenIcon,
      onClickIcon: (event: any, index: number, row: IContract ) => {
        // const contract = getValues().contracts[index];    

        // (refUpdateContract.current??emptyFunc)(index, {...contract, identityToken: generateToken(26)});
      }  
   })

      const cellEditable = (row: IContract, cellId: keyof IContract) => {
        return true;
     }
     
    const [headContractCells, setHeadContractCells]  = useState<HeadCell<IContract>[]>([]);
    useEffect(() => {
      setHeadContractCells([      
        {id:'identityToken', label : t('Identity token'),  display: true, type: 'string', 
            isEditable: cellEditable, width: 20, getInputAdornment: getInputAdornmentToken},      
        {id:'login', label : t('Login'),  display: true, type: 'string', isEditable: cellEditable, width: 10},
        {id:'password', label : t('Password'),  display: true, type: 'string', isPassword: !isPasswordVisible, isEditable: cellEditable, width: 15},
        {id:'instanceId', label : t('Instance Id'),  display: true, type: 'string',isEditable: cellEditable, width: 15} ,      
        {id:'token', label : t('Token'),  display: true, type: 'string',isEditable: cellEditable, width: 20} ,     
        {id:'description', label : t('Description'),  display: true, type: 'string',isEditable: cellEditable, width: 20} ,     
        //{id:'extensionTypeDescription', label : t('Type description'),  display: true, type: 'string',} ,  
        //{id:'extensionTypeType', label : t('Type'),  display: true, type: 'string',} ,  
      ]  )
    }, [t,i18n,isPasswordVisible])

    const refAppendContracts = useRef<(value: Partial<FieldArray<ICustomer>> | Partial<FieldArray<ICustomer>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateContract = useRef<(index: number,value: Partial<FieldArray<ICustomer>> ) => void>(null);
    const refRemoveContract = useRef<(index: number ) => void>(null);

    const rand = () => {
      return Math.random().toString(36).substring(2);
    };    
    
    const [base64QrCode, setBase64QrCode] = useState<string>('');

    const handleAddContracts = (event: any) => {
      // const minId = Math.min( ...getValues().contracts.map( ctr => ctr.id) ) - 1;
      // (refAppendContracts.current??emptyFunc)({ customerId: _data?.id ?? 0,
      //   id: 0, identityToken: generateToken(26), login: '', description: '', isPasswordChanged: true, password: '', passwordMD5: '',
      //   instanceId: '', token: '',  status: '', statusDate: new Date(), 
      //    });
    } 

    const [openInnerContractActivation, setOpenInnerContractActivation] = useState(false);
    const handleClickActiveInnerContract = async (event: any) => {
      const imgBase64 = await getContractActivation();

      setBase64QrCode(imgBase64);

      setOpenInnerContractActivation(true);
    }
    
    const handleTogglePasswordVisibility = (event: any) => {

      setIsPasswordVisible( !isPasswordVisible);
    }

    
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Customer'));
        setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);

        const {id} = currentUserSession;
        _setId(id);
      }, []);    
      
      useEffect( () => {        
        
        async function _() {          
          const status = await getContractStatus();
          setValue('contractInnerStatus', isFalsy(status) ?'': status);

          setValue('contractInnerStatusDescription', 
            status == 'initialize' ? t('Initialize') :
            status == 'qr' ? t('Qr') :
            status == 'retrying' ? t('Retrying') :
            status == 'loading' ? t('Loading') :
            status == 'authenticated' ? t('Authenticated') :
            status == 'disconnected' ? t('Disconnected') :
            status == 'standby' ? t('Standby') : '');
        }

        _();
      }, []); 

      useEffect( () => {        
        
        async function _() {
          const {phoneNumber, profilePicture} = await getContractPhoneInformation();

          setValue('whatsAppNumber', phoneNumber);
          setValue('profilePicture', profilePicture);

          //console.log(phoneInfo);
        }

        _();
      }, []);

    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Contract',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          
            reset(_data);
            console.log(_data);
        }
        }, [_data]);
    
           
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          const data = getValues(); 
          // if(data.fullName.trim() === '' ) {
          //     enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
          //           anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          //     return;
          //   }
                
          // data.contracts.forEach( ctr => {
          //     ctr.passwordMD5 = md5(ctr.password); 
          //     ctr.isPasswordChanged= (ctr.password != '********'); 
          //   } );

          mutate( {...data, hookDatas: []});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Customer', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                                              
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                
                                <TextField sx={{width:'calc(30% - 8px)'}} id="login" label={t('Name')} 
                                    {...register('login')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } } />
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                  label={t('Active ?')}
                                  control={
                                  <Controller
                                      name='isActive'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} disabled />}                        
                                />} />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="login" label={t('Status')} 
                                    helperText={['qr','standby'].includes( (isFalsy(watchContractInnerStatus)? '': watchContractInnerStatus ).toLowerCase())?t('Click to connect your WhatsApp account'):''}
                                    {...register('contractInnerStatusDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={  {
                                      readOnly: true,
                                      endAdornment: ['qr','standby'].includes( (isFalsy(watchContractInnerStatus)? '': watchContractInnerStatus ).toLowerCase())? (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickActiveInnerContract}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) : null
                                  }  } />
                                  { openInnerContractActivation && <FormDialog open={openInnerContractActivation} maxWidth='sm'
                                      okText='' cancelText='' title={`${t('Connect your WhatsApp account')} ...`} onCancel={()=> {}} 
                                      onClose={()=> {setOpenInnerContractActivation(false);}} onOk={()=> {}}  >
                                      <Stack flexDirection='column'>
                                        <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >
                                          {/* <img src={`${globalConfig.get().apiUrl}/api/production/contract/activation`}/> */}
                                          <img src={`data:image/png;base64,${base64QrCode}`}/>
                                        </Box>                        
                                      </Stack>
                                    </FormDialog>
                                  } 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="customerName" label={t('Customer')} 
                                    {...register('customerName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } }
                                     />                                                                  
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} 
                                    {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } } />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(60% - 8px)'}} id="identityToken" label={t('Identity token')} 
                                    {...register('identityToken')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } } />                                
                                <TextField sx={{width:'calc(40% - 8px)'}} id="whatsAppNumber" label={t('WhatsApp N°')} 
                                    {...register('whatsAppNumber')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } } />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="webhookUrl" label={t('Webhook URL')} 
                                    {...register('webhookUrl')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={`${getValues().profilePicture}`} >
                          {/* <img src={`${globalConfig.get().apiUrl}/api/production/contract/activation`}/> */}
                          <img src={`${getValues().profilePicture}`}/>
                        </Box>           
                      </Stack>   
                    </Grid>                   
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

