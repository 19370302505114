
export type DataType = 'text' | 'integer' | 'decimal' | 'float' | 'date' | 'boolean' | 'time' | 'enumeration' | 'entity';

export interface IExtensionType {
    id: number,
    name: string,
    description: string,

    type: DataType,
    textLength: number,
    enumerationId: number,
    enumerationName: string,
    entityName: string,
    entityDescription: string
  }


  export const defaultExtensionType : IExtensionType = {
    id: 0,
    name: '',
    description: '',
    type: 'text',

    textLength: 0,
    enumerationId: 0,
    enumerationName: '',
    entityName: '',
    entityDescription: ''
  }


  export interface IExtensionTypeSearch {
    name: string,
    description: string
  }