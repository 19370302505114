import React, {FC, PropsWithChildren, useRef, useState, useEffect} from 'react';

// import library.
import { useFieldArray, useForm, useFormContext, Controller, FormProvider, FieldArrayWithId, ArrayPath } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import {addDays, addMonths, addYears} from 'date-fns';

import { useSnackbar } from 'notistack';

import NumberFormat from 'react-number-format';
// mui ...
import {Avatar, Button, Grid, Typography, Link, Box, Paper, TextField, 
    MenuItem, IconButton, Checkbox, FormControlLabel, Stack } from '@mui/material';

    import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import { ThreeDots, ThreeCircles  } from 'react-loader-spinner';


import entityService  from 'features/services/Entity';

import useMessageService from './services/Message';

import { FaSearch } from 'react-icons/fa';
import {SiMicrosoftexcel} from 'react-icons/si';

import { pink } from '@mui/material/colors';

import { flexBetweenCenter, fullWidthFlex, justifyCenter } from 'themes/commonStyles';

import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import { DateTimePicker } from '@mui/x-date-pickers';

import useEnumerationService from 'features/configuration/services/Enumeration';
import useBusinessApplicationService, { useBasicFilterBusinessApplicationQuery } from 'features/setup/services/BusinessApplication';

import { IFeatureParameter } from 'library/interface';
import { isFalsy } from 'utility-types';

import { green } from '@mui/material/colors';

import { typographyGroupBoxStyling } from 'themes/commonStyles';


export interface IQueryParameter {
    
    isPeriod: boolean,
    options?: {value: string, name: string}[],

    isRequired: boolean,
    enumerationCode?: string,
}

interface IDataExport {

    _createdOnFrom: Date,
    _createdOnTo: Date,

    _messageType: number,
    _messageStatus: string,

    _validityStartDateFrom: Date,
    _validityStartDateTo: Date,

    _validityEndDateFrom: Date,
    _validityEndDateTo: Date,
  }

const defaultDataExport : IDataExport = {
    _createdOnFrom: new Date(),
    _createdOnTo: new Date(),

    _messageType: 0,
    _messageStatus: '',

    _validityStartDateFrom: new Date(),
    _validityStartDateTo: new Date(),

    _validityEndDateFrom: new Date(),
    _validityEndDateTo: new Date(),
}


export function  MessageExportForm()  {
    

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    //const [filterElements, setFilterElements] = filterElementsState;
   
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {getAsOptions } = useEnumerationService();

    const {retrieveEntity} = entityService();

    const {generateContractMessageXlsx} = useMessageService();

    const [isExportingMessages, setIsExportingMessages] = useState<boolean>(false);

    const methods = useForm<IDataExport>({defaultValues: {...defaultDataExport,
        _createdOnFrom: addMonths(new Date(), -1), _createdOnTo: new Date(),
        _validityStartDateFrom: addMonths(new Date(), -1), _validityStartDateTo: addYears(new Date(), 1),
        _validityEndDateFrom: addMonths(new Date(), -1), _validityEndDateTo: addYears(new Date(), 1),}});

    const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
    
    

const handleExportMessageButtonClick = async (event: any) => {

    const {_createdOnFrom, _createdOnTo, _messageType, _messageStatus,
        _validityStartDateFrom, _validityStartDateTo, _validityEndDateFrom, _validityEndDateTo} = getValues();

    const {fileToken, fileName} = await generateContractMessageXlsx( 
        {createdOnFrom: _createdOnFrom, createdOnTo: _createdOnTo,
        messageType: _messageType, messageStatus: _messageStatus,
        validityStartDateFrom: _validityStartDateFrom, validityStartDateTo: _validityStartDateTo,
        validityEndDateFrom: _validityEndDateFrom, validityEndDateTo: _validityEndDateTo
        } );

    enqueueSnackbar( `${fileName} `, { variant: 'success',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 500 });
    }


    useEffect( () => {        
        async function loadBusinessApplications() {
          //const arr = await getBusinessApplications( {name: '', dbmsType: ''} );         
          //setBusinessApplications([...arr]);
        }
  
        loadBusinessApplications();
      }, []); 

        
    return ( 
    <>
        <FormProvider {...methods} > 
            <Grid container component="main" sx={{  width: '100%' }} alignItems="flex-start">
                <Grid item xs={12} sm={12} md={12} component={Paper} square>
                    <Stack flexDirection='column' sx={{ width: '100%' }} >                        
                        
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller control={control}
                            name='_createdOnFrom' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DateTimePicker label={`${t('Created')} - (${t('Start')})`} 
                                
                                onChange={onChange}                        
                                value={value}
                                renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                              /> )}
                          />         
                          <Controller control={control}
                            name='_createdOnTo' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DateTimePicker label={`${t('Created')} - (${t('End')})`} 
                                
                                onChange={onChange}                        
                                value={value}
                                renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                              /> )}
                          />
                                                            
                        </Box>    
                        <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller name={`_messageType`} control={control}                                    
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="_messageType"
                                  label={t('Type')} inputProps={ {readOnly: false}} > 
                                    <MenuItem value={0}></MenuItem>                                           
                                    <MenuItem value={1}>{t('Text')}</MenuItem>         
                                    <MenuItem value={2}>{t('Image')}</MenuItem>      
                                    <MenuItem value={3}>{t('Document')}</MenuItem>                                   
                                    <MenuItem value={4}>{t('Video')}</MenuItem>                                   
                                </TextField>
                              )}
                            />
                            <Controller name={`_messageStatus`} control={control}                                    
                                render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="_messageStatus"
                                    label={t('Status')} inputProps={ {readOnly: false}} > 
                                        <MenuItem value=""></MenuItem>                                           
                                        <MenuItem value="sended">{t('Sended')}</MenuItem>    
                                    </TextField>
                                )}
                            /> 
                        </Box>    
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller control={control}
                            name='_validityStartDateFrom' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DateTimePicker label={`${t('Sending date')} - (${t('Start')})`}
                                
                                onChange={onChange}                        
                                value={value}
                                renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                              /> )}
                          />         
                          <Controller control={control}
                            name='_validityStartDateTo' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DateTimePicker label={`${t('Sending date')} - (${t('End')})`}
                                
                                onChange={onChange}                        
                                value={value}
                                renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                              /> )}
                          />
                                                            
                        </Box>   
                        <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller control={control}
                                name='_validityEndDateFrom' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DateTimePicker label={`${t('Expiration date')} - (${t('Start')})`}
                                    
                                    onChange={onChange}                        
                                    value={value}
                                    renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                /> )}
                            />
                            <Controller control={control}
                                name='_validityEndDateTo' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DateTimePicker label={`${t('Expiration date')} - (${t('End')})`}
                                    
                                    onChange={onChange}                        
                                    value={value}
                                    renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                /> )}
                            /> 
                        </Box>
                        <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >
                          <Button variant="outlined" onClick={handleExportMessageButtonClick} id="btnExportExcelMessage">
                            {isExportingMessages ? <ThreeCircles
                                    color='#e15b64'
                                    height={'30'}
                                    //width={100}
                                    //timeout={3000} //3 secs
                                /> : <Typography
                                        sx={{
                                            color: (theme) => theme.palette.text.primary,
                                            fontWeight: 'bold',
                                            display:{xs: 'none', md:'block'}     
                                        }} >
                                        {t('Export')}
                                    </Typography>
                            }
                                
                            <Box sx={{ ml: 1, mr: 1, mt: 0.5, }} >
                                <SiMicrosoftexcel color={green[500]} size={16} />
                            </Box>
                          </Button>
                        </Box>          
                    </Stack>  
                </Grid>
                <Grid item xs={12} component={Paper} square>                
                    <Box sx={{ mt: 1, width: '100%' }} >                      
                         
                    </Box>
                </Grid>  
            </Grid>
        </FormProvider> 
    </>
    ); 
}


