
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {useQuery,useQueryClient, QueryClient, QueryClientProvider, useMutation, useQueries, UseQueryResult } from "react-query";
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom } from 'library/store';

import { IResult } from 'library/interface';
import entityService from 'features/services/Entity';

import { IRequestType, defaultRequestType, IRequestTypeBusinessApp, IRequestTypeEx, IRequestTypeItemEx, IRequestTypeBusinessAppCodification, IRequestTypeBusinessAppCodificationMapping } from './models/RequestType';
import useRequestTypeService, {useBasicFilterRequestType} from './services/RequestType';
import { useBasicFilterBusinessApplication, useBasicFilterBusinessApplicationQuery } from './services/BusinessApplication';

import { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';

import { useRecoilState } from 'recoil';
import { IBusinessApplication, IBusinessApplicationQuery } from './models/BusinessApplication';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { IExtensionType } from 'features/configuration/models/ExtensionType';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import TextFieldRight from 'components/ui/TextFieldRight';

import useEnumerationService from 'features/configuration/services/Enumeration';
import { IEnumerationItem, Enum_FTP_PROTOCOL, Enum_TLS_SECURITY, Enum_FTP_MODE, 
     Enum_REQUEST_CLASS, Enum_REQUESTCLASS_ENUMERATION, Enum_REQUESTCLASS_ENUMERATIONITEM } from 'features/configuration/models/Enumeration';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { isEditable } from '@testing-library/user-event/dist/utils';
import { RequestTypeBusinessCodificationMapping } from './RequestTypeBusinessCodificationMapping';

type QueryType = 'masterEntity' | 'itemEntity' | 'intermediary' | 'user' | 'codification' | 'office';

export const RequestTypeForm: FC<IRequestType> = (props: IRequestType = defaultRequestType) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData} = entityService();
  
  const {createRequestType, updateRequestType } = useRequestTypeService();
  const {getExternalLibraries, getExternalLibrary} = externalLibraryService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
    const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
    const basicFilterRequestType = useBasicFilterRequestType( 
        (event: React.MouseEvent<unknown>, row: IRequestType) => {
            setIsSearchBoxShow(false);
            _setId(row.id);
        }
    );

    const emptyFunc = (obj: any) => {}
    const emptyFunc2 = (obj: any) => {}
    const [openBusinessApplicationFilter, setOpenBusinessApplicationFilter] = useState(false);
    const basicFilterBusinessApplication = useBasicFilterBusinessApplication( 
        (event: React.MouseEvent<unknown>, row: IBusinessApplication) => {
            const {id, name, description, dbmsType} = row;

            if(getValues().requestTypeBusinessApps.some( b => b.businessApplicationId === id)) return;
            
            (refAppendRequestTypeBusinessApps.current??emptyFunc)({ businessApplicationId: id,  
                    businessApplicationName: name, dbmsType, businessApplicationDescription: description,
                    returnItemReferenceApiUrl: '', isItemReferenceReturn: false,
                    requestTypeBusinessAppCodifications: [] }); 
            
            setOpenBusinessApplicationFilter(false);
        }
    );

    const refCurrentBusinessApplicationId = useRef(0);
    const [requestTypeBusinessAppIndex, setRequestTypeBusinessAppIndex] = useState<number>(-1);

    const refCurrentRequestClassEnumerationCode = useRef('');  
    const [requestTypeBusinessAppCodificationIndex, setRequestTypeBusinessAppCodificationIndex] = useState<number>(-1);

    const [openBusinessApplicationCodificationMapping, setOpenBusinessApplicationCodificationMapping] = useState(false);

    const [openBusinessApplicationQueryFilter, setOpenBusinessApplicationQueryFilter] = useState(false);
    const basicFilterBusinessApplicationQuery = useBasicFilterBusinessApplicationQuery( 
      () => {
        return refCurrentBusinessApplicationId.current;
      },
      async (event: React.MouseEvent<unknown>, row: IBusinessApplicationQuery) => {

        const {id, name } = row;

        if(requestTypeBusinessAppIndex <0 || getValues().requestTypeBusinessApps.length <= requestTypeBusinessAppIndex)
          return;

        const requestTypeBusinessApp = getValues().requestTypeBusinessApps[requestTypeBusinessAppIndex];

        (refUpdateRequestTypeBusinessApp.current??emptyFunc)(requestTypeBusinessAppIndex,
          (currentQueryType === 'masterEntity') ? 
            {...requestTypeBusinessApp, masterEntityQueryId:id, masterEntityQueryName: name } :
          (currentQueryType === 'itemEntity') ? 
            {...requestTypeBusinessApp, itemEntityQueryId:id, itemEntityQueryName: name} : //{...requestTypeBusinessApp}
          (currentQueryType === 'intermediary') ? 
            {...requestTypeBusinessApp, intermediaryEntityQueryId:id, intermediaryEntityQueryName: name } :
          (currentQueryType === 'office') ? 
            {...requestTypeBusinessApp, officeEntityQueryId:id, officeEntityQueryName: name } :
          (currentQueryType === 'user') ? 
            {...requestTypeBusinessApp, userEntityQueryId:id, userEntityQueryName: name } : {...requestTypeBusinessApp}
           ); 

        if(currentQueryType === 'codification') {
          if(requestTypeBusinessAppCodificationIndex <0 || requestTypeBusinessApp.requestTypeBusinessAppCodifications.length <= requestTypeBusinessAppCodificationIndex)
            return;
          const requestTypeBusinessAppCodification = requestTypeBusinessApp.requestTypeBusinessAppCodifications[requestTypeBusinessAppCodificationIndex];
          (refUpdateRequestTypeBusinessAppCodification.current??emptyFunc)(requestTypeBusinessAppCodificationIndex,
              {...requestTypeBusinessAppCodification, businessApplicationQueryId: id, businessApplicationQueryName: name })
        }
                
        setOpenBusinessApplicationQueryFilter(false);
      }
    );

    const [openExtensionTypeFilter, setOpenExtensionTypeFilter] = useState(false);
    const basicFilterExtensionType = useBasicFilterExtensionType( 
        (event: React.MouseEvent<unknown>, row: IExtensionType) => {
            const {id, name, description} = row;

            //if(getValues().requestTypeBusinessApps.some( b => b.businessApplicationId === id)) return;
            if(isExtensionTypeItem)
              (refAppendRequestTypeItemExs.current??emptyFunc)({ extensionTypeId: id,  
                extensionTypeName: name, description: '' }); 
            else
              (refAppendRequestTypeExs.current??emptyFunc)({ extensionTypeId: id,  
                extensionTypeName: name, description: '' });
            
            setOpenExtensionTypeFilter(false);
        }
    );

      
  const methods = useForm<IRequestType>({defaultValues:defaultRequestType});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchRequestClass = watch('requestClass');

  const watchExternalLibraryId = watch('externalLibraryId');
  const watchRequestTypeClassName = watch('requestTypeClassName');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IRequestType>,Error,IRequestType>(
      _id>0?updateRequestType:createRequestType, {   
        onSuccess: (data: IResult<IRequestType>) => {
          enqueueSnackbar( 'Operation done !!!', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['RequestType',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

    const {data: _data, refetch} = useQuery<IRequestType>(['RequestType', _id], () => retrieveEntity('RequestType',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>(
      ['EnumerationItems', 'RequestType'], () => 
        getEnumerationItemsByEnumerationCodes( [Enum_REQUEST_CLASS, Enum_REQUESTCLASS_ENUMERATION, Enum_REQUESTCLASS_ENUMERATIONITEM,
            Enum_FTP_MODE, Enum_FTP_PROTOCOL,Enum_TLS_SECURITY] ));

    const { data: externalLibraries} = 
      useQuery<IExternalLibrary[]>("externalLibraries", () => getExternalLibraries({name: '', description: ''}));
    
    const [currentLibrary, setCurrentLibrary] = useState<IExternalLibrary>(defaultExternalLibrary);
    const [currentLibraryClass, setCurrentLibraryClass] = useState<IExternalLibraryClass>(defaultExternalLibraryClass);
    

    const [headRequestTypeBusinessAppCells, setHeadRequestTypeBusinessAppCells]  = useState<HeadCell<IRequestTypeBusinessApp>[]>([]);
    useEffect(() => {
      setHeadRequestTypeBusinessAppCells([            
        {id:'id', label : t('Id'),  display: false, type: 'numeric', },
        {id:'businessApplicationName', label : t('Name'),  display: true, type: 'string', },
        {id:'dbmsType', label : t('Dbms'),  display: true, type: 'string', },    
      ]  )
    }, [t,i18n])
    
    
    const refAppendRequestTypeBusinessApps = useRef<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateRequestTypeBusinessApp = useRef<(index: number,value: Partial<FieldArray<IRequestType>> ) => void>(null);
    const refRemoveRequestTypeBusinessApp = useRef<(index: number ) => void>(null);

    const handleAddBusinessApplications = (event: any) => {
      setOpenBusinessApplicationFilter(true);
    }

    const handleOkFilterBusinessApplication = () => {
      setOpenBusinessApplicationFilter(false);
    }

    const handleBusinessAppSelected = (event: React.MouseEvent<unknown>,index: number,row: IRequestTypeBusinessApp) => {  
      //const {businessApplicationId} = row;
      requestBusinessApplicationSelected(index);    
  }  
  
  

  const requestBusinessApplicationSelected = (index: number) => {
    setRequestTypeBusinessAppIndex(index);

    const arrCodifications = (enumItems || []).filter( itm => 
        itm.enumerationCode === Enum_REQUESTCLASS_ENUMERATION && itm.parentEnumerationItemCode === getValues().requestClass ); 

    const requestTypeBusinessApp = getValues().requestTypeBusinessApps[index];
    refCurrentBusinessApplicationId.current = requestTypeBusinessApp.businessApplicationId;

      
      (refUpdateRequestTypeBusinessApp.current??emptyFunc)(index,
          {...requestTypeBusinessApp,
            requestTypeBusinessAppCodifications: arrCodifications.map( (itm, idx) => {
              
              const {code, name} = itm;
              const tmpCodification = requestTypeBusinessApp.requestTypeBusinessAppCodifications.find( c => 
                            c.requestClassEnumerationCode === code);
             
              const arrMappingValues = (enumItems || []).filter( itm => 
                  itm.enumerationCode === Enum_REQUESTCLASS_ENUMERATIONITEM && itm.parentEnumerationItemCode === code );
                return {
                  id: tmpCodification?.id ?? 0 ,
                  requestTypeBusinessAppId: requestTypeBusinessApp.id, 
                  businessApplicationQueryId: tmpCodification?.businessApplicationQueryId ?? 0,
                  businessApplicationQueryName: tmpCodification?.businessApplicationQueryName ??   '',
                  requestClassEnumerationCode: code,
                  defaultMappingCode: tmpCodification?.defaultMappingCode ?? (arrMappingValues.length>0?arrMappingValues[0].code:''),
                  useCodificationMapping: tmpCodification?.useCodificationMapping ?? false,
                  requestTypeBusinessAppCodificationMappings: tmpCodification?.requestTypeBusinessAppCodificationMappings ??[]                  
                }
            } )
          } );
  }

    const requestTypeBusinessAppRowActionIcon = ( requestTypeBusinessApp: IRequestTypeBusinessApp) : ActionIconTableRow<IRequestType,IRequestTypeBusinessApp> => {
  
      const res: ActionIconTableRow<IRequestType,IRequestTypeBusinessApp> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IRequestTypeBusinessApp) => {
          
          (refRemoveRequestTypeBusinessApp.current??emptyFunc)(index);            
        }
      }
      return res;
  }

  const getDefaultValueCodesList = (row: IRequestTypeBusinessAppCodification, cellId: keyof IRequestTypeBusinessAppCodification, 
                    opts: {value: string, name: string}[]) => {        
       
        const {requestClassEnumerationCode} = row;
        
        const arr = refEnumItems.current?.filter(itm => itm.parentEnumerationItemCode === requestClassEnumerationCode);
        return getAsOptions(arr,Enum_REQUESTCLASS_ENUMERATIONITEM);
  }

  const getCodificationCodesList = (row: IRequestTypeBusinessAppCodification, cellId: keyof IRequestTypeBusinessAppCodification, 
    opts: {value: string, name: string}[]) => {        

  const {requestClass} = getValues();

  const arr = refEnumItems.current?.filter(itm => itm.parentEnumerationItemCode === requestClass);
  
  return getAsOptions(arr,Enum_REQUESTCLASS_ENUMERATION);
}

 const getInputAdornmentQuery = (row: IRequestTypeBusinessAppCodification, cellId: keyof IRequestTypeBusinessAppCodification)  => ({
 
    toolTip: 'Select Query',
    icon: ArrowDropDownCircleIcon,
    onClickIcon: (event: any, index: number, row: IRequestTypeBusinessAppCodification ) => {
      
      handleClickSearchBusinessApplicationQuery('codification')(undefined);
    }  
 })

  const cellCodificationEditable = (row: IRequestTypeBusinessAppCodification, cellId: keyof IRequestTypeBusinessAppCodification) => {
    return true;
 }

 const [headRequestTypeBusinessAppCodificationCells, setHeadRequestTypeBusinessAppCodificationCells]  = useState<HeadCell<IRequestTypeBusinessAppCodification>[]>([]);
    useEffect(() => {
      setHeadRequestTypeBusinessAppCodificationCells([            
        {id:'id', label : t('Id'),  display: false, type: 'numeric',  },
        {id:'requestClassEnumerationCode', label : t('Code'),  display: true, type: 'string', 
          getOptions: getCodificationCodesList  },
        {id:'businessApplicationQueryName', label : t('Name'),  display: false, type: 'string', 
          getInputAdornment: getInputAdornmentQuery },    
        {id:'defaultMappingCode', label : t('Default value'),  display: true, type: 'string' , isEditable: cellCodificationEditable,
            getOptions: getDefaultValueCodesList   }, // Also here we have to be sure that we filter only for specific request class 
      ]  )
    }, [t,i18n])

    
  const refAppendRequestTypeBusinessAppCodifications = useRef<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateRequestTypeBusinessAppCodification = useRef<(index: number,value: Partial<FieldArray<IRequestType>> ) => void>(null);
  const refRemoveRequestTypeBusinessAppCodification = useRef<(index: number ) => void>(null);

  const requestTypeBusinessAppCodificationRowActionIcon = ( requestTypeBusinessAppCodification: IRequestTypeBusinessAppCodification) : ActionIconTableRow<IRequestType,IRequestTypeBusinessAppCodification> => {
  
    const res: ActionIconTableRow<IRequestType,IRequestTypeBusinessAppCodification> = {
      toolTip: 'mapping',
      icon: CompareArrowsIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: async (event : any,index: number, row: IRequestTypeBusinessAppCodification) => {
        
        //const arr = await get
        setOpenBusinessApplicationCodificationMapping(true);


        //(refRemoveRequestTypeBusinessApp.current??emptyFunc)(index);            
      }
    }
    return res;
}

const handleBusinessAppCodificationSelected = (event: React.MouseEvent<unknown>,index: number,row: IRequestTypeBusinessAppCodification) => {  
      
  //const {businessApplicationId} = row;
  const {requestClassEnumerationCode} = row;
  requestBusinessApplicationCodificationSelected(index, requestClassEnumerationCode);    
}  

const requestBusinessApplicationCodificationSelected = (index: number, requestClassEnumerationCode: string ) => {
  setRequestTypeBusinessAppCodificationIndex(index);
  refCurrentRequestClassEnumerationCode.current = requestClassEnumerationCode;
}

const cellMappingEditable = (row: IRequestTypeBusinessAppCodificationMapping, cellId: keyof IRequestTypeBusinessAppCodificationMapping): boolean => {
  return true;
}

const getCodificationMappingCodesList = (row: IRequestTypeBusinessAppCodificationMapping, cellId: keyof IRequestTypeBusinessAppCodificationMapping, 
  opts: {value: string, name: string}[]) => {        

    const {requestClassEnumerationCode} = row;


    //alert(requestClassEnumerationCode);
    // if(requestTypeBusinessAppIndex <0 || getValues().requestTypeBusinessApps.length <= requestTypeBusinessAppIndex)
    //    return[];

    // const requestTypeBusinessApp = getValues().requestTypeBusinessApps[requestTypeBusinessAppIndex];
    // if(requestTypeBusinessAppCodificationIndex <0 || requestTypeBusinessApp.requestTypeBusinessAppCodifications.length <= requestTypeBusinessAppCodificationIndex)
    //   return [];
      
    // const requestTypeBusinessAppCodification = requestTypeBusinessApp.requestTypeBusinessAppCodifications[requestTypeBusinessAppCodificationIndex];
    
    //const {requestClassEnumerationCode} = requestTypeBusinessAppCodification;
    
    const arr = refEnumItems.current?.filter(itm => itm.parentEnumerationItemCode === requestClassEnumerationCode);
    
    return getAsOptions(arr,Enum_REQUESTCLASS_ENUMERATIONITEM);
}

const [headBusinessApplicationCodificationMappingCells, setHeadBusinessApplicationCodificationMappingCells]  = useState<HeadCell<IRequestTypeBusinessAppCodificationMapping>[]>([
  {id:'id', label : t('Id'),  display: false, type: 'numeric', },
  {id:'codeFromBusiness', label : t('Origin code'),  display: true, type: 'string', isEditable: cellMappingEditable,},
  {id:'codeForRequest', label : t('Mapping code'),  display: true, type: 'string', isEditable: cellMappingEditable,
    getOptions: getCodificationMappingCodesList
    },    
]);

const refAppendRequestTypeBusinessAppCodificationMappings = useRef<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>(null);
const refUpdateRequestTypeBusinessAppCodificationMapping = useRef<(index: number,value: Partial<FieldArray<IRequestType>> ) => void>(null);
const refRemoveRequestTypeBusinessAppCodificationMapping = useRef<(index: number ) => void>(null);

 const handleAddBusinessApplicationCodificationMappings = (event: any) => {  

  (refAppendRequestTypeBusinessAppCodificationMappings.current??emptyFunc)({ id: 0,  
    requestTypeBusinessAppCodificationId: 0, codeFromBusiness: '', codeForRequest: '', 
    requestClassEnumerationCode: refCurrentRequestClassEnumerationCode.current }); 
 }

  const cellEditable = (row: IRequestTypeEx, cellId: keyof IRequestTypeEx) => {
    return true;
 }

  const [headRequestTypeExCells, setHeadRequestTypeExCells]  = useState<HeadCell<IRequestTypeEx>[]>([
    {id:'id', label : t('Id'),  display: false, type: 'numeric', },
    {id:'extensionTypeName', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', isEditable: cellEditable  },    
  ]);

  const refAppendRequestTypeExs = useRef<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateRequestTypeEx = useRef<(index: number,value: Partial<FieldArray<IRequestType>> ) => void>(null);
  const refRemoveRequestTypeEx = useRef<(index: number ) => void>(null);

  const [isExtensionTypeItem, setIsExtensionTypeItem] = useState<boolean>(false);
  const handleAddExtensionTypes = (event: any) => {
    setIsExtensionTypeItem(false);
    setOpenExtensionTypeFilter(true);
  }

  const handleAddExtensionTypeItems = (event: any) => {
    setIsExtensionTypeItem(true);
    setOpenExtensionTypeFilter(true);
  }

  const handleOkFilterExtensionType = () => {
    setOpenExtensionTypeFilter(false);
  }

  const requestTypeExRowActionIcon = ( requestTypeEx: IRequestTypeEx) : ActionIconTableRow<IRequestType,IRequestTypeEx> => {
  
    const res: ActionIconTableRow<IRequestType,IRequestTypeEx> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IRequestTypeEx) => {
        
        (refRemoveRequestTypeEx.current??emptyFunc)(index);            
      }
    }
    return res;
}

/// The same header cells definition ....

  const refAppendRequestTypeItemExs = useRef<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateRequestTypeItemEx = useRef<(index: number,value: Partial<FieldArray<IRequestType>> ) => void>(null);
  const refRemoveRequestTypeItemEx = useRef<(index: number ) => void>(null);

  const requestTypeItemExRowActionIcon = ( requestTypeEx: IRequestTypeEx) : ActionIconTableRow<IRequestType,IRequestTypeEx> => {
  
    const res: ActionIconTableRow<IRequestType,IRequestTypeEx> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IRequestTypeEx) => {
        
        (refRemoveRequestTypeItemEx.current??emptyFunc)(index);            
      }
    }
    return res;
}

const [currentQueryType, setCurrentQueryType] = useState<QueryType>('masterEntity');
const handleClickSearchBusinessApplicationQuery = (queryType: QueryType) => (event: any) => {
  setCurrentQueryType(queryType);
  setOpenBusinessApplicationQueryFilter(true);
 }

// useEffect( () => {
//   async function loadExternalLibrary() {
//     const library = await retrieveEntity('ExternalLibrary',watchExternalLibraryId);
    
//     setCurrentLibrary(library);
//   }

//   if(currentLibrary.id !== watchExternalLibraryId) {
//     loadExternalLibrary();
//   }

// }, [watchExternalLibraryId]);

// useEffect( () => {

//   if(watchRequestTypeClassName !== '') {
//     const c = currentLibrary.externalLibraryClasses.find(cl => cl.className === watchRequestTypeClassName);
    
//     if(c != null)
//       setCurrentLibraryClass(c);
//   } else
//       setCurrentLibraryClass(defaultExternalLibraryClass);

// }, [watchRequestTypeClassName, currentLibrary]);

// useEffect( () => {

//   if(watchRequestTypeClassName !== '') {
//     const c = currentLibrary.externalLibraryClasses.find(cl => cl.className === watchRequestTypeClassName);
    
//     if(c != null)
//       setCurrentLibraryClass(c);
//   } else
//       setCurrentLibraryClass(defaultExternalLibraryClass);

// }, [watchRequestClass]);


useEffect( () => {                
  setCurrentFormNameAtom(t('Request type'));
  setCurrentBasicTextFilterProps(basicFilterRequestType);

}, []);

const refEnumItems = useRef<IEnumerationItem[]>();
useEffect( () => {                
  
  let arr: IEnumerationItem[] = [];
  if(enumItems && (arr = enumItems.filter( e => e.enumerationCode === Enum_FTP_MODE)).length === 1 ) {
    setValue('requestClass', arr[0].code);
  }

  refEnumItems.current = enumItems;
}, [enumItems]);


      /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
    useEffect( () => {
      // setCurrentFormName(t('Billing'));        
      
      if(_id > 0)
        retrieveData('RequestType',_id, refetch);  
    }, [_id] );


  useEffect( () => {

    if(_data && _data.id > 0) {
        reset(_data);

    if(_data.requestTypeBusinessApps && _data.requestTypeBusinessApps.length > 0) {
      requestBusinessApplicationSelected(0);      
    }
    
  }
  }, [_data]);
  
  const newData = async (event: MouseEvent<HTMLButtonElement>) => {        
    _setId(0);  
    reset(defaultRequestType);    
  }

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
    const data = getValues(); 
    if(data.name.trim() === '' || data.description.trim() === '') {
        enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

    mutate(data);
}

const filterData = async (event: MouseEvent<HTMLButtonElement>) => {
    //setOpenBasicTextFilterForm(true);
}

const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {
        
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
 }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item container xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} direction="row" display="flex">
                    <Stack flexDirection='column' sx={{ width: '100%' }} >
                      <Box sx={{ mt: 1, width: '100%', }}  >
                        <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                        <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                        <Button id='btnFilter' onClick={filterData} sx={ {display:'none'}}  />                                
                        <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                        <TextField sx={{width:'calc(25% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                        <TextField sx={{width:'calc(45% - 8px)'}} id="name" label={t('Name')} {...register('name')} />
                        
                        <Controller name='requestClass' control={control}
                            render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="requestClass"
                                  label={t('Class')} inputProps={ {readOnly: false}}>
                                  {enumItems && enumItems.filter( e => e.enumerationCode === Enum_REQUEST_CLASS).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                                </TextField>
                              )}
                          />
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >         
                        <TextField sx={{width:'calc(25% - 8px)'}} id="code" label={t('Code')} {...register('code')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }   />                       
                        <TextField sx={{width:'calc(75% - 8px)'}} id="description" label={t('Description')} {...register('description')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }   />
                                                   
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >                                
                        <TextField sx={{width:'calc(100% - 8px)'}} id="requestDataUrl" label={t('Request url')} {...register('requestDataUrl')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }   />
                                                   
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >                                
                        <TextField sx={{width:'calc(100% - 8px)'}} id="requestDataItemStatusUpdateUrl" label={t('Request item status update url')} {...register('requestDataItemStatusUpdateUrl')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }   />
                                                   
                      </Box>
                      {/* <Box sx={{ mt: 1, width: '100%' }} >   
                        <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                          label={t('Check response after request ?')}
                          control={
                          <Controller
                              name='checkResponseAfterRequest'
                              control={control}
                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                          />} />                            
                      </Box> */}
                      <Box sx={{ mt: 1, width: '100%' }} >                                
                            <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                label={t('Has request data response document ?')}
                                control={
                                <Controller
                                    name='hasRequestDataResponseDocument'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                            <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                              label={t('Has request data item response document ?')}
                              control={
                              <Controller
                                  name='hasRequestDataItemResponseDocument'
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />                                  
                          </Box>
                    </Stack>
                  </Grid>
                  <Grid item container xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} direction="row" display="flex">                        
                      <Stack flexDirection='column' sx={{ width: '100%' }} >
                        <Box sx={{ mt: 1, width: '100%' }} >                              
                          <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                              label={t('Has response check url ?')}
                              control={
                              <Controller
                                  name='hasResponseCheckUrl'
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />                                                            
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >                              
                          <TextField sx={{width:'calc(100% - 8px)'}} id="responseCheckUrl" label={t('Response check url')} {...register('responseCheckUrl')} 
                              inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                                            
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                              label={t('Response in directory ?')}
                              control={
                              <Controller
                                  name='responseInDirectory'
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />                                                                                       
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >                            
                          <TextField sx={{width:'calc(100% - 8px)'}} id="responseFolder" label={t('Response folder')} {...register('responseDirectoryPath')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                                            
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                              label={t('Move file after treatment ?')}
                              control={
                              <Controller
                                  name='moveDirectoryFileAfterTreatment'
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />                                                                                      
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(100% - 8px)'}} id="archiveFolder" label={t('Archive folder')} {...register('archiveDirectoryPath')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />                                                            
                        </Box>
                        
                          
                          {/* <Box sx={{ mt: 1, width: '100%' }} >                                
                            <Controller name='externalLibraryId' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="dbmsType"
                                      label={t('Library')} inputProps={ {readOnly: false}}>
                                      {externalLibraries?.map( 
                                        (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                            />
                            <Controller name='requestTypeClassName' control={control}
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="requestTypeClassName"
                                  label={t('Class name')} inputProps={ {readOnly: false}}>
                                  {currentLibrary.externalLibraryClasses.map( 
                                    (x,idx) => <MenuItem key={x.classFullName} value={x.className}>{x.className}</MenuItem> )
                                  }
                                </TextField>
                              )}
                            />
                          </Box> 
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                            <Controller name='requestBuildPostObjectMethod' control={control}
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="requestBuildPostObjectMethod"
                                  label={t('Method: build post object')} inputProps={ {readOnly: false}}>
                                  {currentLibraryClass.externalLibraryClassMethods.map( 
                                    (x,idx) => <MenuItem key={x.methodName} value={x.methodName}>{x.methodName}</MenuItem> )
                                  }
                                </TextField>
                              )}
                            />
                            <Controller name='requestBuildResponseObjectMethod' control={control}
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="requestBuildResponseObjectMethod"
                                  label={t('Method: build response object')} inputProps={ {readOnly: false}}>
                                  {currentLibraryClass.externalLibraryClassMethods.map( 
                                        (x,idx) => <MenuItem key={x.methodName} value={x.methodName}>{x.methodName}</MenuItem> )
                                      }
                                </TextField>
                              )}
                            />
                          </Box>*/}
                          
                      </Stack>     
                                         
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} component={Paper} >
                    <Stack flexDirection='column' sx={{ width: '100%' }} >
                      <Box sx={{ mt: 1, width: '100%' }} >                              
                        <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                            label={t('Response in ftp server ?')}
                            control={
                            <Controller
                                name='responseInFtpServer'
                                control={control}
                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                            />} />                                                            
                      </Box>   
                      <Box sx={{ mt: 1, width: '100%' }} >                            
                        <TextField sx={{width:'calc(50% - 8px)'}} id="ftpServerAddress" label={t('Ftp server')} {...register('ftpServerAddress')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                        <Controller
                          render={({ field }) => {
                            return (
                              <NumberFormat              
                                disabled={false}
                                label={t('Port')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                allowEmptyFormatting={false}
                                control={control}    
                                thousandSeparator={true}
                                decimalScale={2}
                                {...field}
                                customInput={TextFieldRight}                            
                              />
                            );
                          }}
                          name='ftpServerPort'
                          control={control}
                        />         
                        <Controller name='ftpServerSecurity' control={control}
                          render={ ({field: {onChange, value}}) => (
                              <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="ftpServerSecurity"
                                label={t('Security')} inputProps={ {readOnly: false}}>
                                {enumItems && enumItems.filter( e => e.enumerationCode === Enum_TLS_SECURITY).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                              </TextField>
                            )}
                        />                                                  
                      </Box> 
                      <Box sx={{ mt: 1, width: '100%' }} >                            
                        <TextField sx={{width:'calc(50% - 8px)'}} id="ftpServerLogin" label={t('User Name')} {...register('ftpServerLogin')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                        <TextField sx={{width:'calc(50% - 8px)'}} id="ftpServerPassword" label={t('Password')} {...register('ftpServerPassword')} 
                                  type="password" inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                                           
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >                            
                        <TextField sx={{width:'calc(50% - 8px)'}} id="ftpServerFolder" label={t('Ftp folder')} {...register('ftpServerFolder')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                        <Controller name='ftpServerProtocol' control={control}
                          render={ ({field: {onChange, value}}) => (
                              <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="ftpServerProtocol"
                                label={t('Protocol')} inputProps={ {readOnly: false}}>
                                {enumItems && enumItems.filter( e => e.enumerationCode === Enum_FTP_PROTOCOL).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                              </TextField>
                            )}
                        />   
                        <Controller name='ftpServerRequestMode' control={control}
                            render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="ftpServerRequestMode"
                                  label={t('Mode')} inputProps={ {readOnly: false}}>
                                  {enumItems && enumItems.filter( e => e.enumerationCode === Enum_FTP_MODE).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                                </TextField>
                              )}
                          />                                                                                
                      </Box>                      
                      <Box sx={{ mt: 1, width: '100%' }} >
                          <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                              label={t('Move file after treatment ?')}
                              control={
                              <Controller
                                  name='moveFtpFileAfterTreatment'
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />                                                                                      
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >
                        <TextField sx={{width:'calc(100% - 8px)'}} id="ftpArchivePath" label={t('Archive folder')} {...register('ftpArchivePath')}
                              inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />                                                            
                      </Box>
                          {/* <Box sx={{ mt: 1, width: '100%' }} >                                
                            <Controller name='responseCheckBuildPostObjectMethod' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="responseCheckBuildPostObjectMethod"
                                      label={t('Method: build response check post object')} inputProps={ {readOnly: false}}>
                                      {currentLibraryClass.externalLibraryClassMethods.map( 
                                        (x,idx) => <MenuItem key={x.methodName} value={x.methodName}>{x.methodName}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                            />
                            <Controller name='responseCheckBuildResponseObjectMethod' control={control}
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="responseCheckBuildResponseObjectMethod"
                                  label={t('Method: build response check response object')} inputProps={ {readOnly: false}}>
                                  {currentLibraryClass.externalLibraryClassMethods.map( 
                                    (x,idx) => <MenuItem key={x.methodName} value={x.methodName}>{x.methodName}</MenuItem> )
                                  }
                                </TextField>
                              )}
                            />
                          </Box> */}
                          
                          {/* <Box sx={{ mt: 1, width: '100%' }} >                                
                            <Controller name='responseFolderCheckObjectMethod' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="dbmsType"
                                      label={t('Method: response folder check')} inputProps={ {readOnly: false}}>
                                      {currentLibraryClass.externalLibraryClassMethods.map( 
                                        (x,idx) => <MenuItem key={x.methodName} value={x.methodName}>{x.methodName}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                            />
                            
                          </Box> */}
                          
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3} component={Paper} >
                    <Stack flexDirection='column'>
                        <Box sx={{ pt: 5, width: '100%' }} >
                            <ArrayFieldTableEx<IRequestType,IRequestTypeBusinessApp,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headRequestTypeBusinessAppCells} rowsPathName='requestTypeBusinessApps' 
                                title={t('Business applications')} rowActionIcon={requestTypeBusinessAppRowActionIcon}  
                                onRowSelected={handleBusinessAppSelected}
                                                    
                                refAppend={refAppendRequestTypeBusinessApps as MutableRefObject<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateRequestTypeBusinessApp as MutableRefObject<(index: number,value: Partial<FieldArray<IRequestType>>) => void>}
                                refRemove={refRemoveRequestTypeBusinessApp as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddBusinessApplications ,icon: AddCircleIcon,  },
                                
                                ]}
                            />
                            { openBusinessApplicationFilter && <FormDialog open={openBusinessApplicationFilter} maxWidth='sm'
                                okText={t('OK')} cancelText='' title={t('Business application')} onCancel={()=> {}} 
                                onClose={()=> {setOpenBusinessApplicationFilter(false);}} onOk={handleOkFilterBusinessApplication}  >
                                    <BasicTextFilterForm<IBusinessApplication> {...basicFilterBusinessApplication } />
                            </FormDialog> }                            
                        </Box>

                    </Stack> 
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} component={Paper} >
                    <Stack flexDirection='column'>
                      {
                        getValues().requestTypeBusinessApps.map( (businessApp, index) => {
                          return (index === requestTypeBusinessAppIndex) && (
                            <Fragment key={`key-${businessApp.id}`}>
                              <Box sx={{ mt: 1, width: '100%' }} > 
                                {`${t(('Business App'))} - ${(requestTypeBusinessAppIndex+1)} - ${businessApp.businessApplicationName}`}                             
                              </Box> 
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <Controller 
                                  key={`businessApps-${businessApp.id}-isItemReferenceReturn-x`}                                    
                                  name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.isItemReferenceReturn`}
                                  control={control}
                                  render= {({ field: {value, onChange,...props} }) => <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                      label={t('Is item reference return ?')}
                                        control={<Checkbox {...props} checked={value} onChange={onChange} />
                                        } /> }                                      
                                />                                                                  
                              </Box>       
                              <Box sx={{ mt: 1, width: '100%' }} >                                
                                <Controller
                                  key={`businessApps-${businessApp.id}-returnItemReferenceApiUrl-x`}
                                  render={({ field }) => <TextField label={t('Return item reference api url')} {...field}  
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}
                                     />} 
                                    name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.returnItemReferenceApiUrl`}                      
                                  control={control}
                                />     
                                                              
                              </Box>                         
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <Controller
                                  key={`businessApps-${businessApp.id}-masterEntityQuery-x-${businessApp.masterEntityQueryName}`}
                                  render={({ field }) => <TextField label={t('Query: Master')} {...field} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickSearchBusinessApplicationQuery('masterEntity')}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    )
                                  }}  />} 
                                  name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.masterEntityQueryName`}                      
                                  control={control}
                                />
                                { openBusinessApplicationQueryFilter && <FormDialog open={openBusinessApplicationQueryFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Query filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenBusinessApplicationQueryFilter(false);}} onOk={()=> {setOpenBusinessApplicationQueryFilter(false);}}  >
                                    <BasicTextFilterForm<IBusinessApplicationQuery> {...basicFilterBusinessApplicationQuery} />
                                  </FormDialog>  } 
                                  </Box>                         
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <Controller
                                    key={`businessApps-${businessApp.id}-itemEntityQuery-x-${businessApp.itemEntityQueryName}`}
                                    render={({ field }) => <TextField label={t('Query: Item')} {...field} 
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`}}
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={handleClickSearchBusinessApplicationQuery('itemEntity')}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />}
                                    name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.itemEntityQueryName`}                      
                                    control={control}
                                  />
                              </Box>
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <Controller
                                    key={`businessApps-${businessApp.id}-itemEntityQuery-x-${businessApp.intermediaryEntityQueryName}`}
                                    render={({ field }) => <TextField label={t('Query: Intermediary')} {...field} 
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`}}
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={handleClickSearchBusinessApplicationQuery('intermediary')}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />}
                                    name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.intermediaryEntityQueryName`}                      
                                    control={control}
                                  />
                              </Box>
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <Controller
                                    key={`businessApps-${businessApp.id}-itemEntityQuery-x-${businessApp.officeEntityQueryName}`}
                                    render={({ field }) => <TextField label={t('Query: Office')} {...field} 
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`}}
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={handleClickSearchBusinessApplicationQuery('office')}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />}
                                    name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.officeEntityQueryName`}                      
                                    control={control}
                                  />
                              </Box>
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <Controller
                                    key={`businessApps-${businessApp.id}-itemEntityQuery-x-${businessApp.userEntityQueryName}`}
                                    render={({ field }) => <TextField label={t('Query: User')} {...field} 
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`}}
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={handleClickSearchBusinessApplicationQuery('user')}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />}
                                    name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.userEntityQueryName`}                      
                                    control={control}
                                  />
                              </Box>
                            </Fragment>
                          ) 
                        } )
                      }
                    </Stack> 
                  </Grid>
                  <Grid item xs={12} md={6} lg={5} component={Paper} >
                    <Stack flexDirection='column'>
                      {
                        getValues().requestTypeBusinessApps.map( (businessApp, index) => {
                          return (index === requestTypeBusinessAppIndex) && (
                            <Fragment key={`key-${businessApp.id}-${businessApp.businessApplicationName}-${index}`}>
                              <Box sx={{ mt: 1, width: '100%' }} > 
                                {/* {`${t(('Business App'))} - ${(requestTypeBusinessAppIndex+1)} - ${businessApp.businessApplicationName}`}                              */}
                              </Box> 
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <ArrayFieldTableEx<IRequestType,IRequestTypeBusinessAppCodification,'id'> 
                                    
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headRequestTypeBusinessAppCodificationCells} 
                                    rowsPathName={`requestTypeBusinessApps.${index}.requestTypeBusinessAppCodifications`} 
                                    title={`${t('Codifications')} - ${businessApp.businessApplicationName}`} rowActionIcon={requestTypeBusinessAppCodificationRowActionIcon}  
                                    onRowSelected={handleBusinessAppCodificationSelected}
                                                        
                                    refAppend={refAppendRequestTypeBusinessAppCodifications as MutableRefObject<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateRequestTypeBusinessAppCodification as MutableRefObject<(index: number,value: Partial<FieldArray<IRequestType>>) => void>}
                                    refRemove={refRemoveRequestTypeBusinessAppCodification as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[ ]}
                                />          
                                {
                                  businessApp.requestTypeBusinessAppCodifications.map( (businessAppCodification,idx) => {
                                    return (idx === requestTypeBusinessAppCodificationIndex && openBusinessApplicationCodificationMapping) &&  
                                      <FormDialog open={openBusinessApplicationCodificationMapping} maxWidth='sm' 
                                        okText={t('OK')} cancelText='' title={t('Codification mapping')} onCancel={()=> {}} 
                                        onClose={()=> {setOpenBusinessApplicationCodificationMapping(false);}} 
                                        onOk={()=> {setOpenBusinessApplicationCodificationMapping(false);}} 
                                        key={`businessAppCodification-${businessAppCodification.requestClassEnumerationCode} - ${idx}`} >
                                          <Fragment key={`key-${businessApp.id}-${businessApp.businessApplicationName}-${index}`}>
                                            <Box sx={{ mt: 1, width: '100%' }} > 
                                              <Controller 
                                                  key={`businessApps-${businessApp.id}-isItemReferenceReturn-x-${businessAppCodification.useCodificationMapping}`}                                    
                                                  name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.requestTypeBusinessAppCodifications.${idx}.useCodificationMapping`}
                                                  control={control}
                                                  render= {({ field: {value, onChange,...props} }) => <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                                      label={t('Use codification mapping ?')}
                                                        control={<Checkbox {...props} checked={value} onChange={onChange} />
                                                        } /> }                                      
                                                />
                                            </Box>
                                            <Box sx={{ mt: 2, width: '100%' }} >
                                              <Controller
                                                  key={`businessApps-${businessApp.id}-itemEntityQuery-x-${businessAppCodification.businessApplicationQueryName}`}
                                                  render={({ field }) => <TextField label={t('Query: Item')} {...field} 
                                                        inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                        sx={{width:`calc(100% - 8px)`}}
                                                        InputProps={{
                                                          readOnly: true,
                                                          endAdornment: (
                                                            <InputAdornment position="end">                                            
                                                              <IconButton color="primary" onClick={handleClickSearchBusinessApplicationQuery('codification')}>
                                                                <ArrowDropDownCircleIcon />
                                                              </IconButton>                                                                                               
                                                          </InputAdornment>
                                                        )
                                                      }} />}
                                                  name={`requestTypeBusinessApps.${requestTypeBusinessAppIndex}.requestTypeBusinessAppCodifications.${idx}.businessApplicationQueryName`}                      
                                                  control={control}
                                                />
                                            </Box>
                                            <Box sx={{ mt: 1, width: '100%' }}>
                                              <ArrayFieldTableEx<IRequestType,IRequestTypeBusinessAppCodificationMapping,'id'> 
                                      
                                              mainObject={getValues()} fieldKey='id' 
                                              headCells={headBusinessApplicationCodificationMappingCells} 
                                              rowsPathName={`requestTypeBusinessApps.${index}.requestTypeBusinessAppCodifications.${idx}.requestTypeBusinessAppCodificationMappings`} 
                                              title={`${t('Codifications')} - ${businessAppCodification.requestClassEnumerationCode}`} 
                                              //rowActionIcon={requestTypeBusinessAppCodificationRowActionIcon}  
                                              //onRowSelected={handleBusinessAppCodificationSelected}
                                                                  
                                              refAppend={refAppendRequestTypeBusinessAppCodificationMappings as MutableRefObject<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>}
                                              refUpdate={refUpdateRequestTypeBusinessAppCodificationMapping as MutableRefObject<(index: number,value: Partial<FieldArray<IRequestType>>) => void>}
                                              refRemove={refRemoveRequestTypeBusinessAppCodificationMapping as MutableRefObject<(index: number) => void>}

                                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                              //displayMore={undefined}
                                              toolbarActions={[
                                                {toolTip: `${t('Add')}...`, onClickIcon: handleAddBusinessApplicationCodificationMappings ,icon: AddCircleIcon,  },
                                              ]}
                                                />
                                            </Box> 
                                          </Fragment>
                                      </FormDialog>
                                  } )
                                }                                                    
                              </Box>  
                            </Fragment>
                          ) 
                        } )
                      }
                      
                    </Stack> 
                  </Grid>
                    {/* <Grid item xs={12} md={3} component={Paper} >
                      <Stack flexDirection='column'>
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<IRequestType,IRequestTypeEx,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headRequestTypeExCells} rowsPathName='requestTypeExs' 
                                  title={t('Roles')} rowActionIcon={requestTypeExRowActionIcon}  
                                  //onRowSelected={handleRoleEntitySelected}
                                                      
                                  refAppend={refAppendRequestTypeExs as MutableRefObject<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateRequestTypeEx as MutableRefObject<(index: number,value: Partial<FieldArray<IRequestType>>) => void>}
                                  refRemove={refRemoveRequestTypeEx as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={[
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddExtensionTypes ,icon: AddCircleIcon,  },
                                  
                                  ]}
                              />
                              { openExtensionTypeFilter && <FormDialog open={openExtensionTypeFilter} maxWidth='sm'
                                  okText={t('OK')} cancelText='' title={t('Business application')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtensionTypeFilter(false);}} onOk={handleOkFilterExtensionType}  >
                                      <BasicTextFilterForm<IBusinessApplication> {...basicFilterBusinessApplication } />
                              </FormDialog> }
                          </Box>
                      </Stack> 
                    </Grid>
                    <Grid item xs={12} md={3} component={Paper} >
                      <Stack flexDirection='column'>
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<IRequestType,IRequestTypeItemEx,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headRequestTypeExCells} rowsPathName='requestTypeBusinessApps' 
                                  title={t('Roles')} rowActionIcon={requestTypeItemExRowActionIcon}  
                                  //onRowSelected={handleRoleEntitySelected}
                                                      
                                  refAppend={refAppendRequestTypeItemExs as MutableRefObject<(value: Partial<FieldArray<IRequestType>> | Partial<FieldArray<IRequestType>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateRequestTypeItemEx as MutableRefObject<(index: number,value: Partial<FieldArray<IRequestType>>) => void>}
                                  refRemove={refRemoveRequestTypeItemEx as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={[
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddExtensionTypes ,icon: AddCircleIcon,  },
                                  
                                  ]}
                              />
                              
                          </Box>
                      </Stack> 
                    </Grid> */}
              </Grid>
            </Box>
        </FormProvider> 
  )
}

