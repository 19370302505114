

export interface IRequestData {
    id: number,
    requestTypeBusinessAppId: number,

    
    reference: string,
    purpose: string,
    status: string,
    statusDate: Date,

    isRequestSended: boolean,
    isRequestSendedOk: boolean,
    requestSendCount: number,
    requestSendedDate: Date,
    lastRequestSendedStatus: string,

    isResponseChecked: boolean,
    isResponseCheckedOk: boolean,
    responseCheckCount: number,
    responseCheckDate: Date,
    lastResponseCheckedStatus: string,
    isResponseArrived: boolean,

    responseArrivedDate: Date,

    contractNumber: string,
    coreKey: string,
    intermediaryCode: string,
    intermediaryName: string,
    officeName: string,
    effectiveDate: Date,
    expirationDate: Date,
    
    productName: string,
    customerName: string,

    requestTypeId: number,
    businessApplicationId: number,

    requestTypeBusinessAppName: string,

    
    
    requestDataExs: IRequestDataEx[],
    requestDataItems: IRequestDataItem[],
  }

  export interface IRequestDataEx {    
    id: number,
    requestTypeExDescription: string,

    textValue: string,
  }

   export interface IRequestDataItem {
    id: number,

    masterKey: string, // 
    coreKey: string, 
    itemIdentification: string,
    itemInformation: string,

    isResponseArrived: false,
    reference: string,

    isReferenceUpdated: boolean,

    itemDate: Date,    

    insuredName: string,
    effectiveDate: Date,
    expirationDate: Date,

    requestDataItemExs: IRequestDataItemEx[]
  }
  
  export interface IRequestDataItemEx {
    id: number,
  }

  export interface IRequestDataCoreMaster {
    id: number,
    contractNumber: string,
    coreKey: string,
    intermediaryCode: string,
    intermediaryName: string,
    officeName: string,
    customerName: string,
    productName: string, 

    effectiveDate: Date,
    expirationDate: Date,
  }

  export interface IRequestDataCoreItem {
    id: number,
    contractNumber: string,
    coreKey: string,
    customerName: string,

    effectiveDate: Date,
    expirationDate: Date,
  }


  export const defaultRequestData : IRequestData = {
    id: 0,
    requestTypeBusinessAppId: 0,

    reference: '',
    purpose: '',
    status: '95',
    statusDate: new Date(),

    isRequestSended: false,
    isRequestSendedOk: false,
    requestSendCount: 0,
    requestSendedDate: new Date(),
    lastRequestSendedStatus: '',
    

    isResponseChecked: false,
    isResponseCheckedOk: false,
    responseCheckCount: 0,
    lastResponseCheckedStatus: '',
    responseCheckDate: new Date(),

    isResponseArrived: false,
    responseArrivedDate: new Date(),

    contractNumber: '',
    coreKey: '',
    intermediaryCode: '',
    intermediaryName: '',
    officeName: '',
    effectiveDate: new Date(),
    expirationDate:new Date(),
    productName: '',
    customerName: '',

    requestTypeId: 0,
    businessApplicationId: 0,

    requestTypeBusinessAppName: '',

    requestDataExs: [],
    requestDataItems: [],
  }

  export interface IRequestDataSearch {
    requestTypeName: string,
    businessApplicationName: string,
    contractNumber: string,
    coreKey: string,    
    customerName: string,
    reference: string 
  }


  export interface IRequestDataCoreMasterSearch {
    coreKey: string,
    contractNumber: string,
    customerName: string,
    productName: string,  
  }


  export interface IDashboardCountItem {
    label: string,
    count: number
  }
  
  export interface IDashboardRequestData {
    requestDatasMonth: IDashboardCountItem[],
    requestDataItemsMonth: IDashboardCountItem[]
  }