import React from 'react';
import './App.css';

import CssBaseline from '@mui/material/CssBaseline';
import Box  from '@mui/material/Box';
import Container from '@mui/material/Container/Container';

import {  useRecoilValue, useRecoilState } from 'recoil';

import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

import { getUnixTime } from 'date-fns';



import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { SystemMenu } from 'components/SystemMenu';
import { AppRoute } from 'components/Route';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { EntityActionDrawer } from 'components/EntityActionDrawer';

import { currentUserSessionAtom, isActionDrawerOpenAtom, isSearchBoxShowAtom } from 'library/store';
import { FilterBox } from 'components/FilterBox';
import { FilterFormDialog } from 'components/FilterFormDialog';
import { RequestNotification } from 'components/RequestNotification';
import { SessionTimeout } from 'components/SessionTimeout';
import { displayOnDesktop } from 'themes/commonStyles';

const getLocal = (lg : string) : Locale => {
  if(lg.includes('fr'))
    return frLocale;
  else if(lg.includes('en'))
    return enLocale;

  return frLocale;
}

function App() {

  const {isAuthenticated, language } = useRecoilValue(currentUserSessionAtom);
  const [isActionDrawerOpen, setIsActionDrawerOpen] = useRecoilState(isActionDrawerOpenAtom);
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);  
  

  const boxProps = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 100,
    overflowY: isAuthenticated?'scroll':'hidden',
    overflowX: 'hidden' 
  }

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocal(language)}>
        <CssBaseline />
        <RequestNotification />
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          { isAuthenticated && <Box>
            <Header />          
          </Box> }
          { isAuthenticated && <Box>
            <SystemMenu />          
          </Box> }
          {isSearchBoxShow && <FilterFormDialog /> } 
          
          <Box
            sx={ {...boxProps} }
          >
            <Container maxWidth="xl" disableGutters={true} sx={{ mb: 1 }}>
              <AppRoute />
              { isActionDrawerOpen && <EntityActionDrawer /> }
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                MobileFooter
              </Box>
            </Container>
          </Box>

          { isAuthenticated && <Box sx={displayOnDesktop}>
            <Footer />
          </Box> }
          { isAuthenticated && <SessionTimeout {...{intialUnixTimeStamp: getUnixTime( (new Date())) }} />}
        </Box>
      </LocalizationProvider>
    </React.Fragment>
  );
}

export default App;
