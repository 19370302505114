

export interface ICustomer {
    id: number,
    fullName: string,
    
    contracts: IContract[]
  }

  export interface IContract {
    id: number,

    customerId: number,
    description: string,
    isActive: boolean,

    instanceId: string,
    token: string,
    status: string,
    statusDate: Date,
    identityToken: string,
    whatsAppNumber: string,
    login: string,

    webhookUrl: string,

    password: string,
    passwordMD5: string,
    isPasswordChanged: boolean,

    customerName: string,

    contractInnerStatus: string,
    contractInnerStatusDescription: string,

    hookDatas: any[],    

    profilePicture: string,
  }


 export const defaultCustomer : ICustomer = {
    id: 0,
    fullName: '',   

    contracts: []
  }


  export const defaultContract : IContract = {
    id: 0,
    customerId: 0,
    description: '',
    isActive: true,

    instanceId: '',
    token: '',
    status: '',
    statusDate: new Date(),
    identityToken: '',
    whatsAppNumber: '',
    login: '',
    webhookUrl: '',

    password: '',
    passwordMD5: '',
    isPasswordChanged: false,

    customerName: '',

    contractInnerStatus: '',
    contractInnerStatusDescription: '',

    hookDatas: [],    

    profilePicture: ''
  }


  export interface IPhoneInformation {
    phoneNumber: string,
    contactName: string,
    profilePicture: string,
  }


  export interface ICustomerSearch {
  
    fullName: string 
  }
