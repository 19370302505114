

export interface IRequestType {
    id: number,
    name: string,
    description: string,
    requestDataUrl: string,
    checkResponseAfterRequest: boolean,
    requestDataItemStatusUpdateUrl: string,
    externalLibraryId: number,
    requestTypeClassName: string,
    requestBuildPostObjectMethod: string,
    requestBuildResponseObjectMethod: string,
    hasResponseCheckUrl: boolean,
    responseCheckUrl: string,
    responseCheckBuildPostObjectMethod: string,
    responseCheckBuildResponseObjectMethod: string,
    
    
    responseFolderCheckObjectMethod: string,
        
    hasRequestDataResponseDocument: boolean,
    hasRequestDataItemResponseDocument: boolean,

    requestClass: string,
    code: string,
    
    responseInDirectory: boolean,
    responseDirectoryPath: string,
    moveDirectoryFileAfterTreatment: boolean,
    archiveDirectoryPath: string,

    responseInFtpServer: boolean,

    ftpServerAddress: string,
    ftpServerPort: number,
    ftpServerLogin: string,
    ftpServerPassword: string,
    ftpServerFolder: string,
    ftpServerProtocol: string,
    ftpServerRequestMode: string,
    ftpServerSecurity: string,

    moveFtpFileAfterTreatment: boolean,
    ftpArchivePath: string,

    requestTypeExs: IRequestTypeEx[],
    requestTypeItemExs: IRequestTypeItemEx[],

    requestTypeBusinessApps: IRequestTypeBusinessApp[]
  }


  export interface IRequestTypeBusinessApp {
    id: number,
    requestTypeId: number, 
    businessApplicationId: number,

    isItemReferenceReturn: boolean,
    returnItemReferenceApiUrl : string,

    masterEntityQueryId: number,
    itemEntityQueryId: number,
    intermediaryEntityQueryId: number,
    officeEntityQueryId: number,
    userEntityQueryId: number,

    businessApplicationName: string, 
    businessApplicationDescription: string,     
    dbmsType: string,
    isBusinessApplicationDirectConnection: boolean,
    businessApplicationLocalServerApiUrl: string,

    requestTypeName: string, 
    requestTypeDescription: string, 

    requestTypeBusinessAppCodifications: IRequestTypeBusinessAppCodification[],
    
    requestTypeBusinessAppName: string, 

    masterEntityQueryName: string,
    itemEntityQueryName: string,
    intermediaryEntityQueryName: string,
    officeEntityQueryName: string,
    userEntityQueryName: string,
  }


  export interface IRequestTypeBusinessAppCodification {
    id: number,
    requestTypeBusinessAppId: number,
    businessApplicationQueryId: number,
    requestClassEnumerationCode: string,
    defaultMappingCode: string,

    useCodificationMapping: boolean,

    businessApplicationQueryName: string,

    requestTypeBusinessAppCodificationMappings: IRequestTypeBusinessAppCodificationMapping[]
  }

  export interface IRequestTypeBusinessAppCodificationMapping {
    id: number,
    requestTypeBusinessAppCodificationId: number,
    codeFromBusiness: string,
    codeForRequest: string,

    requestClassEnumerationCode: string,
  }

  export interface IRequestTypeEx {
    id: number,
    requestTypeId: number,
    extensionTypeId: number,
    description: string,

    extensionTypeName: string,
    extensionTypeDataType: string,
  }

  export interface IRequestTypeItemEx {
    id: number,
    requestTypeId: number,
    extensionTypeId: number,
    description: string,

    extensionTypeName: string,
    extensionTypeDataType: string,
  }


  export const defaultRequestType : IRequestType = {
    id: 0,
    name: '',
    description: '',
    requestDataUrl: '',
    checkResponseAfterRequest: false,
    requestDataItemStatusUpdateUrl: '',
    externalLibraryId: 0,
    requestTypeClassName: '',
    requestBuildPostObjectMethod: '',
    requestBuildResponseObjectMethod: '',
    hasResponseCheckUrl: false,
    responseCheckUrl: '',
    responseCheckBuildPostObjectMethod: '',
    responseCheckBuildResponseObjectMethod: '',
    
    
    responseFolderCheckObjectMethod: '',
    
    hasRequestDataResponseDocument: false,
    hasRequestDataItemResponseDocument: false,

    requestClass: '',
    code: '',
    
    responseInDirectory: false,
    responseDirectoryPath: '',
    moveDirectoryFileAfterTreatment: true,
    archiveDirectoryPath: '',

    responseInFtpServer: false,

    ftpServerAddress: '',
    ftpServerPort: 21,
    ftpServerLogin: '',
    ftpServerPassword: '',
    ftpServerFolder: '',
    ftpServerProtocol: '',
    ftpServerRequestMode: '',
    ftpServerSecurity: '',

    moveFtpFileAfterTreatment: true,
    ftpArchivePath: '',

    requestTypeExs: [],
    requestTypeItemExs: [],

    requestTypeBusinessApps: []
  }

  export interface IRequestTypeSearch {
    
    name: string,
    description: string
  }