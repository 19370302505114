import {useState} from 'react';
import useAxios from 'library/axios'; 
import { useTranslation } from 'react-i18next';
import IEnumeration, {IEnumerationItem, IEnumerationSearch} from '../models/Enumeration';
import { HeadCell } from 'components/ui/EnhancedTable';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';

const _ = () => {

    const axios = useAxios();    

    const createEnumeration = async (enumeration: IEnumeration)  =>       
      await (await axios.post('/api/configuration/enumeration/create', enumeration)).data;

    const updateEnumeration = async (enumeration: IEnumeration)  =>       
      await (await axios.post('/api/configuration/enumeration/update', enumeration)).data;

    const getEnumeration = async (enumerationId  : number )  => {        
        const {data} = (await axios.get(`/api/configuration/enumeration/get-enumeration/${enumerationId}`));
        return await data;
      }

    const getEnumerations = async (criteria  : IEnumerationSearch)  => {
        const {code, name, description} = criteria;
        
        const {data} = (await axios.get(`api/configuration/enumeration/get-enumerations?code=${code}&name=${name}&description=${description}`));
        return await data;
      }

    const getEnumerationItemsByEnumerationCodes = async (enumerationCodes: string[]) : Promise<IEnumerationItem[]>  => {
      const codes = enumerationCodes.join(':-:');
      const {data} = (await axios.get(`api/configuration/enumeration/get-enumerationItems-by-enumerationCodes/${codes}`));
      return await data;
    }

    const getEnumerationItemsByEnumerationCode = async (enumerationCode: string)  => {
        const {data} = (await axios.get(`api/configuration/enumeration/get-enumerationItems-by-enumerationCode/${enumerationCode}`));
        return await data;
      }
    
    const getLanguagesList = async ()  => { //api/{module:controllerNamespace}/[controller]
        const {data} = (await axios.get(`api/configuration/enumeration/get-languages/`));
        return await data;
      }

      const getEnumerationItemsLanguage = async ()  => {
        const {data} = (await axios.get(`api/configuration/enumeration/get-enumerationItems-language/`));
        return await data;
      }  

      const getAsOptions = (enumItems: IEnumerationItem[] | undefined, enumerationCode: string): {value: string, name: string}[] => 
        (enumItems||[]).filter( itm => itm.enumerationCode === enumerationCode).map( ({code, name}) => ({value:code, name}))
      

      
    return {    
        createEnumeration,    
        updateEnumeration,  
        getEnumeration,

        getEnumerations,     
        getEnumerationItemsByEnumerationCodes, 
        getEnumerationItemsByEnumerationCode,
        getEnumerationItemsLanguage,
        
        getLanguagesList,
        getAsOptions,
      
    } 
}

export default _;


export const useBasicFilterEnumeration = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IEnumeration) => void  ) => {

  const { getEnumerations } = _();

  const { t, i18n } = useTranslation();   
    

  const [headEnumerationCells, setHeadEnumerationCells]  = useState<HeadCell<IEnumeration>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },

  ]); 

  const [filterElements, ] = useState([       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},       
      ]);

  const [filteredEnumerations, ] = useState<IEnumeration[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IEnumeration[]> => {
            
    const code = filterElements.find( elt => elt.name === 'code')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getEnumerations( {code, name, description} );
    
    return arr;
  }

  const objKey: keyof IEnumeration = 'id';

  return {
    title: t('Enumeration'), headCells: headEnumerationCells, objKey,
    filterElements, rows: filteredEnumerations, 
    onFilterButtonClick, onRowDoubleClick
  }
}