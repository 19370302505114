import React, {useState,useEffect, MouseEvent} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation  } from 'react-i18next';

import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

import { IMenuItem } from './models/Menu';
import useMenu from 'components/services/Menu';
import { Box, ListItemIcon } from '@mui/material';
import { isFalsy } from 'utility-types';


import { IoSearchCircleSharp } from 'react-icons/io5';

import {currentUserSessionAtom, currentUserSessionSetAuthentication} from 'library/store';
import { useRecoilValue } from 'recoil';
import  {iconFromName} from './ui/DynamicIcon';
import { pink } from '@mui/material/colors';





export const TopMenu = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); 


  const {applicationMenus : menus, setApplicationMenus: setMenus} = useMenu();

  const currentUserSession = useRecoilValue(currentUserSessionAtom);

  const [menuClickCount, setMenuClickCount] = useState<number>(-1);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const menuClick = (event: MouseEvent<HTMLButtonElement>, name: string) : void => {    
    event.preventDefault();
    
    const arr = [...menus];
    const menu = arr.find( m => m.name === name ); 
    if(! menu) return;

    menu.isOpen = !menu.isOpen;
    setMenus([...arr]);
    // setMenuClickCount(menuClickCount - 1);
    // const eltId = (event.target as HTMLButtonElement).id;
    
    // const url = eltId.replace('btn','');
    
    // navigate(`${url}/${menuClickCount}`);    
  }


  const choices = [
    { id: 1, text: t('Contract'), routeToGo: '/contract', iconName: 'FaFileContract' },
    { id: 2, text: t('Message'), routeToGo: '/message', iconName: 'BiMessageSquareEdit' },
    { id: 3, text: t('Numbering'), routeToGo: '/numbering', iconName: 'AiOutlineFieldNumber' },
    { id: 4, text: t('Api'), routeToGo: '/api', iconName: 'MdApi' },
    //{ id: 3, text: t('Report'), routeToGo: '/report', iconName: 'HiDocumentReport' },
    //{ id: 4, text: t('Job schedule'), routeToGo: '/job', iconName: 'AiOutlineSchedule' },
    { id: 5, text: t('More'), routeToGo: '/more', iconName: 'RiMoreFill' },
  ];

  

  const closeMenu = (name: string) : void => {
    
    const arr = [...menus];
    const menu = arr.find( m => m.name === name );  
    if(! menu) return;

    setMenuClickCount(menuClickCount - 1);
    menu.isOpen = false;
    setMenus([...arr]);
  }

  const menuItemClick = (event: MouseEvent<HTMLButtonElement>, name: string, routeToGo: string) : void => {
    event.preventDefault();

    closeMenu(name);
    navigate(`${routeToGo}/${menuClickCount}`);
  }

  const clickAway = (event: MouseEvent<HTMLButtonElement>, name: string) : void => {
    event.preventDefault();

    closeMenu(name);
  }

  const menuItemDisabled = (entityName: string): boolean =>  {
    //return false;
    
    return !currentUserSession.roleEntities.some(re => re.entityName === entityName);
}

const menuDisabled = (menuItems: IMenuItem[]): boolean =>  {
  return false;
  //return !menuItems.some(itm => currentUserSession.roleEntities.some(re => re.entityName === itm.entityName));    
}

useEffect( () => {
  
  
},[t, i18n]);

  return (
    
    <Paper
    sx={{
      borderRadius: 20,
      ml: 15,
    }}
      elevation={3}
    >
    <Stack
      sx={{
        borderRadius: 20,
        pl: 2,
      }}
      divider={<Divider orientation="vertical" flexItem />}
    >
      {choices.map((choice) => {
        return (
          <Button key={choice.id} onClick={(event: any) => menuItemClick(event,'',choice.routeToGo)}
               sx={{ ml: 2, mr: 2, }}>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontWeight: 'bold',
              }}
            >
              {choice.text}
            </Typography>
            {choice.iconName && (
              <Box
                sx={{
                  ml: 1,
                  mt: 1,
                  mr: 1,
                }}
              >
                {iconFromName(choice.iconName)} 
              </Box>
            )}
          </Button>
        );
      })}
    </Stack>
  </Paper>
    
  )
}
