
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import {addDays} from 'date-fns';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import { IMessage, defaultMessage, MessageType } from 'features/production/models/Message';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, currentUserSessionAtom } from 'library/store';
//

import useNumberingService from './services/Numbering';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';
import { isFalsy } from 'utility-types';
import { typographyGroupBoxStyling } from 'themes/commonStyles';
import EnhancedTable, { HeadCell } from 'components/ui/EnhancedTable';

import RuleIcon from '@mui/icons-material/Rule';
import { arrayIncludes } from '@mui/x-date-pickers/internals/utils/utils';
import { ThreeCircles } from 'react-loader-spinner';


export interface INumbering {
  number: string,
  isValid: boolean
}

export const defaultNumbering : INumbering = {
  number: '',
  isValid: false
}



export const NumberingForm: FC<INumbering> = (props: INumbering = defaultNumbering) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer } = entityService();

  const {checkNumberExs} = useNumberingService();

  const [isChecking, setIsChecking] = useState<boolean>(false);

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
    
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  
  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<IMessage>({defaultValues:{...defaultMessage,validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchType = watch('type');
  const watchRecipientNumber = watch('recipientNumber');
  const watchFileName = watch('fileName');
  const watchBase64File = watch('base64File');

  const queryClient = useQueryClient();
  

  const [recipients, setRecipients] = useState<string[]>([]);

  const [numberings, setNumberings] = useState<INumbering[]>([]);


      function openFileDialog() {
        (document as any).getElementById("file-upload").click();
      }
    
  const [headNumberingCells, setHeadNumberingCells]  = useState<HeadCell<INumbering>[]>([      
    {id:'number', label : 'N°',  display: true, type: 'string', width: 50 },
    {id:'isValid', label : t('Valid ?'),  display: true, type: 'bool', width: 50 },
    
    //{id:'licensePlate', label : t('Description'),  display: true, type: 'string', width: 20 },
    //{id:'insuredFullName', label : t('Name'),  display: true, type: 'string', width: 80 },
  ]);

  const handleCheckNumberings = async (event: any) => {
          
    setIsChecking(true);
    const arr = await checkNumberExs( numberings.map(nb => nb.number)  );

    const nums = numberings.map( nb => {
      const n = arr.find(x => x.number == nb.number);
      return {...nb, isValid: isFalsy(n)? false : n.isValid }
      });

    setIsChecking(false);

    setNumberings(nums);
  }


      const setFile = (_event: any) => {
        let f = _event.target.files![0];
        var reader = new FileReader();
    
        reader.onload = function () {
    
            var binaryString = reader.result as string;
        
            const base64String = binaryString
                                        .replace('data:', '')
                                        .replace(/^.+,/, '');
    
            setValue("base64File", base64String);
            setValue("fileName", f.name);
          };
      
          reader.onerror = function () {
            console.log("File load failed");
          };
      
          reader.readAsDataURL(f);    
      };

      useEffect( () => {        
        setCurrentFormNameAtom(t('Numbers check'));
        setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
      }, []);

      useEffect( () => {        
        setRecipients([...watchRecipientNumber.split(',')].filter(n => n.trim() !== '') );
      }, [watchRecipientNumber]);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            // if(_id > 0)
            //   retrieveData('Message',_id, refetch);  
          }, [_id] );
    
    
        // useEffect( () => {
            
        // if(_data && _data.id > 0) {
        //     reset(_data);
        // }
        // }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {        
        _setId(0);  
        //reset({...defaultMessage, validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)  });         
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Message', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                      
                    <Grid item xs={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(80% - 8px)'}} id="messageBody" label={t('Add number by pressing ENTER')} 
                                {...register('currentRecipient')} multiline maxRows={1}
                                InputProps={{
                                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                                    const {currentRecipient, recipientNumber} = getValues();

                                    const tmp = numberings.map(nb => nb.number);

                                    const setNumber = new Set( currentRecipient.split('\n').filter(n => 
                                        n.trim() !== '' && !tmp.includes(n.trim()) ) );
                                    const currentRecipients = Array.from(setNumber);
                                    
                                    //const recipients = recipientNumber.split(',');
                                    
                                    if(event.key === 'Enter') {
                                      if(currentRecipients.length > 0) {
                                        setNumberings([...numberings, ...currentRecipients.map( x => ({number: x, isValid: false}) ) ]);
                                      }
                                        //setValue('recipientNumber', `${recipientNumber},${currentRecipients.join(',')}`.replace(/^,+/, '') ); 

                                      setValue('currentRecipient', '');                                     
                                    }
                                  },
                                }}                                
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              />   
                          </Box>
                          { isChecking && <Box sx={{ mt: 1, mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >
                            <ThreeCircles
                                    color='#e15b64'
                                    height={'30'}
                                    //width={100}
                                    //timeout={3000} //3 secs
                                />
                          </Box>}
                          <Box sx={{ mt: 1, width: '100%' }} key={getValues().recipientNumber}>     
                            <EnhancedTable<INumbering> rows={numberings} 
                              headCells={headNumberingCells} title={` ${numberings.length} ${t(('Recipient'))}(s) `}
                              objKey={'number'} 
                              stateSelected={undefined} 
                              onRowSelected={undefined} onRowDoubleClick={undefined} rowCheckedMode={undefined}
                              onRowCheckedSelectChange={undefined} order='asc' orderBy={'number'} 
                              toolbarActions={[
                                { toolTip: `${t('Verify')}...`, onClickIcon: handleCheckNumberings ,icon: RuleIcon,  },                      
                              ]}
                            />                            
                          </Box>                          
                        </Stack>                  
                    </Grid>
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

