
import { useState } from "react";

import {RefetchOptions, RefetchQueryFilters, QueryObserverResult } from "react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import { useSetRecoilState, useRecoilState } from "recoil"; 

import { IEntity, IEntityProperty, IFeatureDescription, IFeatureParameter } from "library/interface";

import useAxios from 'library/axios'; 
import { isActionDrawerOpenAtom, currentEntityNameForActionDrawerAtom, currentEntityIdForActionDrawerAtom, 
  currentUserSessionAtom, isSearchBoxShowAtom, currentBasicTextFilterPropsAtom } from "library/store";
import { BasicTextFilterProps, ITextFilterElement } from "components/ui/BasicTextFilterForm";

import { HeadCell, RowCheckedMode } from "components/ui/EnhancedTable";

import { isFalsy } from "utility-types";
// import { IFeatureDescription, IFeatureParameter } from "../utils/interface";


export interface ICustomerApi {
  baseUrl: string,
  
  name: string,
  description: string,

  method: string,
  routeName: string,

  body: string
}

const _ = () => {

    const axios = useAxios();   

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);

    const [isActionDrawerOpen, setIsActionDrawerOpen] = useRecoilState(isActionDrawerOpenAtom);
    const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
    
    const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

    const [, setActionEntityName] = useRecoilState(currentEntityNameForActionDrawerAtom);
    const [, setActionEntityId] = useRecoilState(currentEntityIdForActionDrawerAtom);
    
    
    const retrieveEntity = async (entityName: string, entityId: number)  =>  {
        const {data} = (await axios.get(`api/home/retrieve-entity?entityName=${entityName}&entityId=${entityId}`));
        return await data;
      }
  
      const retrieveData = async ( entityName: string, entityId: number,
          refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<unknown,unknown>>
      ) : Promise<boolean> => {
        if( (entityId || 0) <= 0) 
            return false;
        //   enqueueSnackbar( `${t('Invalid Id')} : ${_id}`, { variant: 'warning',
        //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      
        if(false) { //if(!canRetreive())
            alert('Can retrieve !!!');
            return false;
            //navigate(`/entity/Enumeration/${entityId}`);
        } else {
          await refetch();
          return true;
        }
      }

    const getFeaturesByEntityType = async ( entityName: string, featureSearch : { featureName: string, featureDescription: string}) : Promise<IFeatureDescription[]>  => {        
      const {featureName, featureDescription} = featureSearch;
      const {data} = (await axios.get(`/api/home/get-features-by-entity-type/?entityName=${entityName}&featureName=${featureName}&featureDescription=${featureDescription}`));
      return await data;
    } 

    const getFeaturesByEntity = async (entityName: string, entityId: number) : Promise<IFeatureDescription[]>  => {        
      const {data} = (await axios.get(`/api/home/get-features-by-entity/?entityName=${entityName}&entityId=${entityId}`));
      return await data;
    } 

    const getFeaturesByEntityForUser = async (entityName: string, entityId: number) : Promise<IFeatureDescription[]>  => {        
      const {data} = (await axios.get(`/api/home/get-features-by-entity-for-user/?entityName=${entityName}&entityId=${entityId}`));
      return await data;
    }

    const getParametersByFeature = async (entityName: string, featureName: string) : Promise<IFeatureParameter[]>  => {        
      const {data} = (await axios.get(`/api/home/get-parameters-by-feature/?entityName=${entityName}&featureName=${featureName}`));
      return await data;
    }

    const executeEntityFeature = async (entityAction: IFeatureDescription )  => 
      await (await axios.post('/api/home/execute-entity-feature', entityAction)).data;

    const getRootEntities = async (entitySearch: {name: string, description: string})  => {    
      const {name, description} = entitySearch;
      const {data} = (await axios.get(`/api/home/get-root-entities/?name=${name}&description=${description}`));
      return await data;
    }

    const getEntityProperties = async (entityName: string) : Promise<IEntityProperty[]>  => {          
      const {data} = (await axios.get(`/api/home/get-entity-by-properties/?entityName=${entityName}`));
     
      return await data;
    }

    const getCustomerApis = async () : Promise<ICustomerApi[]>  => {    
      //const {name, description} = entitySearch;
      const {data} = (await axios.get(`/api/home/get-customer-apis`));
      return await data;
    }

    const getCustomerApi = async (apiName: string) : Promise<ICustomerApi>  => {    
      //const {name, description} = entitySearch;
      const {data} = (await axios.get(`/api/home/get-customer-api/?apiName=${apiName}`));
      return await data;
    }

    const checkEntityExpressionSyntax = async (entityName: string, expression: string) : Promise<{syntaxOk: boolean, syntaxError: string, returnType: string}>  => //{          
      await (await axios.post('/api/home/check-entity-expression-syntax', {entityName, expression})).data;
    //   const {data} = (await axios.post(`/api/home/check-entity-expression-syntax/?entityName=${entityName}&expression2Check=${expression2Check}`));
    //   return await data;
    // }

    const openEntityActionDrawer = async (entityName: string, entityId: number) => {         
      
      if(isFalsy(entityName) || isFalsy(entityId) || entityId<= 0) {
          enqueueSnackbar( t('This action is only taken on valid entity'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
      }

      setActionEntityName(entityName);
      setActionEntityId(entityId);
      setIsActionDrawerOpen(true);      
    }

    function showEntitySearchBox()  {      

      
      setIsSearchBoxShow(true);      
    }

    // function showEntitySearchBox<T> (basicFilterProps: BasicTextFilterProps<T>)  {      

      
    //   //setCurrentBasicTextFilterProps(basicFilterProps);
    //   setIsSearchBoxShow(true);      
    // }


    // const setCurrentActionsByEntity = async (entityName: string, entityId: number)  => { 
    //   const actions = await getActionsByEntity(entityName,entityId);

    //   setCurrentEntityActions(actions);
    // } 

      
    return {    
        retrieveEntity,
        retrieveData,

        getFeaturesByEntityType,
        getFeaturesByEntity,
        getFeaturesByEntityForUser,

        getParametersByFeature,

        getRootEntities,

        getCustomerApis,
        getCustomerApi,

        getEntityProperties,
        checkEntityExpressionSyntax,

        executeEntityFeature,

        openEntityActionDrawer,

        showEntitySearchBox
        
        // setCurrentActionsByEntity,
    } 
}

export default _;


export interface IFilterEntityOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IEntity[], React.Dispatch<React.SetStateAction<IEntity[]>>],
}

const defaultFilterEntityOption: IFilterEntityOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}

export const useBasicFilterEntity = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IEntity) => void,filterOption?: IFilterEntityOption
  ) => {

  const { getRootEntities } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterEntityOption;

  const [headEntityCells, setHeadEntityCells]  = useState<HeadCell<IEntity>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
  ]);  
  const [filterElements,] = useState( [       
      {name: 'name', text: t('Name'), value: ''}, 
      {name: 'description', text: t('Description'), value: ''},     
    ]);    

  const [filteredEntities, setFilteredEntities] = useState<IEntity[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IEntity[]> => {
        
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
        
    const arr = await getRootEntities( {name, description} );
    
    return arr;
  }

  const objKey: keyof IEntity = 'name';

  return {
    title: t('Roles'), headCells: headEntityCells, objKey,
    filterElements, rows: filteredEntities, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

export interface IFilterFeatureOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IFeatureDescription[], React.Dispatch<React.SetStateAction<IFeatureDescription[]>>],
}

const defaultFilterFeatureOption: IFilterFeatureOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}

export const useBasicFilterFeatureDescription = ( getEntityName: () => string,
    onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IFeatureDescription) => void, filterOption?: IFilterFeatureOption  ) => {

  const { getFeaturesByEntityType } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();
  
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterFeatureOption;  

  const [headFeatureDescriptionCells, setHeadFeatureDescriptionCells]  = useState<HeadCell<IFeatureDescription>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'label', label : t('Description'),  display: true, type: 'string', },
  ]);  
  const [filterElements,] = useState( [       
    {name: 'featureName', text: t('Name'), value: ''}, 
    {name: 'featureDescription', text: t('Description'), value: ''},   
    ]);    

  const [filteredFeatureDescriptions, setFilteredFeatureDescriptions] = useState<IFeatureDescription[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IFeatureDescription[]> => {
               
    const featureName = filterElements.find( elt => elt.name === 'featureName')?.value || '';
    const featureDescription = filterElements.find( elt => elt.name === 'featureDescription')?.value || '';
        
    const arr = await getFeaturesByEntityType( getEntityName(), {featureName, featureDescription} );
    
    return arr;
  }

  const objKey: keyof IFeatureDescription = 'name';

  return {
    title: t('Features'), headCells: headFeatureDescriptionCells, objKey,
    filterElements, rows: filteredFeatureDescriptions, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}