
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { IExtensionType, defaultExtensionType } from './models/ExtensionType';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom } from 'library/store';
import useExtensionTypeService, { useBasicFilterExtensionType } from './services/ExtensionType';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { ClickAwayListener, IconButton, InputAdornment, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import CodeIcon from '@mui/icons-material/Code';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { ListItemIcon } from '@mui/material';

import TextFieldRight from 'components/ui/TextFieldRight';

import { stripHtml } from "string-strip-html";

import { AiOutlineMessage, AiOutlineWhatsApp, AiOutlineFacebook, AiOutlineFileExcel, AiOutlineFileWord, AiOutlineLayout } from 'react-icons/ai';

import { HiOutlineDocumentReport } from 'react-icons/hi';

import { isFalsy } from 'utility-types';
import DocViewer, { PDFRenderer, PNGRenderer, MSDocRenderer ,DocViewerRenderers} from '@cyntler/react-doc-viewer';

import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useReportService, { useBasicFilterReport } from './services/Report';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { IReport, defaultReport, IReportField, ReportType, MessageType, ReportFileType , defaultReportDocument } from './models/Report';

import { globalConfig } from 'config';
import { RichWysiwygEditor } from 'components/ui/RichWysiwygEditor';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';

import useEnumerationService from 'features/configuration/services/Enumeration';
import { IEnumerationItem, Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT } from 'features/configuration/models/Enumeration';

//import * from '../../../public'

export const ReportForm: FC<IReport> = (props: IReport = defaultReport) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntityExpressionSyntax } = entityService();

  const { createReport, updateReport } = useReportService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterReport = useBasicFilterReport( 
    (event: React.MouseEvent<unknown>, row: IReport) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
    const basicFilterEntity = useBasicFilterEntity( 
        (event: React.MouseEvent<unknown>, row: IEntity) => {
            const {name, description} = row;

            setValue('entityName', name || '');      
            setValue('entityDescription', description || '');      
            setOpenEntityFilter(false);
        }
    );

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
    
  const methods = useForm<IReport>({defaultValues:defaultReport});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields: reportDocuments, append: appendReportDocuments, update ,remove,  } = useFieldArray({ name: 'reportDocuments', control, });

  const watchCurrentDocumentLanguage = watch('currentDocumentLanguage');
  const watchReportType = watch('type');
  const watchCurrentMessageType = watch('currentMessageType');
  const watchCurrentReportFileType = watch('currentReportFileType');

  const watchEntityName = watch('entityName');

  const [currentFieldIndex, setCurrentFieldIndex] = useState(-1);
  const [currentFieldExpression, setCurrentFieldExpression] = useState('');  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IReport>,Error,IReport>(
      _id>0?updateReport:createReport, {   
        onSuccess: (data: IResult<IReport>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Report',data.data.id]);
        },
        onError: (err: Error) => {          
          // enqueueSnackbar( error?.message, { variant: 'error',
          //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

      const [enumItems, setEnumItems] = useState<IEnumerationItem[]>([]);
      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'Report'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT] ));

    const {data: _data, refetch} = useQuery<IReport>(['Report', _id], () => retrieveEntity('Report',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      // const handleClickSearchEnumeration = (event: any) => {
      //   setOpenEnumerationFilter(true);
      // } 
      
    const reportFieldRowActionIcon = ( reportField: IReportField) : ActionIconTableRow<IReport,IReportField> => {  
      const res: ActionIconTableRow<IReport,IReportField> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: IReportField) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IReportField) => {           
          
          (refRemoveReportField.current??emptyFunc)(index);            
        }
      }
      return res;
    }
      
    const getInputAdornmentReportField = (row: IReportField, cellId: keyof IReportField)  => ({
 
      toolTip: 'Expression',
      icon: DeveloperModeIcon,
      onClickIcon: (event: any, index: number, row: IReportField ) => {
        
        const {valueExpression, format} = row;
        
        if(isFalsy(getValues().entityName)) {
          enqueueSnackbar( t('Entity is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        
        if(isFalsy(format)){
          enqueueSnackbar( t('Data format is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }


        setCurrentFieldExpression(valueExpression);
        setCurrentFieldIndex(index);
        setOpenEntityExpression(true);
        
        //handleClickSearchBusinessApplicationQuery('codification')(undefined);
      }  
   })

   const getFormatsList = (row: IReportField, cellId: keyof IReportField, 
      opts: {value: string, name: string}[]) => {        

      //const {requestClassEnumerationCode} = row;
      
      return getAsOptions(refEnumItems.current ?? [],Enum_REPORTFIELD_DATA_FORMAT);
    }

    const cellEditableReportField = (row: IReportField, cellId: keyof IReportField) => {
      return true;
    }
    const [headReportFieldCells, setHeadFieldCells]  = useState<HeadCell<IReportField>[]>([]);
    useEffect(() => {
      setHeadFieldCells([            
        {id:'name', label : t('Name'),  display: true, type: 'string', width: 40, isEditable: cellEditableReportField},
        {id:'format', label : t('Format'),  display: true, type: 'string', width: 25, isEditable: cellEditableReportField, 
          getOptions: getFormatsList },
        {id:'alias', label : t('Alias'),  display: true, type: 'string', width: 35, isEditable: cellEditableReportField,
          getInputAdornment: getInputAdornmentReportField}
            
        //{id:'extensionTypeDescription', label : t('Type description'),  display: true, type: 'string',} ,  
        //{id:'extensionTypeType', label : t('Type'),  display: true, type: 'string',} ,  
      ]  )
    }, [t,i18n])

    const refAppendReportFields = useRef<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateReportField = useRef<(index: number,value: Partial<FieldArray<IReport>> ) => void>(null);
    const refRemoveReportField = useRef<(index: number ) => void>(null);

    const handleAddReportFields = (event: any) => {
      (refAppendReportFields.current??emptyFunc)({ id: 0,  name: '', alias: '', format: 'exact' }); 
    }

    const handleClickOkExpression = async () => {
      if(currentFieldIndex <0 || getValues().reportFields.length <= currentFieldIndex)
          return;
      
      const reportField = getValues().reportFields[currentFieldIndex];
      const checkExpression = await checkEntityExpressionSyntax(watchEntityName, currentFieldExpression);
      
      if(!checkExpression){
        enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      const {syntaxOk, syntaxError, returnType} = checkExpression;
      if(!syntaxOk) {
        enqueueSnackbar( syntaxError , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      const enumItemFormat = enumItems.find(x => x.enumerationCode === Enum_REPORTFIELD_DATA_FORMAT && x.code === reportField.format );

      if(returnType !== enumItemFormat?.parentEnumerationItemCode) {
        enqueueSnackbar( t('Selected format and return type of expression are incompatible') , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      // check expression syntax before update valueExpression.      
      //

      (refUpdateReportField.current??emptyFunc)(currentFieldIndex, {...reportField, valueExpression: currentFieldExpression} );
      setOpenEntityExpression(false);
    }

    const handleClickSearchEntity = (event: any) => {
      setOpenEntityFilter(true);
    }  

    const handleClickEmailObjectExpression = (emailObj : string) => {
      setCurrentFieldExpression(emailObj);
      //setCurrentFieldIndex(index);
      setOpenEntityExpression(true);
    }

    const [reportType, setReportType] = useState<ReportType>('message');
    const handleChangeReportType = ( event: React.MouseEvent<HTMLElement>, newType: ReportType ) => {  

      console.log(newType);
      if (newType !== null) {
        setReportType(newType);
        setValue('type', newType);
      }      
    }

    useEffect( () => {

      if(isFalsy(watchCurrentDocumentLanguage)) return;
      if(isFalsy(watchReportType)) return;
      
      if(watchReportType === 'message' && watchCurrentMessageType === '') return;
      if(watchReportType === 'file' && watchCurrentReportFileType === '') return;

      console.log(watchReportType);
      console.log(watchCurrentMessageType);
      console.log(watchCurrentReportFileType);
      console.log(watchCurrentDocumentLanguage);

      const reportDocument = getValues().reportDocuments.find(d => 
          d.documentLanguage === watchCurrentDocumentLanguage && d.type === watchReportType &&
          d.messageType === watchCurrentMessageType && d.reportFileType === watchCurrentReportFileType );

      console.log(reportDocument);
      
      console.log(reportDocuments);

      if(isFalsy(reportDocument))         
        appendReportDocuments( {...defaultReportDocument, type: watchReportType, documentLanguage:watchCurrentDocumentLanguage, 
          messageType: watchCurrentMessageType, reportFileType: watchCurrentReportFileType  });      
      
    }, [watchReportType, watchCurrentDocumentLanguage,  watchCurrentMessageType, watchCurrentReportFileType])

    function openFileDialog( watchReportType: ReportType ) {
      const fileElementId = watchReportType as unknown as string;
      var element = (document as any).getElementById(fileElementId);

      if(!isFalsy(element))
        element.click();
    }

    const setFile = (_event: any, watchReportType: ReportType) => {

    }

    const [anchorElMessageMenu, setAnchorElMessageMenu] = useState(null);
    const openMessageMenu = Boolean(anchorElMessageMenu);

    const handleMessageMenuClick = (event : any) => {  
      
      if(_isMessageMenuItemClick) {
        _isMessageMenuItemClick = false;
        return;
      }        
      setAnchorElMessageMenu(event.currentTarget);
    }
  
    let _isMessageMenuItemClick: boolean = false;
    const handleMessageMenuItemClick = (messageType: MessageType) => {
      _isMessageMenuItemClick = true;
      setValue('currentMessageType', messageType);
      setAnchorElMessageMenu(null);      
    }

    const [anchorElDocumentMenu, setAnchorElDocumentMenu] = useState(null);
    const openDocumentMenu = Boolean(anchorElDocumentMenu);

    const handleDocumentMenuClick = (event : any) => {  
      if(_isDocumentMenuItemClick) {
        _isDocumentMenuItemClick = false;
        return;
      }        
      setAnchorElDocumentMenu(event.currentTarget);
    }
  
    let _isDocumentMenuItemClick: boolean = false;
    const handleDocumentMenuItemClick = (documentType: ReportFileType) => {
      _isDocumentMenuItemClick = true;
      setValue('currentReportFileType', documentType);
      setAnchorElDocumentMenu(null);      
    }
  
    const refEnumItems = useRef<IEnumerationItem[]>();

    useEffect( () => {
      async function loadEnumerationItems() {
        const arr = await getEnumerationItemsByEnumerationCodes( [Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT] );
        setEnumItems([...arr]);

        refEnumItems.current = arr;
      }

      loadEnumerationItems();
    }, [])

      useEffect( () => {        
        setCurrentFormNameAtom(t('Report'));
        setCurrentBasicTextFilterProps(basicFilterReport);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Report',_id, refetch);  
          }, [_id] );
    
    
      useEffect( () => {
         
        if(_data && _data.id > 0) {

            console.log(_data);

            reset({..._data,
            type: _data.reportDocuments.length ? _data.reportDocuments[0].type : 'message',
            currentDocumentLanguage: _data.reportDocuments.length ? _data.reportDocuments[0].documentLanguage : 'fr-FR',
            currentMessageType: _data.reportDocuments.length ? _data.reportDocuments[0].messageType : 'sms',
            currentReportFileType: _data.reportDocuments.length ? _data.reportDocuments[0].reportFileType : 'layout'  });
          }
          
        }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {   
        _setId(0);       
        reset(defaultReport);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              return;
            }
      
          console.log(data);
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Report', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

    

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(20% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(80% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                 
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                           </Box>
                        </Stack>                        
                    </Grid>     
                    <Grid item xs={12} md={6} lg={3} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="entityDescription" label={t('Entity')} 
                                  {...register('entityDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchEntity}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                           </Box>
                           { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityFilter(false);}} onOk={()=> {setOpenEntityFilter(false);}}  >
                                    <BasicTextFilterForm<IEntity> {...basicFilterEntity} />
                                  </FormDialog>  }
                        </Stack>                        
                    </Grid>
                          
                    <Grid item xs={12} md={12} lg={5} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IReport,IReportField,'id'> 
                                key={`Report field -`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headReportFieldCells} rowsPathName='reportFields' 
                                title={t(`Items list`)} rowActionIcon={reportFieldRowActionIcon}  
                                //onRowSelected={handleRoleEntitySelected}
                                                    
                                refAppend={refAppendReportFields as MutableRefObject<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateReportField as MutableRefObject<(index: number,value: Partial<FieldArray<IReport>>) => void>}
                                refRemove={refRemoveReportField as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}
                                
                                toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddReportFields ,icon: AddCircleIcon, },  
                                                          
                                ]}
                            /> 
                            { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={watchEntityName} properties={[]} 
                                      expression={currentFieldExpression} setExpression={setCurrentFieldExpression} />
                                  </FormDialog>  }                           
                        </Box>
                      </Stack>   
                    </Grid>      
                    <Grid item xs={12} md={12} lg={7} component={Paper} >
                      <Stack flexDirection='column'>                        
                          <Box sx={{ mt: 1, width: '100%', pt: 3 }} >
                            <Stack flexDirection='row'>
                              <Box sx={{ mt: 1, width: '40%', pt: 0 }} >
                                <Controller name='currentDocumentLanguage' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="currentDocumentLanguage"
                                        label={t('Document language')} >
                                          <MenuItem  value="fr-FR">{t('French')}</MenuItem>
                                          <MenuItem  value="en-US">{t('English')}</MenuItem>
                                      </TextField>
                                    )}
                                />
                              </Box>
                              <Box sx={{ mt: 1, width: '60%', pt: 0 }} >
                                <ToggleButtonGroup size="small"
                                  color="primary"
                                  value={reportType}
                                  exclusive
                                  onChange={handleChangeReportType}
                                  aria-label="Small sizes" fullWidth
                                >
                                  <ToggleButton value="message" onClick={handleMessageMenuClick}>
                                    {t('Message')} 
                                    {(watchReportType === 'message' && watchCurrentMessageType === 'sms') && <AiOutlineMessage /> }
                                    {(watchReportType === 'message' && watchCurrentMessageType === 'whatsApp') && <AiOutlineWhatsApp /> }
                                    {(watchReportType === 'message' && watchCurrentMessageType === 'facebook') && <AiOutlineFacebook /> }
                                      
                                      <Menu anchorEl={anchorElMessageMenu}
                                        open={openMessageMenu}>
                                        
                                          <MenuItem value="sms" onClick={() => handleMessageMenuItemClick('sms')}>
                                            <ListItemIcon color="primary"><AiOutlineMessage /></ListItemIcon>
                                            {t('Sms')}
                                          </MenuItem>
                                          <MenuItem value="whatsApp" onClick={() => handleMessageMenuItemClick('whatsApp')}>
                                            <ListItemIcon color="primary"><AiOutlineWhatsApp /></ListItemIcon>
                                            {t('WhatsApp')}
                                          </MenuItem>
                                          <MenuItem value="facebook" onClick={() => handleMessageMenuItemClick('facebook')}>
                                            <ListItemIcon color="primary"><AiOutlineFacebook /></ListItemIcon>
                                            {t('Facebook')}
                                          </MenuItem>
                                      </Menu>                                                                                                                                       
                                  </ToggleButton>
                                  <ToggleButton value="email">{t('Email')} </ToggleButton>        
                                  <ToggleButton value="file" onClick={handleDocumentMenuClick}>
                                    {t('Document')}   
                                    {(watchReportType === 'file' && watchCurrentReportFileType === 'layout') && <HiOutlineDocumentReport /> }
                                    {(watchReportType === 'file' && watchCurrentReportFileType === 'doc') && <AiOutlineFileWord /> }
                                    {(watchReportType === 'file' && watchCurrentReportFileType === 'excel') && <AiOutlineFileExcel /> }      
                                    <Menu anchorEl={anchorElDocumentMenu}
                                        open={openDocumentMenu}>                                        
                                          <MenuItem value="layout" onClick={() => handleDocumentMenuItemClick('layout')}>
                                            <ListItemIcon color="primary"><HiOutlineDocumentReport /></ListItemIcon>
                                            {t('Layout')}
                                          </MenuItem>
                                          <MenuItem value="doc" onClick={() => handleDocumentMenuItemClick('doc')}>
                                            <ListItemIcon color="primary"><AiOutlineFileWord /></ListItemIcon>
                                            {t('Word')}
                                          </MenuItem>
                                          <MenuItem value="excel" onClick={() => handleDocumentMenuItemClick('excel')}>
                                            <ListItemIcon color="primary"><AiOutlineFileExcel /></ListItemIcon>
                                            {t('Excel')}
                                          </MenuItem>
                                      </Menu>
                                    </ToggleButton>     
                                </ToggleButtonGroup> 
                              </Box>
                            </Stack>    
                          </Box>                                              
                        {
                             getValues().reportDocuments.map( (reportDocument, index) => {
                              
                              const {type, documentLanguage, messageType, reportFileType, emailObject} = reportDocument;
                              return (type === watchReportType && documentLanguage === watchCurrentDocumentLanguage && messageType === watchCurrentMessageType && reportFileType === watchCurrentReportFileType) && (
                              <Fragment key={`key-${index} - ${reportDocument.type} - ${reportDocument.documentLanguage} - ${reportDocument.messageType} - ${reportDocument.messageType}`}>
                                
                                { watchReportType === 'email' && 
                                  <Box sx={{ mt: 1, width: '100%' }} >                                
                                    <Controller
                                        key={`query-${reportDocument.emailObject}-query-x`}
                                        render={({ field }) => <TextField label={t('Email object')} {...field} multiline={true} rows={2}  
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`, textTransform: 'none'}} 
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={() => handleClickEmailObjectExpression(emailObject)}>
                                                  <DeveloperModeIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />} 
                                          name={`reportDocuments.${index}.emailObject`}                      
                                        control={control}
                                      />
                                  </Box>
                                }
                                { ( watchReportType === 'message' || watchReportType === 'email' ) &&
                                  <Box sx={{ mt: 1, width: '100%' }} id={`reportDocuments.${index}.messageBody`} > 
                                    <Controller

                                        render={({ field }) => 
                                          <RichWysiwygEditor {...field} 
                                            mentionSuggestions={getValues().reportFields.map(({alias}) => ({text: alias, value: "${"+alias+"}"}))}
                                            id={`key-${index} - ${reportDocument.type} - ${reportDocument.documentLanguage} - ${reportDocument.messageType}`} />}
                                            name={ watchReportType === 'message' ?
                                              `reportDocuments.${index}.messageBody` :
                                              `reportDocuments.${index}.emailBody`}
                                            control={control}
                                            defaultValue=""
                                            
                                            rules={{
                                              validate: {
                                                required: (v) =>
                                                  (v && stripHtml(v).result.length > 0) ||
                                                  "Description is required",
                                                maxLength: (v) =>
                                                  (v && stripHtml(v).result.length <= 2000) ||
                                                  "Maximum character limit is 2000",
                                              },
                                            }}
                                      />                                                      
                                  </Box>
                                }

                                { (watchReportType === 'file') &&
                                  <Box sx={{ mt: 1, width: '100%' }} > 
                                    <input type="file" id={watchReportType} style={{ display: "none" }} onChange={ (_event: any) => setFile(_event, watchReportType) }/>
                                    <Button onClick={ () => openFileDialog(watchReportType)}>
                                        {isFalsy(reportDocument.documentFileName) ? `${t('Click to toad file')} ...`: `${reportDocument.documentFileName} -> Click to change`}
                                    </Button>                                                      
                                  </Box>
                                }
                                { (watchReportType === 'file') &&
                                  <Box sx={{ mt: 1, width: '100%', height: 400 }} > 
                                    <iframe src={`${globalConfig.get().apiUrl}/api/production/requestData/get-reportData-merged-pdf-files?id=8`} 
                                      width="100%" height="100%" title='kkk'></iframe>                                      
                                  </Box>                             
                                }
                                
                              </Fragment> )})
                        }
                      </Stack>   
                    </Grid>                          
                </Grid>
            </Box>
        </FormProvider> 
  )
}

