

export const Enum_REQUESTDATA_STATUS = 'RequestData_Status';

export const Enum_FTP_PROTOCOL = 'Ftp_Protocol'; 
export const Enum_TLS_SECURITY = 'Tls_Security';  
export const Enum_FTP_MODE = 'Ftp_Mode'; 

export const Enum_REQUEST_CLASS = 'Request_Class'; 
export const Enum_REQUESTCLASS_ENUMERATION = 'RequestClass_Enumeration'; 
export const Enum_REQUESTCLASS_ENUMERATIONITEM = 'RequestClass_EnumerationItem'; 

export const Enum_REQUESTSTATUS_TYPE = 'RequestStatus_Type'; 
export const Enum_REQUESTSTATUS_STATUS = 'RequestStatus_Status'; 

export const Enum_REPORTFIELD_DATA_TYPE = 'ReportField_Data_Type';
export const Enum_REPORTFIELD_DATA_FORMAT = 'ReportField_Data_Format';

export const Enum_JOB_RETENTION_MODE = 'Job_Retention_Mode';
export const Enum_DURATION_UNIT = 'Duration_Unit';

// type EnumerationExType = 'baseType' | 'enumeration' | 'entity';
// type EnumerationExItemType = 'text' | 'number' | 'date' | 'bool' | 'enumItemCode' | 'entityId';

export enum EnumerationExType {
  BaseType = 1,
  Enumeration = 2,
  Entity = 3
}

export enum EnumerationExItemType {
  Text = 11,
  Number = 12,
  Date = 13,
  Bool = 14,
  ItemCode = 2,
  EntityId = 3
}


export interface IEnumerationItemEx {
  id: number,
  enumerationItemId: number,

  enumerationExId: number,
  enumerationExName: string,

  extensionValueId: number,

  value: any,
  
  // type: EnumerationExItemType,

  // valueText: string,
  // valueNumber: string,
  // valueDate: Date,
  // valueBool: boolean,

  // valueEnumItemCode: string,
  // valueEntityId: number


  


//ExtensionValueId	bigint	Checked


}


export interface IEnumerationItem {
  id: number,

  enumerationId: number,
  enumerationCode: string,
  code: string,
  name: string,
  description: string,

  parentEnumerationItemCode: string,
  parentEnumerationCode: string,
      
  enumerationItemExs: IEnumerationItemEx[]
}
 

export interface IEnumerationEx {
  id: number,
  
  enumerationId: number,
      
  name: string,
  description: string,

  extensionTypeId: number,

  extensionTypeType: string,
  extensionTypeName: string,
  extensionTypeDescription: string,

  enumerationItemExs: IEnumerationItemEx[]
}

export default interface IEnumeration {
  id: number, 
  code: string, 
  name: string, 
  description: string, 
  usage: string, 

  parentEnumerationId: number,
  parentEnumerationName: string,
      
  enumerationExs: IEnumerationEx[],
  enumerationItems: IEnumerationItem[],    
}

export const defaultEnumeration : IEnumeration = {
  id: 0,
  code: '',
  name: '',
  description: '',
  usage: '',

  parentEnumerationId: 0,
  parentEnumerationName: '',

  enumerationExs: [],
  enumerationItems: [],  
}

export interface IEnumerationSearch {
  code: string,
  name: string,
  description: string
}

