import React, { useState, useEffect, useRef } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import { useTranslation  } from 'react-i18next';

import { fileTokensAtom, hubConnectionIdAtom,
    requestDataNotificationKeyAtom, requestDataItemResponseArrivedIdsAtom,  requestDataSendedIdsAtom, requestDataSendedOkIdsAtom,
    requestDataResponseCheckedIdsAtom, requestDataResponseCheckedOkIdsAtom, requestDataResponseArrivedIdsAtom} from 'library/store';
import { globalConfig } from 'config';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { GrDownload, GrClose, GrPrint } from 'react-icons/gr';



export const RequestNotification = () => {

    const { t, i18n } = useTranslation();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [hubConnectionId, setHubConnectionId] = useRecoilState(hubConnectionIdAtom);

    const [_, setRequestDataNotificationKey] = useRecoilState(requestDataNotificationKeyAtom);

    const [currentFileToken, setCurrentFileToken] = useState<string>('');
    const [currentFileName, setCurrentFileName] = useState<string>('');
    
    const [requestDataSendedIds, setRequestDataSendedIds] = useRecoilState(requestDataSendedIdsAtom);
    const [requestDataItemResponseArrivedIds, setRequestDataItemResponseArrivedIds] = useRecoilState(requestDataItemResponseArrivedIdsAtom);

    const [requestDataSendedOkIds, setRequestDataSendedOkIds] = useRecoilState(requestDataSendedOkIdsAtom);
    const [requestDataResponseCheckedIds, setRequestDataResponseCheckedIds] = useRecoilState(requestDataResponseCheckedIdsAtom);
    const [requestDataResponseCheckedOkIds, setRequestDataResponseCheckedOkIds] = useRecoilState(requestDataResponseCheckedOkIdsAtom);
    const [requestDataResponseArrivedIds, setRequestDataResponseArrivedIds] = useRecoilState(requestDataResponseArrivedIdsAtom);
        

    const [ connection, setConnection ] = useState<HubConnection>();
    const [ chat, setChat ] = useState([]);
    const latestChat = useRef(null);

    const refAnchorElement = useRef<HTMLAnchorElement | null>(null);

    const xActionFileToken = (fileToken: string, fileName: string): SnackbarAction => (snackbarId: SnackbarKey) => (
        <>
            <a href={`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`} ref={refAnchorElement} id="fileDownload" className="hidden" target="__blank" download/>
            <Button onClick={() => { refAnchorElement?.current?.click(); closeSnackbar(snackbarId) }}>   
                <GrDownload size={24} />
            </Button>
            <Button onClick={() => { closeSnackbar(snackbarId) }}>
                <GrClose size={24} />
            </Button>
        </>
    )

    // latestChat.current = chat;

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${globalConfig.get().apiUrl}/hubs/requestNotifications`)  //'http://localhost:45073/hubs/requestNotifications
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {   
        if (connection) {
            connection.start()
                .then( () => console.log('Connected!'))
                .then( () => { setHubConnectionId(connection?.connectionId || ''); console.log(connection.connectionId); })
                .then( () => {
                    console.log('Connected!');
    
                    connection.on('notifyRequestSended', id => { 
                        //console.log(`notifyRequestSended ${id}`);
                        setRequestDataSendedIds([...requestDataSendedIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyRequestSended ${id}`);
                    });

                    connection.on('notifyRequestSendedOk', id => { 
                        //console.log(`notifyResponseChecked ${id}`);
                        setRequestDataSendedOkIds([...requestDataSendedOkIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyRequestSendedOk ${id}`);
                    });

                    connection.on('notifyResponseChecked', id => { 
                        //console.log(`notifyResponseChecked ${id}`);
                        setRequestDataResponseCheckedIds([...requestDataResponseCheckedIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyResponseChecked ${id}`);
                    });

                    connection.on('notifyResponseCheckedOk', id => { 
                        //console.log(`notifyResponseCheckedOk ${id}`);
                        setRequestDataResponseCheckedOkIds([...requestDataResponseCheckedOkIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyResponseCheckedOk ${id}`);
                    });

                    connection.on('notifyResponseArrived', id => { 
                        //console.log(`notifyResponseArrived ${id}`);
                        setRequestDataResponseArrivedIds([...requestDataResponseArrivedIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyResponseArrived ${id}`);
                    });

                    connection.on('notifyItemResponseArrived', id => { 
                        //console.log(`notifyItemResponseArrived ${id}`);
                        setRequestDataItemResponseArrivedIds([...requestDataItemResponseArrivedIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyItemResponseArrived ${id}`);
                    });


                    connection.on('fileToken', ({fileToken,fileName}) => { 

                        setCurrentFileToken(fileToken);
                        setCurrentFileName(fileName);                        

                        enqueueSnackbar( t('You have received new file to download'), { variant: 'info',
                            anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true,
                            action: xActionFileToken(String(fileToken),String(fileName))   } );
                    });

                    connection.on('notifyTest', id => { 

                        // enqueueSnackbar( 'You have to select business appication before fetch business data', { variant: 'warning',
                        //         anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                        //console.log(`notifyItemResponseArrived ${id}`);
                        // setRequestDataItemResponseArrivedIds([...requestDataItemResponseArrivedIds, Number(id)]);
                        // setRequestDataNotificationKey(`notifyTest ${id}`);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    // const sendMessage = async (user, message) => {
    //     const chatMessage = {
    //         user: user,
    //         message: message
    //     };

    //     if (connection.connectionStarted) {
    //         try {
    //             await connection.send('SendMessage', chatMessage);
    //         }
    //         catch(e) {
    //             console.log(e);
    //         }
    //     }
    //     else {
    //         alert('No connection to server yet.');
    //     }
    // }

    return (
        <>
        </>
    );
};

//export RequestNotification;