
export interface IUser {
    id: number,

    accessFailedCount: number,
    concurrencyStamp: string,
    email: string,
    emailConfirmed: boolean,
    lockoutEnabled: boolean,
    lockoutEnd: Date,
    normalizedEmail: string,
    normalizedUserName: string,
    passwordHash: string,
    phoneNumber: string,
    phoneNumberConfirmed: boolean,
    securityStamp: string,
    twoFactorEnabled: boolean,
    userName: string,
    isActive: boolean,
    salt: string,
    serviceCode: string,
    personId: number,
    userDescription: string,
    firstName: string,
    fastName: string,
    profilePicture: string,
    refreshToken: string,
    refreshTokenExpiryTime: Date,

    password: string,
    passwordMD5: string,
    isPasswordChanged: boolean,

    userRoles: IUserRole[],

    userBusinessApplications: IUserBusinessApplication[],
    userRequestTypes: IUserRequestType[]
  }

  export interface IUserRole {
    id: number,
    
    userId: number,
    roleId: number,

    roleName: string,
    roleDescription: string,
  }

  export interface IUserBusinessApplication {
    id: number,
    
    userId: number,
    businessApplicationId: number,
    businessApplicationUserName: string,

    businessApplicationName: string,
    businessApplicationDescription: string,
  }

  export interface IUserRequestType {
    id: number,
    
    userId: number,
    requestTypeId: number,

    requestUserName: string,
    requestUserPassword: string,
    accessCode: string,
    applicantCode: string,
    intermediaryAccess: string,

    accesCondition: string,
    priority: number,

    requestTypeName: string,
    requestTypeDescription: string,
  }


  export const defaultUser : IUser = {
    id: 0,
    accessFailedCount: 0,
    concurrencyStamp: '',
    email: '',
    emailConfirmed: false,
    lockoutEnabled: false,
    lockoutEnd: new Date(),
    normalizedEmail: '',
    normalizedUserName: '',
    passwordHash: '',
    phoneNumber: '',
    phoneNumberConfirmed: false,
    securityStamp: '',
    twoFactorEnabled: false,
    userName: '',
    isActive: false,
    salt: '',
    serviceCode: '',
    personId: 0,
    userDescription: '',
    firstName: '',
    fastName: '',
    profilePicture: '',
    refreshToken: '',
    refreshTokenExpiryTime: new Date(),

    password: '',
    passwordMD5: '',
    isPasswordChanged: false,

    userRoles: [],
    userBusinessApplications: [],
    userRequestTypes: []
  }

  export interface IUserSearch {
  
  userName: string,
  userDescription: string,  
}

