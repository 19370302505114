import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ISmsApi,  ISmsApiSearch } from "../models/SmsApi";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios();    
    const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);

    const {id} = currentUserSession;
    
    const checkNumberExs = async (numbers: string[]) : Promise<{number: string, isValid: boolean}[]> => {

      return await (await axios.post('/api/production/check-numbers', {contractId: id, recipientsNumber: numbers})).data; 
    }
      
    return {    
      checkNumberExs      
    } 
}

export default _;


