import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {IBusinessApplication, IBusinessApplicationQuery, IBusinessApplicationQueryParameter, IBusinessApplicationQuerySearch, IBusinessApplicationSearch } from '../models/BusinessApplication';

const _ = () => {

    const axios = useAxios();

    const createBusinessApplication = async (businessApplication: IBusinessApplication)  =>       
        await (await axios.post('/api/setup/businessApplication/create', businessApplication)).data;       
        
    const updateBusinessApplication = async (businessApplication: IBusinessApplication)  =>       
        await (await axios.post('/api/setup/businessApplication/update', businessApplication)).data;     
        
    const getBusinessApplication = async (id  : number )  => {
        const {data} = (await axios.get(`/api/setup/businessApplication/get-businessApplication/${id}`));
        return await data;
    }

    const getBusinessApplications = async (criteria: IBusinessApplicationSearch) : Promise<IBusinessApplication[]> => {
        const {name, dbmsType} = criteria;

        const {data} = (await axios.get(`/api/setup/businessApplication/get-businessApplications?name=${name}&dbmsType=${dbmsType}`));
        return await data;
        }

    const getBusinessApplicationQueries = async (businessApplicationId: number, criteria: IBusinessApplicationQuerySearch) : Promise<IBusinessApplicationQuery[]> => {
      const {name, description} = criteria;

      const {data} = (await axios.get(`/api/setup/businessApplication/get-businessApplication-queries?businessApplicationId=${businessApplicationId}&name=${name}&description=${description}`));
      return await data;
      }

      const getBusinessApplicationQueryParameters = async (businessApplicationQueryId: number) : Promise<IBusinessApplicationQueryParameter[]> => {
         
        const {data} = (await axios.get(`/api/setup/businessApplication/get-businessApplication-query-parameters?businessApplicationQueryId=${businessApplicationQueryId}`));
        return await data;
        }

    return {
        createBusinessApplication,
        updateBusinessApplication,
        getBusinessApplication,        
        getBusinessApplications,

        getBusinessApplicationQueries,
        getBusinessApplicationQueryParameters
        
    } 
}

export default _;



export const useBasicFilterBusinessApplication = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBusinessApplication) => void  ) => {

    const { getBusinessApplications } = _();
  
    const { t, i18n } = useTranslation();   
      
    const [headBusinessApplicationCells, setHeadBusinessApplicationCells]  = useState<HeadCell<IBusinessApplication>[]>([
      {id:'id', label : t('Id'),  display: true, type: 'numeric', },
      {id:'name', label : t('Name'),  display: true, type: 'string', },
      {id:'description', label : t('Description'),  display: true, type: 'string', },
  
    ]); 
    const [filterElements,] = useState( [       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},     
      ]);    
  
    const [filteredBusinessApplications, ] = useState<IBusinessApplication[]>([]); 
  
    const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IBusinessApplication[]> => {
              
      const name = filterElements.find( elt => elt.name === 'name')?.value || '';
      const dbmsType = filterElements.find( elt => elt.name === 'dbmsType')?.value || '';
      
      const arr = await getBusinessApplications( {name, dbmsType} );
      
      return arr;
    }
  
    const objKey: keyof IBusinessApplication = 'id';
  
    return {
      title: t('Business applications'), headCells: headBusinessApplicationCells, objKey,
      filterElements, rows: filteredBusinessApplications, 
      onFilterButtonClick, onRowDoubleClick
    }
  }


  
export const useBasicFilterBusinessApplicationQuery = ( getBusinessApplicationId: () => number,
onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBusinessApplicationQuery) => void ) => {

const { t, i18n } = useTranslation();   
const { enqueueSnackbar } = useSnackbar();
const { getBusinessApplicationQueries } = _();

const [headBusinessApplicationQueryCells, ]  = useState<HeadCell<IBusinessApplicationQuery>[]>([
  {id:'name', label : t('Name'),  display: true, type: 'string', },
  {id:'description', label : t('Description'),  display: true, type: 'string', },
 

  ]);  
  const [filterElements,] = useState<ITextFilterElement[]>( [
    {name: 'name', text: t('Name'), value: ''},  
    {name: 'description', text: t('Description'), value: ''}, 
  ]);    
  const [filteredBusinessApplicationQueries, ] = useState<IBusinessApplicationQuery[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IBusinessApplicationQuery[]> => {

    const businessApplicationId = getBusinessApplicationId();
    
    if(businessApplicationId <= 0) return [];

    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';

    const arr = await getBusinessApplicationQueries( businessApplicationId, {name, description} );

    return arr;
  }

  const objKey: keyof IBusinessApplicationQuery = 'id';

  return {
    title: t('Queries'), headCells: headBusinessApplicationQueryCells, objKey,
    filterElements, rows: filteredBusinessApplicationQueries, 
    onFilterButtonClick, onRowDoubleClick
  }
}

