import React, { useRef, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUnixTime } from 'date-fns';


import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {LockOutlined} from '@mui/icons-material';

import {Language as LanguageIcon, TranslateOutlined as TranslateOutlinedIcon,  } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ThreeDots } from 'react-loader-spinner';
import { IUserCredential, IUserSession } from 'features/identity/models/User';

import useAxios from 'library/axios';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { useLocalStorage } from 'react-use';
import { currentUserSessionAtom, hubConnectionIdAtom, lastUnixTimeStampAtom } from 'library/store';
import md5 from 'md5';
import { IResult } from 'library/interface';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Colors } from 'themes/theme';



function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.univsoft.com/nyxis">
          
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

export const Login = () => {

    const axios = useAxios();

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar(); 
    const navigate = useNavigate();

    const { register, getValues, setValue ,formState: { errors } } = useForm<IUserCredential>(
        {defaultValues: {language:'fr', userName: '', password: ''}});

    const [hubConnectionId, setHubConnectionId] = useRecoilState(hubConnectionIdAtom);
  
  const [ storeCustomerID, setStoreCustomerID ,  removeStoreCustomerID]  = useLocalStorage('CustomerID');
  const [ storeLanguage, setStoreLanguage ,  removeStoreLanguage]  = useLocalStorage('Language');
  
  const [ storeLogin, setStoreLogin, removeStoreLogin  ] = useLocalStorage('Login');

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
    
  // const setCurrentUser = useUserStore(state => state.setCurrentUser);

  const anchorRefLanguageButton = useRef(null);
  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);

  const inputLogin = useRef();
  const inputPassword = useRef();

  //const {setCurrentUser} = useUserStore();

  const postCredential = async (md5Credential: IUserCredential) => 
  await (await axios.post('/api/identity/token/get-contract-token', {...md5Credential })).data;


  const {isLoading, isError, isSuccess ,error,mutate} = useMutation< IResult<IUserSession>,Error,IUserCredential>(postCredential,
     {
       retry:2,
       onSuccess: (data: IResult<IUserSession>) => {
        try {
          
          if(!data.succeeded) {
            enqueueSnackbar( data.messages.join( '\n'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
            return;
          }
          
          if(!data.data.isAuthenticated) {
            enqueueSnackbar( t('Invalid user credential'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
            return;
          }

          //sessionStorage.setItem('lastUnixTimeStamp', String(unixTimeStamp) );

          setCurrentUserSession(data.data);
          navigate('/home');  
          // const {isConnected} = data;
          // if(isConnected) 
          //   connectUser();

        } catch {

        }
       }
    });

  
    const handleChangeLanguage = (
      event: React.MouseEvent<HTMLElement>,
      newLanguage: string,
    ) => {
      //setLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
      setOpenLanguageMenu(false);
      setStoreLanguage(newLanguage);
      setValue('language', newLanguage);
    };

    const onClickLanguageMenu = () => {
      setOpenLanguageMenu(true);  
    };
  
    const menuLanguageItemClick = (lg: string) => (event: any) =>{
      i18n.changeLanguage(lg);
      setOpenLanguageMenu(false);
      setStoreLanguage(lg);
      setValue('language', lg);

      // console.log(i18n);
      // console.log(i18n.getResource('fr','common','Signin'));
      //localStorage.setItem('lastLanguage', lg);
    }
  
    const closeLanguageMenu = () => {
      setOpenLanguageMenu(false);
    };


  const postLogin = async (event: MouseEvent<HTMLButtonElement>) => {
    
    event.preventDefault();
    
    const  {userName, password, language } = getValues();
    // if( externalCustomerID && externalCustomerID <= 0) {
    //   alert('invalid banque ...');
    //   return;
    // }

    //setStoreCustomerID(externalCustomerID);
    // console.log(login);
    // console.log(password);
    // console.log(getValues());
    // console.log(md5(password));
    

     mutate( { userName, password: md5(password), language, hubConnectionId } );

    //console.log(data);
    //event.preventDefault();
    //const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
  };

  return (    
    <Grid container component="main" sx={{ height: '100vh', 
                    backgroundImage: 'url(https://source.unsplash.com/random)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center', }}
        >
        <Grid item xs={1} sm={4} md={4} lg={5}></Grid>
        
        <Grid item container xs={10} sm={5} md={4} lg={3} justifyContent="center" alignItems="center" >
          
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', 
              alignContent:'center', width: '100%',
              background: 'white', opacity: 0.9, borderRadius: 10 }} >
            <Box sx={{ mt: 1, width: '100%', px: 2  }} > 
              <ToggleButtonGroup size="small"
                color="primary"
                value={getValues().language}
                exclusive
                onChange={handleChangeLanguage}
                aria-label="Small sizes"
              >
                <ToggleButton value="fr">Francais</ToggleButton>
                <ToggleButton value="en">English</ToggleButton>                
              </ToggleButtonGroup>
            </Box>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('Signin')}
            </Typography>
            <Box sx={{ mt: 1, width: '100%'}}>
              <TextField sx={{width:'calc(100% - 8px)'}} required  id="email" label={t('Login')} {...register('userName')} defaultValue={ (storeLogin || '') as string}
                inputRef={inputLogin} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus 
                InputProps={{
                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                    if(event.key === 'Enter' && inputPassword.current)
                      (inputPassword.current as HTMLInputElement).focus();
                  },
                }}
              />
            </Box>
            <Box sx={{ mt: 1, width: '100%'}}>
              <TextField sx={{width:'calc(100% - 8px)'}} required label={t('Password')} {...register('password')}
                type="password" id="password" autoComplete="current-password" inputRef={inputPassword}
                inputProps={ { style: {textTransform: 'none'} } }
                InputProps={{
                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                    if(event.key === 'Enter') {
                      const btnPostLogin = document.getElementById(`btnPostLogin`);
                      if(btnPostLogin !== null)
                        btnPostLogin?.click();                        
                    }
                      //postLogin( new MouseEvent<HTMLButtonElement,MouseEvent>('button') );
                  },
                }} />
             </Box> 
             <Box sx={{ mt: 1, width: '100%', p:2}}> 
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, pl: 2, pr: 2 }} onClick={postLogin} id="btnPostLogin">
                {isLoading ? <ThreeDots
                        color='#00BFFF'
                        height={'100%'}
                        //width={100}
                        //timeout={3000} //3 secs
                      /> : t('Signin')}
              </Button>
              </Box>
              <Box sx={{ mt: 1, width: '100%'}}> 
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                   
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {isError? "Error" : ""}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} sm={3} md={4} lg={4}></Grid>
      </Grid>
  )
}
