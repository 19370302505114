import React, {FC, PropsWithChildren, useState, useEffect} from 'react';

// import library.
import { useFieldArray, useForm, useFormContext, Controller, FormProvider, FieldArrayWithId, ArrayPath, UnpackNestedValue, DeepPartial } from 'react-hook-form';

import NumberFormat from 'react-number-format';
// mui ...
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

// import {ITarifInfo} from '../component/crm/model/BankPolicy';

import TextFieldRight from './TextFieldRight';

type BaseType = 'string' | 'numeric' | 'bool' | 'date';

type DataType = 'text' | 'integer' | 'decimal' | 'float' | 'date' | 'boolean' | 'time' | 'enumeration' | 'entity';

export interface IField {
  
  type: BaseType,
  dataType: DataType,
  
  options?: {value: string, name: string} [],
}


export interface ExtensionValueFieldProps {    

    name: string, 
    label: string,

    type: DataType,
    

    itemsPerRow: 1 | 2 | 3
} 

 

//export function ArrayFieldBox  (props : ArrayFieldBoxProps)  {
export default function ExtensionValueField (props : ExtensionValueFieldProps)  {
    
  const {name, label, type, itemsPerRow} = props;

  const _itemsPerRow = (itemsPerRow || 1) as number;

  const w = _itemsPerRow === 3? '33' : _itemsPerRow === 2?  '50'  : '100' as string;

  const {  control  } = useFormContext();  

  
    function displayCell() : React.ReactNode {     
      
      console.log(props);
      
      if( (type) === 'boolean')
        return (<Controller 
                name={name}
                control={control}
                render={({ field: {onChange, value} }) => <Checkbox onChange={onChange} checked={value}/>}
                
             />);

      if(type === 'integer' || type === 'decimal' || type === 'float' )
        return (<Controller
          render={({ field: {onChange, onBlur, name, value, ref} }) => {
            return (
              <NumberFormat sx={{width:`calc(${w}% - 8px)`,  style: { textAlign: 'right' }}}
                label={label}
                //decimalScale={2}
                allowEmptyFormatting={false}
                control={control}                          
                //fixedDecimalScale={true}              
                thousandSeparator={true}
                decimalScale={2}
                onValueChange={ (v) => onChange(v.floatValue) }
                //{...field}
                customInput={TextFieldRight}
                defaultValue={value}
                value={value}
                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
              />
            );
          }}
          name={name} 
          control={control}
        />);
        
        // if(type === 'text' || type === 'enumeration')
        //   return (<Controller
        //     render={ ({field: {onChange, value}}) => (
        //       <TextField select onChange={onChange} value={value} sx={{width:`calc(${w}% - 8px)`}}
        //          label={label} >
        //       {param.options && param.options.map( 
        //         (ei,ix) => <MenuItem key={ei.value} value={ei.value}>{ei.name}</MenuItem> )
        //       }
        //       </TextField>
        //     )}
        //     name={name} //defaultValue={param[valueKey]}
        //     control={control}
        //   />);8

          return (<Controller             
            render={({ field }) => <TextField sx={{width:`calc(${w}% - 8px)`}} {...field} label={label}   />}
            name={name} //defaultValue={param[valueKey]}
            control={control}
        />);
    }

    return (  <>
                {displayCell() }
              </>      
      );    
}

